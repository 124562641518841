// translations/purchases.js

export default {
    ru: {
        price: "Цена",
        total: "Стоимость",
        ttl: "Стоимость",
        components: "Состав",
        noDataCta: {
            title: "Добавьте свою первую поставку материалов",
            subtitle: "Так мы будем отслеживать остатки материалов и себестоимость ваших продуктов",
        },
        delete: {
            title: "Удаление поставки",
            message: "Удалить поставку ",
        },
        exportMessage: "Экспорт списка поставок",
        view: {
            menu: {
                torg12: "Скачать накладную",
                export: "Экспорт состава",
            },
            print: {
                title: 'Печать поставки',
                hidePrices: 'Скрыть стоимость',
            },
            exportMessage: 'Экспорт состава поставки'
        },
        edit: {
            title: {
                add: 'Новая поставка',
                edit: 'Редактировать поставку',
            },
            addRow: 'Добавить строку',
            addMaterial: 'Создать новый материал',
            duplicateWarning: 'Это повтор. Вы можете создать поставку с повторяющимися материалами, мы просто предупреждаем.',
            createFirst: 'Сначала создайте хотя бы один материал',
        },
        snackbar: {
            added: "Поставка добавлена",
            edited: "Поставка обновлена",
            deleted: "Поставка удалена",
        },
        error: {
            notFound: 'Поставка не найдена. Возможно, она была удалена. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        price: "Unit price",
        total: "Total price",
        ttl: "Total",
        components: "Items",
        noDataCta: {
            title: "Add your first materials purchase",
            subtitle: "This will help us track material inventory and calculate your product costs",
        },
        delete: {
            title: "Delete purchase",
            message: "Delete purchase",
        },
        exportMessage: "Export purchases list",
        view: {
            menu: {
                export: "Export items",
            },
            print: {
                title: 'Print purchase',
                hidePrices: 'Hide prices',
            },
            exportMessage: 'Export purchase items'
        },
        edit: {
            title: {
                add: 'New purchase',
                edit: 'Edit purchase',
            },
            addRow: 'Add row',
            addMaterial: 'Create new material',
            duplicateWarning: 'This is a duplicate. You can create a purchase with duplicate materials, but we recommend avoiding duplicates.',
            createFirst: 'Create at least one material first',
        },
        snackbar: {
            added: "Purchase added",
            edited: "Purchase updated",
            deleted: "Purchase deleted",
        },
        error: {
            notFound: 'Purchase not found. It may have been deleted. Try reloading the page.'
        }
    }
}