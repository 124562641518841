<template>
	<v-tooltip bottom :disabled="!showTooltip">
		<template v-slot:activator="{ on, attrs }">
			<v-autocomplete
				:value="value"
				v-bind="$attrs"
				:search-input.sync="search"
				:hide-no-data="allowAdd && !search"
				:items="computedItems"
				:readonly="loading"
				:disabled="disabled"
				:item-text="itemText"
				:item-value="itemValue"
				:return-object="returnObject"
				outlined
				hide-details
				single-line
				:class="{ 'v-input--comfortable': !large && $attrs.dense === undefined }"
				:menu-props="{
					closeOnClick: true,
					closeOnContentClick: true,
					disableKeys: true,
					openOnClick: false,
					maxHeight: 304,
					offsetY: true,
					offsetOverflow: false,
					transition: false,
				}"
				@input="$emit('input', $event)"
				@change="$emit('change', $event)"
				:no-data-text="computedNoDataText"
			>
				<template v-slot:prepend-inner v-if="duplicate">
					<v-tooltip bottom nudge-right="300">
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" class="mr-1" :style="duplicateIconStyle">
								mdi-content-copy
							</v-icon>
						</template>
						<span>
							{{ duplicateText }}
						</span>
					</v-tooltip>
				</template>
				<template v-slot:prepend-inner v-else-if="loading && value">
					<span>
						<v-skeleton-loader type="text" width="150px" style="padding-top: 5px" />
					</span>
				</template>
				<template v-slot:selection="{ item }">
					<div
						v-if="!loading"
						class="v-select__selection v-select__selection--comma"
						:class="{ 'text-decoration-line-through': currentDisabled }"
						v-bind="attrs"
						v-on="on"
					>
						{{ item.name }}
					</div>
				</template>
				<template v-slot:item="{ index, item }" v-if="!!$scopedSlots.item">
					<slot name="item" :item="item"></slot>
				</template>
				<template v-slot:item="{ index, item }" v-else>
					<v-list-item-content
						v-if="item.disabled"
						class="text-decoration-line-through"
						style="color: rgba(0, 0, 0, 0.87)"
					>
						{{ item.name }}
					</v-list-item-content>
					<v-list-item-content v-else>
						{{ item.name }}
					</v-list-item-content>
				</template>
				<template v-slot:no-data v-if="allowAdd">
					<v-list-item v-if="search" @click="addItem">
						<v-icon class="mr-3">mdi-plus-circle</v-icon>
						<v-list-item-content> {{ addText }} "{{ search }}" </v-list-item-content>
					</v-list-item>
				</template>
			</v-autocomplete>
		</template>
		<span>{{ itemName }}</span>
	</v-tooltip>
</template>

<script>
export default {
	inheritAttrs: false,

	props: {
		value: {
			type: [String, Number, Object],
			default: "",
		},
		items: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		itemText: {
			type: String,
			default: "name",
		},
		itemValue: {
			type: String,
			default: "id",
		},
		noDataText: {
			type: String,
			default: function () {
				return this.t("noData")
			},
		},
		noResultsText: {
			type: String,
			default: function () {
				return this.t("noData")
			},
		},
		duplicateText: {
			type: String,
			default: function () {
				return this.t("thisIsDuplicate")
			},
		},
		textLength: {
			type: Number,
			default: 25,
		},
		duplicate: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		allowAdd: {
			type: Boolean,
			default: false,
		},
		addText: {
			type: String,
			default: function () {
				return this.t("create")
			},
		},
		initialId: {
			// 123, m-123, p-123, r-123
			type: [Number, String],
			default: null,
		},
		initialName: {
			type: String,
			default: "",
		},
		large: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			search: "",
		}
	},
	computed: {
		showTooltip() {
			// Сравниваем длину текста с допустимой длиной
			// Здесь длина измеряется в символах
			// Иконка "дубликат" по длине равна примерно 3 символам
			if (this.itemName.length + this.duplicate * 3 - this.textLength > 0) return true
			else return false
		},
		itemName() {
			if (this.returnObject) {
				if (!this.value) return ""
				else return this.value[this.itemText]
			} else {
				let item = this.items.find((i) => i[this.itemValue] == this.value)
				if (item) return item[this.itemText]
				else return ""
			}
		},
		computedNoDataText() {
			if (this.allowAdd) return ""
			else if (this.items.length == 0) return this.noDataText
			else return this.noResultsText
		},
		initialDisabled() {
			if (this.items.length == 0) return false
			else return this.initialId && !this.items.some((s) => s.id == this.initialId)
		},
		currentDisabled() {
			return this.value && this.computedItems.find((s) => s.id == this.value)?.disabled
		},
		computedItems() {
			if (!this.initialDisabled) {
				return this.items
			} else {
				return [
					...this.items,
					{
						id: this.initialId,
						name: this.initialName,
						disabled: true,
					},
				]
			}
		},
		duplicateIconStyle() {
			if (this.large) {
				return "margin-top: 0px; cursor: auto"
			} else if (this.dense) {
				return "margin-top: 0px; cursor: auto"
			} else {
				return "margin-top: -5px; cursor: auto"
			}
		},
	},
	methods: {
		addItem() {
			this.$emit("add", this.search)
			this.search = ""
		},
	},
}
</script>
