// translations/transfers.js

export default {
    ru: {
        type: "Тип",
        source: "Откуда",
        target: "Куда",
        cost: "Себестоимость",
        cst: "Себ-ть",
        costPerUnit: "Себ-ть за ед.",
        price: "Стоимость",
        components: "Состав",
        noDataCta: {
            title: "Добавьте перемещение материалов между складами",
            subtitle: "Если у вас есть несколько складов, вы можете перемещать материалы и продукты между ними",
        },
        delete: {
            title: "Удаление перемещения",
            message: "Удалить перемещение",
        },
        exportMessage: "Экспорт списка перемещений",
        view: {
            menu: {
                export: "Экспорт состава",
            },
            print: {
                title: 'Печать перемещения',
                hidePrices: 'Скрыть стоимость',
            },
            exportMessage: 'Экспорт списка материалов / продуктов'
        },
        edit: {
            title: {
                add: 'Новое перемещение',
                edit: 'Редактировать перемещение',
            },
            addRow: 'Добавить строку',
            duplicateWarning: 'Это повтор. Вы можете создать перемещение с повторами, мы просто предупреждаем.',
            createMaterialFirst: 'Сначала создайте хотя бы один материал',
            createProductFirst: 'Сначала создайте хотя бы один продукт',
        },
        snackbar: {
            added: "Перемещение добавлено",
            edited: "Перемещение обновлено",
            deleted: "Перемещение удалено",
        },
        error: {
            notFound: 'Перемещение не найдено. Возможно, оно было удалено. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        type: "Type",
        source: "From",
        target: "To",
        cost: "Total cost",
        cst: "Cost",
        costPerUnit: "Unit cost",
        price: "Total price",
        components: "Transfer items",
        noDataCta: {
            title: "Add your first transfer",
            subtitle: "If you have multiple storage locations, you can move materials and products between them",
        },
        delete: {
            title: "Delete transfer",
            message: "Delete transfer",
        },
        exportMessage: "Export transfers list",
        view: {
            menu: {
                export: "Export items",
            },
            print: {
                title: 'Print transfer',
                hidePrices: 'Hide prices',
            },
            exportMessage: 'Export transfer items'
        },
        edit: {
            title: {
                add: 'New transfer',
                edit: 'Edit transfer',
            },
            addRow: 'Add row',
            duplicateWarning: 'This is a duplicate. You can create a transfer with duplicates, but we recommend avoiding duplicates.',
            createMaterialFirst: 'Create at least one material first',
            createProductFirst: 'Create at least one product first',
        },
        snackbar: {
            added: "Transfer added",
            edited: "Transfer updated",
            deleted: "Transfer deleted",
        },
        error: {
            notFound: 'Transfer not found. It may have been deleted. Try reloading the page.'
        }
    }
}