import { render, staticRenderFns } from "./ASettingsPaymentRu.vue?vue&type=template&id=987d13ea&scoped=true"
import script from "./ASettingsPaymentRu.vue?vue&type=script&lang=js"
export * from "./ASettingsPaymentRu.vue?vue&type=script&lang=js"
import style0 from "./ASettingsPaymentRu.vue?vue&type=style&index=0&id=987d13ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987d13ea",
  null
  
)

export default component.exports