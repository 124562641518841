<template>
	<div>
		<a-chip v-for="c in computedCategories" :key="c.id" :color="colors[c.color]">
			{{ c.name }}
		</a-chip>
		<a-chip v-if="!showAll && categories.length > shortenedLength" @click="showAll = true">
			<v-icon small>mdi-dots-horizontal</v-icon>
		</a-chip>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	props: {
		categories: {
			type: Array,
			default: () => [],
		},
	},
	data: function () {
		return {
			shortenedLength: 5,
			showAll: false,
		}
	},
	computed: {
		computedCategories() {
			if (this.showAll) {
				return this.categories
			} else {
				return this.categories.slice(0, this.shortenedLength)
			}
		},
		...mapGetters({
			colors: "colors",
		}),
	},
}
</script>
