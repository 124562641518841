import Vue from 'vue'

export function required(v) {
    if (v instanceof Array) {
        return v.length > 0 || Vue.prototype.t('rules.required')
    }
    else {
        return !!v || Vue.prototype.t('rules.required')
    }
}
export function positive(v) {
    return v > 0 || Vue.prototype.t('rules.positive')
}
export function nonNegative(v) {
    return v >= 0 || Vue.prototype.t('rules.nonNegative')
}
export function validEmail(v) {
    // Валидный email или пустая строка
    const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(v) || v === '' || Vue.prototype.t("rules.wrongEmail")
}
export function minEight(v) {
    return v.length >= 8 || Vue.prototype.t('rules.minEight')
}
export function hasNumber(v) {
    return /\d/.test(v) || Vue.prototype.t('rules.hasNumber')
}
export function hasLowercase(v) {
    return /[a-z]/.test(v) || Vue.prototype.t('rules.hasLowercase')
}
export function hasUppercase(v) {
    return /[A-Z]/.test(v) || Vue.prototype.t('rules.hasUppercase')
}
export function hasLetter(v) {
    return /[A-z]/.test(v) || Vue.prototype.t('rules.hasLetter')
}