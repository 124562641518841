<template>
	<!-- Кнопка с подсказкой, которая видна при наведении на disabled кнопку -->
	<v-tooltip
		:top="tooltipPosition == 'top'"
		:bottom="tooltipPosition == 'bottom'"
		:right="tooltipPosition == 'right'"
		:left="tooltipPosition == 'left'"
		:nudge-top="nudgeTop"
		:nudge-bottom="nudgeBottom"
		:nudge-left="nudgeLeft"
		:nudge-right="nudgeRight"
		:disabled="!disabled"
	>
		<template v-slot:activator="{ on, attrs }">
			<span v-bind="attrs" v-on="on">
				<v-btn
					:outlined="outlined"
					:color="color"
					:elevation="elevation"
					:small="small"
					:disabled="disabled"
					:loading="loading"
					:class="contentClass"
					@click="$emit('click')"
				>
					<slot></slot>
				</v-btn>
			</span>
		</template>
		{{ disabledTooltip }}
	</v-tooltip>
</template>

<script>
export default {
	props: {
		small: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabledTooltip: {
			type: String,
			default: "",
		},
		tooltipPosition: {
			type: String,
			default: "top",
		},
		nudgeTop: {
			type: [String, Number],
			default: 0,
		},
		nudgeBottom: {
			type: [String, Number],
			default: 0,
		},
		nudgeLeft: {
			type: [String, Number],
			default: 0,
		},
		nudgeRight: {
			type: [String, Number],
			default: 0,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: "",
		},
		elevation: {
			type: [String, Number],
			default: 0,
		},
		contentClass: {
			type: String,
			default: "",
		},
	},
}
</script>
