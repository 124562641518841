export default {
    ru: {
        error: {
            deletePaid: "Нельзя удалить счет, который уже оплачен",
            alreadyPaid: "Нельзя установить статус оплачен для счета, который уже оплачен"
        }
    },
    en: {
        error: {
            deletePaid: "You can't delete paid invoice",
            alreadyPaid: "You can't set paid status for invoice that is already paid"
        }
    }
}