// translations/chart.js

export default {
    ru: {
        "name": "ru",
        "options": {
            "months": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            "shortMonths": ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
            "days": ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
            "shortDays": ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            "toolbar": {
                "exportToSVG": "Скачать SVG",
                "exportToPNG": "Скачать PNG",
                "menu": "Меню",
                "selection": "Выбор",
                "selectionZoom": "Увеличить выбранное",
                "zoomIn": "Увеличить",
                "zoomOut": "Уменьшить",
                "pan": "Перемещение",
                "reset": "Сбросить увеличение"
            }
        }
    },
    en: {
        "name": "en",
        "options": {
            "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            "toolbar": {
                "exportToSVG": "Download SVG",
                "exportToPNG": "Download PNG",
                "menu": "Menu",
                "selection": "Selection",
                "selectionZoom": "Selection Zoom",
                "zoomIn": "Zoom In",
                "zoomOut": "Zoom Out",
                "pan": "Pan",
                "reset": "Reset Zoom"
            }
        }
    }
}