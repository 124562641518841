<template>
	<div>
		<v-menu offset-y :disabled="!allowChange">
			<template v-slot:activator="{ on, attrs }">
				<v-chip v-if="loading" color="grey lighten-3" label small disabled>
					<v-icon left small> mdi-timer-sand </v-icon>
					{{ t("loading") }}
				</v-chip>
				<v-chip
					v-else
					:color="colors[currentStatus.color]"
					v-bind="attrs"
					v-on="on"
					label
					small
					:class="{ 'pr-4': !allowChange }"
				>
					<v-icon left small>
						{{ currentStatus.icon }}
					</v-icon>
					{{ currentStatus.name }}
					<v-icon v-if="allowChange" right class="d-print-none"> mdi-menu-down </v-icon>
				</v-chip>
			</template>
			<v-list dense>
				<v-list-item v-for="(s, key) in statuses" :key="key" @click="onChange(key)">
					<v-list-item-title>
						<v-icon left small>
							{{ s.icon }}
						</v-icon>
						{{ s.name }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<v-dialog
			v-model="dialog"
			persistent
			:fullscreen="mobile"
			:transition="mobile ? false : 'dialog-transition'"
			max-width="600"
		>
			<v-card>
				<!-- Заголовок -->
				<v-card-title class="headline" v-if="type == 'production'">
					{{ t("production") }} {{ "№" + num + " " + name }}
				</v-card-title>
				<v-card-title class="headline" v-else-if="type == 'order'">
					{{ t("order") }} {{ orderNum(num) }}
				</v-card-title>
				<v-card-title class="headline" v-else-if="type == 'purchase'">
					{{ t("purchase") }} {{ supplier }}
				</v-card-title>

				<!-- Содержимое -->
				<v-card-text class="text-body-1 text--primary">
					<!-- Старый и новый	статусы -->
					<v-row>
						<v-col cols="12" class="pb-6">
							<v-chip :color="colors[currentStatus.color]" label small>
								<v-icon left small>
									{{ currentStatus.icon }}
								</v-icon>
								{{ currentStatus.name }}
							</v-chip>
							<v-icon class="mx-2">mdi-arrow-right-thin</v-icon>
							<v-chip :color="colors[newStatus.color]" label small>
								<v-icon left small>
									{{ newStatus.icon }}
								</v-icon>
								{{ newStatus.name }}
							</v-chip>
						</v-col>
					</v-row>
					<!-- Выбор даты -->
					<v-row v-if="askForDate">
						<v-col cols="6">
							<v-form ref="formDate">
								<a-date-picker
									v-model="date"
									:label="type == 'order' ? t('dateShipped') : t('dateReceived')"
									:rules="[required]"
								/>
							</v-form>
						</v-col>
					</v-row>
					<!-- Изменения остатков -->
					<v-row>
						<v-col cols="12" class="body-1" v-if="materials.length || products.length">
							{{ t("statuses.stocksWillBeUpdated") }}:
						</v-col>
						<v-col cols="12" class="body-1" v-else>
							{{ t("statuses.stocksWillNotChange") }}
						</v-col>
					</v-row>
					<!-- Материалы -->
					<v-row v-if="materials.length > 0">
						<v-col cols="12" class="caption">
							<template v-if="storagesMaterials.length == 1"> {{ t("materials") }}: </template>
							<template v-else>
								{{ t("materials") }}, {{ t("storage").toLowerCase() }}:
								{{ materialsStorageName }}:
							</template>
						</v-col>
					</v-row>
					<v-row v-for="mat in materials" :key="'mat-' + mat.id">
						<v-col cols="6" lg="8" class="py-1 pl-5 body-2">
							<a-truncated-text width="340px" :text="mat.name" />
						</v-col>
						<v-col cols="6" lg="4" class="py-1 body-2">
							<span :class="mat.stock < 0 ? 'red--text' : ''">
								{{ mat.stock }} {{ mat.unit }}
							</span>
							<v-icon>mdi-arrow-right-thin</v-icon>
							<span :class="mat.newStock < 0 ? 'red--text' : ''">
								{{ mat.newStock }} {{ mat.unit }}
							</span>
						</v-col>
					</v-row>
					<!-- Продукты -->
					<v-row v-if="products.length > 0">
						<v-col cols="12" class="caption">
							<template v-if="storagesProducts.length == 1"> {{ t("products") }} </template>
							<template v-else>
								{{ t("products") }}, {{ t("storage").toLowerCase() }}:
								{{ productsStorageName }}:
							</template>
						</v-col>
					</v-row>
					<v-row v-for="prod in products" :key="'prod-' + prod.id">
						<v-col cols="6" lg="8" class="py-1 pl-5 body-2">
							<a-truncated-text width="340px" :text="prod.name" />
						</v-col>
						<v-col cols="6" lg="4" class="py-1 body-2">
							<span :class="prod.stock < 0 ? 'red--text' : ''">
								{{ prod.stock }} {{ prod.unit }}
							</span>
							<v-icon>mdi-arrow-right-thin</v-icon>
							<span :class="prod.newStock < 0 ? 'red--text' : ''">
								{{ prod.newStock }} {{ prod.unit }}
							</span>
						</v-col>
					</v-row>
				</v-card-text>

				<!-- Кнопки -->
				<v-card-actions class="py-4 px-6">
					<v-btn color="primary" elevation="0" @click="accept">
						{{ t("continue") }}
					</v-btn>
					<v-btn outlined @click.native="deny">
						{{ t("cancel") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import api from "@/lib/api"
import dayjs from "dayjs"
import { track } from "@amplitude/analytics-browser"
import { mapGetters } from "vuex"
import { required } from "@/lib/rules"
import { orderNum } from "@/lib/utils"

export default {
	props: {
		id: [Number, String],
		type: {
			type: String,
			default: "production",
		},
		value: {
			type: [Number, String],
			default: 0,
		},
		num: {
			type: [Number, String],
			default: "",
		},
		name: {
			type: [Number, String],
			default: "",
		},
		supplier: {
			type: String,
			default: "",
		},
		initialDate: {
			type: String,
			default: "",
		},
	},
	data: function () {
		return {
			loading: false,
			dialog: false,
			date: this.initialDate,
			materials: [],
			products: [],
			materialsStorageName: "",
			productsStorageName: "",
			newValue: 0,
		}
	},
	computed: {
		allowChange() {
			if (this.type == "production") {
				return this.user.productionEdit
			} else if (this.type == "order") {
				return this.user.ordersEdit
			} else if (this.type == "purchase") {
				return this.user.purchasesEdit
			}
		},
		statuses() {
			if (this.type == "production") {
				return this.statusesProduction
			} else if (this.type == "order") {
				return this.statusesOrders
			} else if (this.type == "purchase") {
				return this.statusesPurchases
			} else {
				return null
			}
		},
		askForDate() {
			if (this.type == "production") {
				return false
			} else if (this.type == "order") {
				return (
					this.user.prefShowOrderDateShipped &&
					!this.currentStatus.askForDate &&
					this.newStatus.askForDate
				)
			} else if (this.type == "purchase") {
				return (
					this.user.prefShowPurchaseDateReceived &&
					!this.currentStatus.askForDate &&
					this.newStatus.askForDate
				)
			}
		},
		currentStatus() {
			return this.statuses[this.value] ?? { name: "", icon: "", color: "" }
		},
		newStatus() {
			return this.statuses[this.newValue] ?? { name: "", icon: "", color: "" }
		},
		mobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		...mapGetters({
			storagesMaterials: "storages/materials",
			storagesProducts: "storages/products",
			statusesProduction: "statuses/production",
			statusesPurchases: "statuses/purchases",
			statusesOrders: "statuses/orders",
			colors: "colors",
			user: "user",
		}),
	},
	methods: {
		onChange(status) {
			// Смена статуса this.value => status
			if (status != this.value) {
				this.loading = true
				this.getStockChanges(status).then(
					(response) => {
						this.materials = response.data.materials
						this.products = response.data.products
						this.newValue = status

						if (this.materials.length || this.products.length || this.askForDate) {
							this.materialsStorageName = response.data.materialsStorageName
							this.productsStorageName = response.data.productsStorageName
							this.dialog = true

							if (this.askForDate) {
								this.$nextTick(() => {
									this.$refs.formDate.resetValidation()
								})
							}
						} else {
							this.accept()
						}
					},
					(error) => {
						this.loading = false
					}
				)
			}
		},
		getStockChanges(status) {
			if (this.type == "production") {
				return api.post("production/get_stock_changes", {
					id: this.id,
					status: status,
				})
			} else if (this.type == "order") {
				return api.post("order/get_stock_changes", {
					id: this.id,
					status: status,
				})
			} else if (this.type == "purchase") {
				return api.post("purchase/get_stock_changes", {
					id: this.id,
					status: status,
				})
			}
		},
		updateProductionStatus() {
			api.post("production/update_status", {
				id: this.id,
				status: this.newValue,
			})
				.then(() => {
					this.$store.dispatch("snackbar", this.t("statuses.snackbar.production"))
				})
				.finally(() => {
					this.loading = false
				})

			track("PRODUCTION_STATUS_UPDATE")
		},
		updateOrderStatus() {
			let date = this.askForDate ? this.date : null

			api.post("order/update_status", {
				id: this.id,
				status: this.newValue,
				dateShipped: date,
			})
				.then(() => {
					this.$store.dispatch("snackbar", this.t("statuses.snackbar.order"))
					if (date) {
						this.$emit("update-date-shipped", date)
					}
				})
				.finally(() => {
					this.loading = false
				})

			track("ORDER_STATUS_UPDATE")
		},
		updatePurchaseStatus() {
			let date = this.askForDate ? this.date : null

			api.post("purchase/update_status", {
				id: this.id,
				status: this.newValue,
				dateReceived: date,
			})
				.then(() => {
					this.$store.dispatch("snackbar", this.t("statuses.snackbar.purchase"))
					if (date) {
						this.$emit("update-date-received", date)
					}
				})
				.finally(() => {
					this.loading = false
				})

			track("PURCHASE_STATUS_UPDATE")
		},
		accept() {
			if (this.askForDate && !this.$refs.formDate.validate()) return

			this.$emit("input", this.newValue)
			this.dialog = false
			if (this.type == "production") {
				this.updateProductionStatus()
			} else if (this.type == "order") {
				this.updateOrderStatus()
			} else if (this.type == "purchase") {
				this.updatePurchaseStatus()
			}
		},
		deny() {
			this.dialog = false
			this.loading = false
		},
		required,
		orderNum,
	},
	created() {
		if (this.date == "") {
			this.date = dayjs().format("YYYY-MM-DD")
		}
	},
}
</script>
