// translations/menu.js

export default {
    ru: {
        other: 'Другое',
        help: 'База знаний',
        loggedInAs: "Вы зашли как",
        exitViewAs: "Вернуться",
        pin: "Закрепить меню",
        unpin: "Открепить меню",
    },
    en: {
        other: 'Other',
        help: 'Help Center',
        loggedInAs: "Logged in as",
        exitViewAs: "Return",
        pin: "Pin menu",
        unpin: "Unpin menu",
    }
}