// translations/settings.js

export default {
    ru: {
        profile: {
            title: "Профиль",
            name: "Имя",
            email: "Почта",
            company: "Компания",
            changePassword: "Изменить пароль",
            logout: "Выйти",
            logoutMessage: "Вы уверены, что хотите выйти из аккаунта?",
            edit: {
                title: "Редактировать профиль",
            },
            password: {
                title: "Изменить пароль",
                old: "Текущий пароль",
                new: "Новый пароль",
                hint: "От 8 символов, включая цифры и буквы",
            },
            snackbar: {
                profileEdited: "Профиль обновлен",
                passwordChanged: "Пароль обновлен",
            }
        },
        preferences: {
            title: "Настройки системы",
            defaultPurchaseStatus: {
                label: "Статус новой поставки по умолчанию",
                tooltip: "Выберите, в каком статусе по умолчанию будет создаваться новая поставка. Статус поставки влияет на поступление материалов и продуктов на склад. Статус всегда можно изменить в разделе поставок. Подробнее об этом в базе знаний в разделе поставки."
            },
            defaultProductionStatus: {
                label: "Статус нового производства по умолчанию",
                tooltip: "Выберите, в каком статусе по умолчанию будет создаваться новое производство. Статус производства влияет на списания материалов и продуктов. Статус всегда можно изменить в разделе производства. Подробнее об этом в базе знаний в разделе производство."
            },
            defaultOrderStatus: {
                label: "Статус нового заказа по умолчанию",
                tooltip: "Выберите, в каком статусе по умолчанию будет создаваться новый заказ. Статус заказа влияет на списания продуктов со склада. Статус всегда можно изменить в разделе заказов. Подробнее об этом в базе знаний в разделе заказы."
            },
            defaultOrderFromProduction: {
                label: "Способ выполнения нового заказа по умолчанию",
                tooltip: "Выберите, как по умолчанию будут создаваться новые заказы. Способ выполнения влияет на то, откуда будут списываться продукты для заказа. Подробнее об этом в базе знаний."
            },
            currency: {
                label: "Валюта",
            },
            dateFormat: {
                label: "Формат даты",
            },
            showPurchasePaymentStatus: {
                label: "Показывать статус оплаты поставок",
                tooltip: "На экране поставок будет отображаться статус оплаты. Статус оплаты можно изменить вручную, это может быть полезно для отслеживания оплат поставщикам."
            },
            showPurchaseDateReceived: {
                label: "Показывать дату получения для поставок",
                tooltip: "В карточке поставки будут отображаться две даты: дата заказа и дата получения. Дату получения можно изменить через редактирование поставки или в момент смены статуса поставки на \"Получена\". А в карточке поставщика будет отображаться среднее время доставки."
            },
            showOrderPaymentStatus: {
                label: "Показывать статус оплаты заказов",
                tooltip: "На экране заказов будет отображаться статус оплаты. Статус оплаты можно изменить вручную, это может быть полезно для отслеживания оплат от покупателей."
            },
            showOrderDateShipped: {
                label: "Показывать дату отправки для заказов",
                tooltip: "В карточке заказа будут отображаться две даты: дата заказа и дата отправки. Дату отправки можно изменить через редактирование заказа или в момент смены статуса заказа на \"Отправлен\". А в карточке покупателя будет отображаться среднее время доставки."
            },
            decimals: {
                label: "Показывать копейки в ценах"
            },
            purchaseOfProducts: {
                label: "Возможность поставки продуктов",
                tooltip: "После включения, в поставке можно будет выбрать не только материалы, но и продукты. Может быть полезно, если какие-то из продуктов вы не производите сами, а закупаете."
            },
            productionFromProducts: {
                label: "Возможность производства продуктов из других продуктов",
                tooltip: "После включения, в составе продукта можно будет выбрать не только материалы, но и другие продукты. Может быть полезно, если вы используете заготовки для своих продуктов, или производство состоит нескольких стадий."
            }
        },
        categories: {
            createNew: 'Создать новую категорию',
            view: {
                title: "Редактировать категории",
                text: "Вы можете перетаскивать категории, меняя их порядок. Порядок категорий влияет на их отображение в списке материалов и продуктов. Кликните по названию категории, чтобы изменить ее цвет.",
                usedInStorages: "Эта категория используется в настройках складов, поэтому ее нельзя удалить"
            },
            edit: {
                title: {
                    edit: "Редактировать категорию",
                    add: {
                        materials: "Создать категорию материалов",
                        products: "Создать категорию продуктов",
                        resources: "Создать категорию ресурсов",
                    }
                }
            },
            delete: {
                title: "Удаление категории",
                message: "Удалить категорию",
                willNotBeDeleted: "не будут удалены."
            },
            snackbar: {
                edited: "Категории обновлены",
            }
        },
        users: {
            admin: "Администратор",
            inviteSent: "Приглашение отправлено",
            resendInvite: "Повторно отправить приглашение",
            upgradeNeeded: "Доступно на других тарифных планах",
            limitReached: 'Достигнут лимит пользователей',
            dialog: {
                title: {
                    add: "Добавить пользователя",
                    edit: "Редактировать пользователя"
                },
                email: "Электронная почта",
                rights: "Права доступа",
                view: "Просмотр",
                edit: "Редактирование",
                allowExport: {
                    title: "Разрешить экспорт данных",
                    tooltip: "Пользователь сможет выгружать данные из всех разделов системы в формате excel и csv."
                },
                adminRights: {
                    title: "Права администратора",
                    tooltip: "Доступ к настройкам системы и управление пользователями",
                },
                message: "Пользователю будет отправлено приглашение на указанный адрес электронной почты",
                sendInvite: "Отправить приглашение"
            },
            delete: {
                title: "Удаление пользователя",
                message: "Удалить пользователя",
            },
            invite: {
                title: "Готово",
                message: "Письмо с приглашением повторно отправлено на адрес",
            },
            snackbar: {
                added: "Пользователь добавлен",
                edited: "Пользователь обновлен",
                deleted: "Пользователь удален",
            },
            error: {
                exists: "Пользователь с таким email уже существует"
            }
        },
        integrations: {
            title: "Интеграции",
            connected: "Подключено",
            pending: "Ожидание данных",
            connect: "Подключить",
            upgradeNeeded: "Доступно на других тарифных планах",
            tilda: {
                name: "Tilda",
                title: "Интеграция с Tilda",
                description: "Интеграция с интернет магазином на Tilda позволяет получать заказы, которые покупатели оформляют на вашем сайте на Tilda сразу в Controlata.",
                link: {
                    message: "Инструкция по подключению интеграции доступна",
                    href: "https://help.controlata.ru/hc/c1/articles/1734617828-tilda",
                    here: "здесь"
                },
                apiKey: "Ключ API",
                prefix: "Префикс для номеров заказов",
                fieldNum: "Поле номера заказа",
                fieldName: "Поле имени покупателя",
                fieldEmail: "Поле email покупателя",
                fieldPhone: "Поле телефона покупателя",
                fieldAddress: "Поле адреса покупателя",
                fieldDeliveryPrice: "Поле стоимости доставки",
                fieldDiscount: "Поле суммы скидки",
                fieldNotes: "Поле заметок",
                production: "Создавать отдельное производство под каждый заказ",
                externalid: "Использовать externalid для сопоставления продуктов",
                delete: {
                    title: "Удаление интеграции с Tilda",
                    message: "После удаления интеграции новые заказы перестанут поступать в систему. Вы уверены?",
                }
            },
            general: {
                name: "Общее API",
                title: "Интеграция по API",
                description: "Интеграция по API позволяет получать заказы из любых источников, которые вы настроите.",
                link: {
                    message: "Инструкция по подключению интеграции доступна",
                    href: "https://help.controlata.ru/hc/c1/articles/1734617350-api",
                    here: "здесь"
                },
                apiKey: "Ключ API",
                prefix: "Префикс для номеров заказов",
                delete: {
                    title: "Удаление интеграции по API",
                    message: "После удаления интеграции новые заказы перестанут поступать в систему. Вы уверены?"
                }
            },
            snackbar: {
                copied: "Ключ API скопирован"
            },
            error: {
                exists: "Интеграция уже существует"
            }
        },
        deleteAll: {
            dangerZone: "Опасная зона",
            deleteAllAndRestart: "Удалить все данные и начать сначала",
            dialog: {
                title: "Удаление всех данных",
                message: "Вы собираетесь безвозвратно удалить все материалы, продукты, ресурсы, их категории, производства, поставки, заказы, инвентаризации и всю вашу информацию в системе. Восстановить данные будет невозможно.\n\nЗарегистрированные пользователи и склады останутся.\n\nВы на 100% уверены, что хотите удалить ваши данные?",
                check: "УДАЛИТЬ ВСЕ",
                btnAcceptText: "Удалить все",
            },
            snackbar: "Данные удалены"
        },
        invoice: "Счет на оплату",
        invoices: "Счета на оплату",
    },
    en: {
        profile: {
            title: "Account",
            name: "Name",
            email: "Email",
            company: "Company",
            changePassword: "Change password",
            logout: "Sign out",
            logoutMessage: "Are you sure you want to sign out?",
            edit: {
                title: "Edit account details",
            },
            password: {
                title: "Change password",
                old: "Current password",
                new: "New password",
                hint: "At least 8 characters, including numbers and letters",
            },
            snackbar: {
                profileEdited: "Account details updated",
                passwordChanged: "Password updated",
            }
        },
        preferences: {
            title: "System settings",
            defaultPurchaseStatus: {
                label: "Default status for new purchases",
                tooltip: "Select the default status for new purchases. Purchase status determines when materials are added to inventory. This can be changed anytime in the purchases section. See the knowledge base for more details.",
            },
            defaultProductionStatus: {
                label: "Default status for new production",
                tooltip: "Select the default status for new production runs. Production status determines when materials are deducted from inventory and products are added. This can be changed anytime in the production section. See the knowledge base for more details.",
            },
            defaultOrderStatus: {
                label: "Default status for new sales",
                tooltip: "Select the default status for new sales. Order status determines when products are deducted from inventory. This can be changed anytime in the orders section. See the knowledge base for more details."
            },
            defaultOrderFromProduction: {
                label: "Default fulfillment method for new sales",
                tooltip: "Select the default fulfillment method for new sales. The fulfillment method determines how products are sourced (from inventory or made-to-order). See the knowledge base for more details."
            },
            currency: {
                label: "Currency",
            },
            dateFormat: {
                label: "Date format",
            },
            showPurchasePaymentStatus: {
                label: "Show purchase payment status",
                tooltip: "Displays payment status on the purchases screen. This can be updated manually to help track payments to suppliers."
            },
            showPurchaseDateReceived: {
                label: "Show received date for purchases",
                tooltip: "Displays both date placed and date received for purchases. The received date can be updated when editing or marking a purchase as 'Received'. Supplier profiles will show average delivery times based on this data."
            },
            showOrderPaymentStatus: {
                label: "Show sale payment status",
                tooltip: "Displays payment status on the sales screen. This can be updated manually to help track customer payments."
            },
            showOrderDateShipped: {
                label: "Show shipped date for sales",
                tooltip: "Displays both date placed and date shipped for sales. The shipped date can be updated when editing or marking a sale as 'Shipped'. Customer profiles will show average processing times based on this data."
            },
            decimals: {
                label: "Show decimals in prices",
            },
            purchaseOfProducts: {
                label: "Enable purchasing products",
                tooltip: "Allows adding products to purchases, not just materials. Useful when you buy ready-made products rather than manufacturing them yourself."
            },
            productionFromProducts: {
                label: "Enable subassemblies",
                tooltip: "Allows using finished products as components in other products. Useful for creating subassemblies or when your manufacturing process involves multiple stages."
            }
        },
        categories: {
            createNew: 'Create new category',
            view: {
                title: "Manage categories",
                text: "Drag categories to reorder them in your materials and products lists. Click on a category name to change its color.",
                usedInStorages: "This category cannot be deleted because it is used in storage location settings"
            },
            edit: {
                title: {
                    edit: "Edit category",
                    add: {
                        materials: "Create material category",
                        products: "Create product category",
                        resources: "Create resource category",
                    }
                }
            },
            delete: {
                title: "Delete category",
                message: "Delete category",
                willNotBeDeleted: "will remain in the system."
            },
            snackbar: {
                edited: "Categories updated",
            }
        },
        users: {
            admin: "Administrator",
            inviteSent: "Invitation sent",
            resendInvite: "Resend invitation",
            upgradeNeeded: "Available on other plans",
            limitReached: 'User limit reached',
            dialog: {
                title: {
                    add: "Add user",
                    edit: "Edit user"
                },
                email: "Email",
                rights: "User rights",
                view: "View",
                edit: "Edit",
                allowExport: {
                    title: "Allow data export",
                    tooltip: "Allows the user to export data from all sections as Excel and CSV files."
                },
                adminRights: {
                    title: "Admin rights",
                    tooltip: "Provides access to system settings and user management.",
                },
                message: "An invitation will be sent to the specified email address",
                sendInvite: "Send invitation"
            },
            delete: {
                title: "Delete user",
                message: "Delete user",
            },
            invite: {
                title: "Done",
                message: "Invitation email has been resent to",
            },
            snackbar: {
                added: "User added",
                edited: "User updated",
                deleted: "User deleted",
            },
            error: {
                exists: "User with this email already exists"
            }
        },
        integrations: {
            title: "Integrations",
            connected: "Connected",
            pending: "Pending data",
            connect: "Connect",
            upgradeNeeded: "Available on other plans",
            tilda: {
                name: "Tilda",
            },
            general: {
                name: "General API",
                title: "API integration",
                description: "API integration allows you to receive sales from any sources you configure.",
                link: {
                    message: "",
                    href: "",
                    here: ""
                },
                apiKey: "API key",
                prefix: "Sale number prefix",
                delete: {
                    title: "Delete API integration",
                    message: "After deleting this integration, new sales will no longer be imported into the system. Are you sure?"
                }
            },
            snackbar: {
                copied: "API key copied"
            },
            error: {
                exists: "Integration already exists"
            }
        },
        deleteAll: {
            dangerZone: "Danger zone",
            deleteAllAndRestart: "Delete all data and start over",
            dialog: {
                title: "Delete all data",
                message: "You are about to permanently delete all materials, products, resources, categories, production runs, purchases, sales, audits, and all other information in the system. This action cannot be undone.\n\nRegistered users and storage locations will remain.\n\nAre you 100% sure you want to delete your data?",
                check: "DELETE ALL",
                btnAcceptText: "Delete all",
            },
            snackbar: "Data deleted"
        },
        invoice: "Invoice",
        invoices: "Invoices",
    }
}