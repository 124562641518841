<template>
	<div>
		<v-row class="mt-0" v-if="images.length">
			<v-col v-for="image in images" :key="image.id" cols="auto">
				<v-card @click="preview(image)">
					<v-img :src="image.urlSmall" height="80" width="80">
						<template v-slot:placeholder>
							<v-row class="fill-height ma-0 align-center justify-center">
								<v-skeleton-loader type="image" width="80" height="80" />
							</v-row>
						</template>
					</v-img>
				</v-card>
			</v-col>
		</v-row>
		<v-row dense class="mt-3" v-if="nonImages.length">
			<v-col v-for="file in nonImages" :key="file.id" cols="auto">
				<v-chip label @click="download(file)">
					<v-icon left small> mdi-file </v-icon>
					<a-truncated-text width="350px" :text="file.name" />
				</v-chip>
			</v-col>
		</v-row>
		<!-- Превью изображения -->
		<v-dialog
			v-model="dialog.open"
			:width="aspect * height + 'vh'"
			:height="height + 'vh'"
			:key="dialog.image?.urlLarge"
			style="z-index: 1000000"
			@click:outside="dialog.open = false"
			@keydown.esc="dialog.open = false"
			@keydown.left="left"
			@keydown.right="right"
		>
			<v-card :color="dialog.image?.extension == 'png' ? 'white' : 'rgba(0, 0, 0, 0)'">
				<!-- key is needed to set loading state when source changes -->
				<v-img
					:width="aspect * height + 'vh'"
					:height="height + 'vh'"
					contain
					:key="dialog.image?.urlLarge"
					:src="dialog.image?.urlLarge"
					:lazy-src="dialog.image?.urlSmall"
					@click="dialog.open = false"
				>
					<v-app-bar flat color="rgba(0, 0, 0, 0)" class="">
						<v-spacer></v-spacer>
						<v-btn
							color="rgba(0, 0, 0, 0.15)"
							fab
							x-small
							elevation="0"
							class="ml-2"
							@click="download(dialog.image)"
						>
							<v-icon color="white">mdi-download</v-icon>
						</v-btn>
						<v-btn
							color="rgba(0, 0, 0, 0.15)"
							fab
							x-small
							elevation="0"
							class="ml-2"
							@click="dialog.open = false"
						>
							<v-icon color="white">mdi-close</v-icon>
						</v-btn>
					</v-app-bar>
					<template v-slot:placeholder>
						<v-row class="fill-height ma-0 align-center justify-center">
							<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
						</v-row>
					</template>
				</v-img>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		files: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			dialog: {
				open: false,
				image: null,
				aspect: 1,
			},
			imageExtensions: ["jpg", "jpeg", "png"],
			height: 85, // vh
		}
	},
	computed: {
		images() {
			return this.files.filter((file) => {
				return this.imageExtensions.includes(file.extension)
			})
		},
		nonImages() {
			return this.files.filter((file) => {
				return !this.imageExtensions.includes(file.extension)
			})
		},
		aspects() {
			return this.images.map((file) => {
				const img = new Image()
				img.src = file.urlSmall
				return img.naturalWidth / img.naturalHeight
			})
		},
		aspect() {
			if (!this.dialog.image) return 1
			const index = this.images.indexOf(this.dialog.image)
			return this.aspects[index]
		},
	},
	methods: {
		preview(file) {
			this.dialog.open = true
			this.dialog.image = file
		},
		left() {
			const index = this.images.indexOf(this.dialog.image)
			if (index > 0) {
				this.dialog.image = this.images[index - 1]
			}
		},
		right() {
			const index = this.images.indexOf(this.dialog.image)
			if (index < this.images.length - 1) {
				this.dialog.image = this.images[index + 1]
			}
		},
		download(file) {
			const link = document.createElement("a")
			link.href = file.urlOriginal
			link.target = "_blank"

			// TODO: download attribute doesn't work with cross-origin URLs
			// When move from rucdn.controlata.com to cdn.controlata.ru, that should work
			// For now, files are downloaded with key instead of actual name
			link.setAttribute("download", file.name)

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
	},
}
</script>
