var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{class:{ 'v-input--comfortable': _vm.comfortable },attrs:{"value":_vm.value,"search-input":_vm.search,"hide-no-data":!_vm.search,"items":_vm.categories,"readonly":_vm.loading,"disabled":_vm.disabled,"label":_vm.label,"small-chips":_vm.value.length > 0,"placeholder":_vm.placeholder,"persistent-placeholder":_vm.placeholder.length > 0,"rules":_vm.rules,"hide-selected":!_vm.many,"dense":_vm.computedDense,"return-object":_vm.returnObject,"item-text":"name","item-value":"id","multiple":"","outlined":"","hide-details":"","no-data-text":_vm.t('noData'),"menu-props":{
		closeOnClick: true,
		closeOnContentClick: !_vm.many,
		disableKeys: true,
		openOnClick: false,
		maxHeight: 304,
		offsetY: true,
		offsetOverflow: false,
		transition: false,
	}},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([(_vm.allowAdd)?{key:"no-data",fn:function(){return [(_vm.search)?_c('v-list-item',{class:[{ 'v-list-item--comfortable': _vm.comfortable }],on:{"click":_vm.addCategory}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"subheading pr-2"},[_vm._v(_vm._s(_vm.t("settings.categories.createNew")))]),_c('a-chip',{attrs:{"color":_vm.colors[_vm.nextColor]}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1):_vm._e()]},proxy:true}:null,{key:"selection",fn:function({ attrs, item, parent, selected }){return [_c('a-chip',{attrs:{"color":_vm.colors[item.color],"deletable":""},on:{"delete":function($event){return parent.selectItem(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function({ index, item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({class:[{ 'v-list-item--comfortable': _vm.comfortable }],scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [(_vm.many)?_c('v-list-item-action',{staticClass:"ml-0 mr-3"},[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1):_vm._e(),_c('a-chip',{attrs:{"color":_vm.colors[item.color]}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }