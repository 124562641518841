<template>
	<div>
		<v-menu offset-y :disabled="!allowChange">
			<template v-slot:activator="{ on, attrs }">
				<v-chip v-if="loading" color="grey lighten-3" label small disabled>
					<v-icon left small> mdi-timer-sand </v-icon>
					{{ t("loading") }}
				</v-chip>
				<v-chip
					v-else
					:color="colors[statuses[value]?.color]"
					v-bind="attrs"
					v-on="on"
					label
					small
					:class="{ 'pr-4': !allowChange }"
				>
					{{ t("statuses.payment." + type + "." + statuses[value].name) }}
					<v-icon v-if="allowChange" right class="d-print-none"> mdi-menu-down </v-icon>
				</v-chip>
			</template>
			<v-list dense>
				<v-list-item v-for="(s, key) in statuses" :key="key" @click="onChange(key)">
					<v-list-item-title>
						{{ t("statuses.payment." + type + "." + s.name) }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import api from "@/lib/api"
import { track } from "@amplitude/analytics-browser"
import { mapGetters } from "vuex"

export default {
	props: {
		type: {
			type: String,
			default: "order",
		},
		value: {
			type: Number,
			default: 0,
		},
		id: [Number, String],
	},
	data: (vm) => ({
		loading: false,
	}),
	computed: {
		allowChange() {
			if (this.type == "order") {
				return this.user.ordersEdit
			} else if (this.type == "purchase") {
				return this.user.purchasesEdit
			}
		},
		mobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		...mapGetters({
			user: "user",
			colors: "colors",
			statuses: "statuses/payments",
		}),
	},
	methods: {
		onChange: function (status) {
			// Смена статуса this.value => status
			if (status != this.value) {
				this.$emit("input", status)
				if (this.type == "order") {
					this.updateOrderPayment(status)
				} else if (this.type == "purchase") {
					this.updatePurchasePayment(status)
				}
			}
		},
		updatePurchasePayment(status) {
			this.loading = true
			api.post("purchase/update_payment", {
				id: this.id,
				status: status,
			})
				.then(() => {
					this.$store.dispatch("snackbar", this.t("statuses.snackbar.payment.purchase"))
				})
				.finally(() => {
					this.loading = false
				})

			track("PURCHASE_PAYMENT_UPDATE")
		},
		updateOrderPayment(status) {
			this.loading = true
			api.post("order/update_payment", {
				id: this.id,
				status: status,
			})
				.then(() => {
					this.$store.dispatch("snackbar", this.t("statuses.snackbar.payment.order"))
				})
				.finally(() => {
					this.loading = false
				})

			track("ORDER_PAYMENT_UPDATE")
		},
	},
}
</script>
