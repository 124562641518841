// translations/audits.js

export default {
    ru: {
        cost: "Себестоимость",
        type: "Тип",
        materialsAudit: "Инвентаризация материалов",
        productsAudit: "Инвентаризация продуктов",
        noDataCta: {
            title: "Создайте инвентаризацию",
            subtitle: "Инвентаризация поможет вам сравнить, сколько у вас материалов и продуктов в системе и фактически",
        },
        // delete: {
        //     title: "Удаление инвентаризации",
        //     message: "Удалить инвентаризацию ",
        // },
        // exportMessage: "Экспорт списка инвентаризаций",
        new: {
            1: {
                title: 'Тип инвентаризации',
                text: 'Выберите, что будем считать'
            },
            2: {
                title: 'Склад',
                text: 'На каком складе?'
            },
            3: {
                title: 'Категории',
                text: 'Можно ограничить инвентаризацию выбранными категориями. Если не выбирать категории, то будут включены все доступные позиции.'
            },
            start: 'Начать'
        },
        edit: {
            title: {
                add: 'Новая инвентаризация',
                edit: 'Редактировать инвентаризацию',
            },
            addRow: 'Добавить строку',
            deleteRow: 'Удалить строку',
            results: 'Результаты',
            atCost: 'По себестоимости',
            atPrice: 'По розничной цене',
            shortages: 'Недостачи',
            surpluses: 'Излишки',
            expected: 'Ожидаемый остаток',
            actual: 'Фактический остаток',
            difference: 'Разница',
            exportMessage: 'Экспорт строк инвентаризации',
        },
        snackbar: {
            added: "Инвентаризация добавлена",
            edited: "Инвентаризация обновлена",
            // deleted: "Инвентаризация удалена",
        },
        error: {
            notFound: 'Инвентаризация не найдена. Возможно, она была удалена. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        cost: "Cost",
        type: "Type",
        materialsAudit: "Materials audit",
        productsAudit: "Products audit",
        noDataCta: {
            title: "Create your first inventory audit",
            subtitle: "Audits help you compare expected inventory levels in the system with actual physical counts",
        },
        // delete: {
        //     title: "Delete audit",
        //     message: "Delete audit",
        // },
        // exportMessage: "Export audits list",
        new: {
            1: {
                title: 'Audit type',
                text: 'Select the type of items to count'
            },
            2: {
                title: 'Storage location',
                text: 'Select the storage location to audit'
            },
            3: {
                title: 'Categories',
                text: 'You can limit the audit to selected categories. If no categories are selected, all available items will be included.'
            },
            start: 'Start'
        },
        edit: {
            title: {
                add: 'New audit',
                edit: 'Edit audit',
            },
            addRow: 'Add row',
            deleteRow: 'Delete row',
            results: 'Results',
            atCost: 'At cost',
            atPrice: 'At sales price',
            shortages: 'Shortages',
            surpluses: 'Surpluses',
            expected: 'Expected stock',
            actual: 'Actual stock',
            difference: 'Difference',
            exportMessage: 'Export audit items',
        },
        snackbar: {
            added: "Audit added",
            edited: "Audit updated",
            // deleted: "Audit deleted",
        },
        error: {
            notFound: 'Audit not found. It may have been deleted. Try reloading the page.'
        }
    }
}