export default {
    ru: {
        bikNotFound: "По данному БИК ничего не найдено",
        innNotFound: "По данному ИНН ничего не найдено",
        connectionError: "Ошибка связи с провайдером данных, попробуйте снова через минуту."
    },
    en: {
        bikNotFound: "No data found for this BIK",
        innNotFound: "No data found for this INN",
        connectionError: "Error connecting to data provider, please try again in a minute.",
    }
}