<template>
	<v-textarea
		:label="label"
		:value="localValue"
		hide-details
		outlined
		auto-grow
		rows="3"
		@input="$emit('input', $event)"
	></v-textarea>
</template>

<script>
export default {
	name: "ANotesInput",

	data() {
		return {
			localValue: null,
		}
	},

	props: {
		value: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: function () {
				return this.t("notes")
			},
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.localValue = val
			},
		},
	},
}
</script>
