import axios from 'axios'
import store from '@/store'

// Configuration constants
const CONFIG = {
	DEBUG_LEVELS: {
		NONE: 0,
		REQUEST_ONLY: 1,
		FULL_DEBUG: 2
	},
	API_VERSION: 5,
	MAX_ATTEMPTS: 10,
	RETRY_THRESHOLD: 5,
}

// Debug level setup
let debugLevel = CONFIG.DEBUG_LEVELS.REQUEST_ONLY

if (origin !== 'http://localhost:8080') {
	debugLevel = CONFIG.DEBUG_LEVELS.NONE
}

// Utility functions
const logger = {
	request: (url, data) => {
		if (debugLevel >= CONFIG.DEBUG_LEVELS.REQUEST_ONLY) {
			console.log('>> api ' + url)
			if (debugLevel >= CONFIG.DEBUG_LEVELS.FULL_DEBUG && data) {
				console.log(data)
				console.log('------')
			}
		}
	},
	response: (url, data) => {
		if (debugLevel >= CONFIG.DEBUG_LEVELS.FULL_DEBUG) {
			console.log('<< api ' + url)
			console.log(data)
			console.log('------')
		}
	},
	error: (message, error) => {
		if (debugLevel >= CONFIG.DEBUG_LEVELS.REQUEST_ONLY) {
			console.error(message, error)
		}
	}
}

// Get API URL based on environment
const getApiUrl = () => {
	const apiUrl = process.env.VUE_APP_API_URL

	logger.request('url: ' + apiUrl)

	return apiUrl
}

// Create axios instance with configuration
const api = axios.create({
	baseURL: getApiUrl(),
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json;charset=utf-8'
	},
	validateStatus: status => status >= 200 && status < 300
})

// Retry mechanism
class RetryHandler {
	constructor() {
		this.attempt = 1
	}

	shouldRetry(error) {
		return !error.response &&
			error.code === 'ERR_NETWORK' &&
			this.attempt <= CONFIG.MAX_ATTEMPTS
	}

	async retry(config) {
		if (this.attempt > CONFIG.RETRY_THRESHOLD) {
			store.dispatch('dialog/connectionOpen')
		}

		const delay = Math.exp(this.attempt - 3) * 1000
		this.attempt++

		logger.request(`Retry attempt ${this.attempt} for ${config?.url}`)

		return new Promise(resolve =>
			setTimeout(() => resolve(api(config)), delay)
		)
	}

	reset() {
		this.attempt = 1
	}
}

const retryHandler = new RetryHandler()

// Request interceptor
api.interceptors.request.use(config => {
	logger.request(config.url, config.data)

	// Ensure PHP extension
	if (config.url?.substring(config.url.length - 4) !== '.php') {
		config.url = `${config.url}.php`
	}

	// Set headers
	if (!config.headers) {
		config.headers = {}
	}

	config.headers['version'] = CONFIG.API_VERSION

	const token = localStorage.getItem('token')
	if (token) {
		config.headers['authorization'] = token
	}

	if (config.data instanceof FormData) {
		config.headers['Content-Type'] = 'multipart/form-data'
	}

	return config
}, error => {
	logger.error('Request error:', error)
	return Promise.reject(error)
})

// Response interceptor
api.interceptors.response.use(response => {
	retryHandler.reset()
	store.dispatch('dialog/connectionClose')
	store.dispatch('dialog/maintenanceClose')

	logger.response(response.config.url, response.data)

	// Validate response
	if (!response.data) {
		throw new Error('Server did not reply')
	}

	// Handle special response types
	if (response.data instanceof Blob) {
		return response
	}

	// Handle token updates
	if (response.data.token !== undefined) {
		localStorage.setItem('token', response.data.token)
	}

	// Update user data
	if (response.data.user !== undefined) {
		store.dispatch('set', { key: "authorized", value: true })
		store.dispatch('set', { key: "user", value: response.data.user })
	}

	// Handle errors in response
	if (!response.data.success) {
		const error = response.data.error

		switch (error) {
			case 'Unauthorized':
			case 'Restricted':
				store.dispatch('logout')
				break
			case 'Maintenance':
				store.dispatch('dialog/maintenanceOpen')
				break
			default:
				if (error) {
					const title = 'dialogs.error.title'
					store.dispatch('dialog/notifyOpen', {
						title: title,
						message: error,
						translate: true
					})
					throw new Error(error)
				}
				throw new Error('Unknown error')
		}
	}

	// Version check
	if (response.data.version !== CONFIG.API_VERSION) {
		store.dispatch('set', {
			key: 'needReload',
			value: true
		})
	}

	return response
}, error => {
	logger.error('Response error:', {
		message: error.message,
		status: error.response?.status,
		data: error.response?.data,
		config: error.config
	})

	if (retryHandler.shouldRetry(error)) {
		return retryHandler.retry(error.config)
	}

	return Promise.reject(error)
})

export default api