import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue')
const Companies = () => import(/* webpackChunkName: "admin" */ '@/components/admin/Companies.vue')
const Invoices = () => import(/* webpackChunkName: "admin" */ '@/components/admin/Invoices.vue')
const Logs = () => import(/* webpackChunkName: "admin" */ '@/components/admin/Logs.vue')
const UiElements = () => import(/* webpackChunkName: "admin" */ '@/components/admin/UiElements.vue')
const Materials = () => import(/* webpackChunkName: "main" */ '@/views/Materials.vue')
const AViewMaterial = () => import(/* webpackChunkName: "main" */ '@/components/materials/AViewMaterial.vue')
const Products = () => import(/* webpackChunkName: "main" */ '@/views/Products.vue')
const AViewProduct = () => import(/* webpackChunkName: "main" */ '@/components/products/AViewProduct.vue')
const Purchases = () => import(/* webpackChunkName: "main" */ '@/views/Purchases.vue')
const AViewPurchase = () => import(/* webpackChunkName: "main" */ '@/components/purchase/AViewPurchase.vue')
const Suppliers = () => import(/* webpackChunkName: "main" */ '@/views/Suppliers.vue')
const AViewSupplier = () => import(/* webpackChunkName: "main" */ '@/components/suppliers/AViewSupplier.vue')
const Transfer = () => import(/* webpackChunkName: "main" */ '@/views/Transfer.vue')
const AViewTransfer = () => import(/* webpackChunkName: "main" */ '@/components/transfer/AViewTransfer.vue')
const Production = () => import(/* webpackChunkName: "main" */ '@/views/Production.vue')
const AViewProduction = () => import(/* webpackChunkName: "main" */ '@/components/production/AViewProduction.vue')
const Orders = () => import(/* webpackChunkName: "main" */ '@/views/Orders.vue')
const AViewOrder = () => import(/* webpackChunkName: "main" */ '@/components/order/AViewOrder.vue')
const Customers = () => import(/* webpackChunkName: "main" */ '@/views/Customers.vue')
const AViewCustomer = () => import(/* webpackChunkName: "main" */ '@/components/customers/AViewCustomer.vue')
const Storages = () => import(/* webpackChunkName: "main" */ '@/views/Storages.vue')
const Writeoffs = () => import(/* webpackChunkName: "main" */ '@/views/Writeoffs.vue')
const AViewWriteoff = () => import(/* webpackChunkName: "main" */ '@/components/writeoff/AViewWriteoff.vue')
const Resources = () => import(/* webpackChunkName: "main" */ '@/views/Resources.vue')
const AViewResource = () => import(/* webpackChunkName: "main" */ '@/components/resource/AViewResource.vue')
const Audits = () => import(/* webpackChunkName: "main" */ '@/views/Audits.vue')
const AEditAudit = () => import(/* webpackChunkName: "main" */ '@/components/audit/AEditAudit.vue')
const Settings = () => import(/* webpackChunkName: "main" */ '@/views/Settings.vue')
const Reports = () => import(/* webpackChunkName: "main" */ '@/views/Reports.vue')
const ReportMaterialsStocks = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportMaterialsStocks.vue')
const ReportProductsStocks = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductsStocks.vue')
const ReportMaterialsHistory = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportMaterialsHistory.vue')
const ReportProductsHistory = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductsHistory.vue')
const ReportMaterialsTurnover = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportMaterialsTurnover.vue')
const ReportProductsTurnover = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductsTurnover.vue')
const ReportMaterialsAtDate = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportMaterialsAtDate.vue')
const ReportProductsAtDate = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductsAtDate.vue')
const ReportPurchasesVolume = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportPurchasesVolume.vue')
const ReportProductionVolume = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductionVolume.vue')
const ReportOrdersVolume = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportOrdersVolume.vue')
const ReportPurchasedMaterials = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportPurchasedMaterials.vue')
const ReportOrderedProducts = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportOrderedProducts.vue')
const ReportProducedProducts = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProducedProducts.vue')
const ReportUsedMaterials = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportUsedMaterials.vue')
const ReportMaterialsTurnoverRates = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportMaterialsTurnoverRates.vue')
const ReportProductsTurnoverRates = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductsTurnoverRates.vue')
const ReportResourcesHistory = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportResourcesHistory.vue')
const ReportResourcesTurnover = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportResourcesTurnover.vue')
const ReportProductsAbc = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductsAbc.vue')
const ReportMaterialsAbc = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportMaterialsAbc.vue')
const ReportProfitAndLoss = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProfitAndLoss.vue')
const ReportPurchaseCostDynamics = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportPurchaseCostDynamics.vue')
const ReportProductionCostDynamics = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportProductionCostDynamics.vue')
const ReportOrderPriceDynamics = () => import(/* webpackChunkName: "main" */ '@/components/reports/AReportOrderPriceDynamics.vue')
const History = () => import(/* webpackChunkName: "main" */ '@/views/History.vue')
const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
const Register = () => import(/* webpackChunkName: "login" */ '@/views/Register.vue')
const Onboarding = () => import(/* webpackChunkName: "login" */ '@/views/Onboarding.vue')
const SetPassword = () => import(/* webpackChunkName: "login" */ '@/views/SetPassword.vue')

Vue.use(VueRouter)

let debug = false

if (origin != 'http://localhost:8080') debug = false

VueRouter.prototype.open = function (event, routeObject) {
	// Method for opening a route in a new tab if ctrl or cmd is pressed
	if (event.ctrlKey === true || event.metaKey === true) {
		let routeData = this.resolve(routeObject)
		window.open(routeData.href, "_blank")
	} else {
		this.push(routeObject)
	}
}

// meta props:
// needAuth - этот экран доступен только авторизованным пользователям
// authRight - название права доступа, которое необходимо для доступа к этому экрану
// authDeny - этот экран доступен только неавторизованным пользователям

const routes = [
	{
		path: '/onboarding',
		name: 'Onboarding',
		component: Onboarding,
		meta: {
			needAuth: true,
			authDeny: false
		},
	},
	{
		path: '/',
		redirect: '/materials',
	},
	{
		path: '/profile',
		redirect: '/settings',
	},
	{
		path: '/purchase',
		redirect: '/purchases',
	},
	{
		path: '/purchase/item/:id',
		redirect: '/purchases/item/:id',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			needAuth: false,
			authDeny: true
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
		meta: {
			needAuth: false,
			authDeny: true
		},
	},
	{
		path: '/set-password/:link',
		name: 'SetPassword',
		component: SetPassword,
		meta: {
			needAuth: false,
			authDeny: true
		},
	},
	{
		path: '/materials',
		name: 'Materials',
		component: Materials,
		meta: {
			title: 'materials',
			needAuth: true,
			authRight: 'materialsView',
			authDeny: false
		},
	},
	{
		path: '/materials/item/:id',
		name: 'Material',
		component: Materials,
		meta: {
			title: 'material',
			needAuth: true,
			authRight: 'materialsView',
			authDeny: false
		},
	},
	{
		path: '/materials/item/print/:id/:storageId/:storageName/:code',
		name: 'MaterialPrint',
		component: AViewMaterial,
		meta: {
			title: 'material',
			needAuth: true,
			authRight: 'materialsView',
			authDeny: false
		}
	},
	{
		path: '/products',
		name: 'Products',
		component: Products,
		meta: {
			title: 'products',
			needAuth: true,
			authRight: 'productsView',
			authDeny: false
		}
	},
	{
		path: '/products/item/:id',
		name: 'Product',
		component: Products,
		meta: {
			title: 'products',
			needAuth: true,
			authRight: 'productsView',
			authDeny: false
		}
	},
	{
		path: '/products/item/print/:id/:storageId/:storageName/:code',
		name: 'ProductPrint',
		component: AViewProduct,
		meta: {
			title: 'product',
			needAuth: true,
			authRight: 'productsView',
			authDeny: false
		}
	},
	{
		path: '/purchases',
		name: 'Purchases',
		component: Purchases,
		meta: {
			title: 'purchases',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/purchases/item/:id',
		name: 'Purchase',
		component: Purchases,
		meta: {
			title: 'purchase',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/purchases/item/print/:id/:materialsStorageName/:productsStorageName/:code',
		name: 'PurchasePrint',
		component: AViewPurchase,
		meta: {
			title: 'purchase',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		}
	},
	{
		path: '/suppliers',
		name: 'Suppliers',
		component: Suppliers,
		meta: {
			title: 'suppliers',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/suppliers/item/:id',
		name: 'Supplier',
		component: Suppliers,
		meta: {
			title: 'supplier',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		},
	},
	{
		path: '/suppliers/item/print/:id/:code',
		name: 'SupplierPrint',
		component: AViewSupplier,
		meta: {
			title: 'supplier',
			needAuth: true,
			authRight: 'purchasesView',
			authDeny: false
		}
	},
	{
		path: '/production',
		name: 'Productions',
		component: Production,
		meta: {
			title: 'production',
			needAuth: true,
			authRight: 'productionView',
			authDeny: false
		}
	},
	{
		path: '/production/item/:id',
		name: 'Production',
		component: Production,
		meta: {
			title: 'production',
			needAuth: true,
			authRight: 'productionView',
			authDeny: false
		}
	},
	{
		path: '/production/item/print/:id/:materialsStorageName/:productsStorageName/:code',
		name: 'ProductionPrint',
		component: AViewProduction,
		meta: {
			title: 'production',
			needAuth: true,
			authRight: 'productionView',
			authDeny: false
		}
	},
	{
		path: '/orders',
		name: 'Orders',
		component: Orders,
		meta: {
			title: 'orders',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/orders/item/:id',
		name: 'Order',
		component: Orders,
		meta: {
			title: 'order',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/orders/item/print/:id/:storageName/:code',
		name: 'OrderPrint',
		component: AViewOrder,
		meta: {
			title: 'order',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		}
	},
	{
		path: '/customers',
		name: 'Customers',
		component: Customers,
		meta: {
			title: 'customers',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/customers/item/:id',
		name: 'Customer',
		component: Customers,
		meta: {
			title: 'customer',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		},
	},
	{
		path: '/customers/item/print/:id/:code',
		name: 'CustomerPrint',
		component: AViewCustomer,
		meta: {
			title: 'customer',
			needAuth: true,
			authRight: 'ordersView',
			authDeny: false
		}
	},
	{
		path: '/storages',
		name: 'Storages',
		component: Storages,
		meta: {
			title: 'storages',
			needAuth: true,
			authRight: 'storagesView',
			authDeny: false
		},
	},
	{
		path: '/storages/item/:id',
		name: 'Storage',
		component: Storages,
		meta: {
			title: 'storage',
			needAuth: true,
			authRight: 'storagesView',
			authDeny: false
		},
	},
	{
		path: '/transfer',
		name: 'Transfers',
		component: Transfer,
		meta: {
			title: 'transfers',
			needAuth: true,
			authRight: 'transfersView',
			authDeny: false
		},
	},
	{
		path: '/transfer/item/:id',
		name: 'Transfer',
		component: Transfer,
		meta: {
			title: 'transfer',
			needAuth: true,
			authRight: 'transfersView',
			authDeny: false
		},
	},
	{
		path: '/transfer/item/print/:id/:code',
		name: 'TransferPrint',
		component: AViewTransfer,
		meta: {
			title: 'transfer',
			needAuth: true,
			authRight: 'transfersView',
			authDeny: false
		}
	},
	{
		path: '/writeoff',
		name: 'Writeoffs',
		component: Writeoffs,
		meta: {
			title: 'writeoffs',
			needAuth: true,
			authRight: 'writeoffsView',
			authDeny: false
		},
	},
	{
		path: '/writeoff/item/:id',
		name: 'Writeoff',
		component: Writeoffs,
		meta: {
			title: 'writeoff',
			needAuth: true,
			authRight: 'writeoffsView',
			authDeny: false
		},
	},
	{
		path: '/writeoff/item/print/:id/:materialsStorageName/:productsStorageName/:code',
		name: 'WriteoffPrint',
		component: AViewWriteoff,
		meta: {
			title: 'writeoff',
			needAuth: true,
			authRight: 'writeoffsView',
			authDeny: false
		}
	},
	{
		path: '/resources',
		name: 'Resources',
		component: Resources,
		meta: {
			title: 'resources',
			needAuth: true,
			authRight: 'resourcesView',
			authDeny: false
		},
	},
	{
		path: '/resources/item/:id',
		name: 'Resource',
		component: Resources,
		meta: {
			title: 'resource',
			needAuth: true,
			authRight: 'resourcesView',
			authDeny: false
		},
	},
	{
		path: '/resources/item/print/:id/:code',
		name: 'ResourcePrint',
		component: AViewResource,
		meta: {
			title: 'resource',
			needAuth: true,
			authRight: 'resourcesView',
			authDeny: false
		}
	},
	{
		path: '/audits',
		name: 'Audits',
		component: Audits,
		meta: {
			title: 'audits',
			needAuth: true,
			authRight: 'auditsView',
			authDeny: false
		}
	},
	{
		path: '/audits/:id',
		name: 'AuditEdit',
		component: AEditAudit,
		props: true,
		meta: {
			title: 'audit',
			needAuth: true,
			authRight: 'auditsView',
			authDeny: false
		}
	},
	{
		path: '/history',
		name: 'History',
		component: History,
		meta: {
			title: 'history',
			needAuth: true,
			authRight: 'historyView',
			authDeny: false
		}
	},
	{
		path: '/reports',
		name: 'Reports',
		component: Reports,
		meta: {
			title: 'reports',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-stocks',
		name: 'ReportMaterialsStocks',
		component: ReportMaterialsStocks,
		meta: {
			title: 'reports.materialsStocks',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-stocks',
		name: 'ReportProductsStocks',
		component: ReportProductsStocks,
		meta: {
			title: 'reports.productsStocks',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-history',
		name: 'ReportMaterialsHistory',
		component: ReportMaterialsHistory,
		meta: {
			title: 'reports.materialsHistory',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-history',
		name: 'ReportProductsHistory',
		component: ReportProductsHistory,
		meta: {
			title: 'reports.productsHistory',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-turnover',
		name: 'ReportMaterialsTurnover',
		component: ReportMaterialsTurnover,
		meta: {
			title: 'reports.materialsTurnover',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-turnover',
		name: 'ReportProductsTurnover',
		component: ReportProductsTurnover,
		meta: {
			title: 'reports.productsTurnover',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-at-date',
		name: 'ReportMaterialsAtDate',
		component: ReportMaterialsAtDate,
		meta: {
			title: 'reports.materialsAtDate',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-at-date',
		name: 'ReportProductsAtDate',
		component: ReportProductsAtDate,
		meta: {
			title: 'reports.productsAtDate',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/purchases-volume',
		name: 'ReportPurchasesVolume',
		component: ReportPurchasesVolume,
		meta: {
			title: 'reports.purchasesVolume',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/production-volume',
		name: 'ReportProductionVolume',
		component: ReportProductionVolume,
		meta: {
			title: 'reports.productionVolume',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false

		},
	},
	{
		path: '/reports/orders-volume',
		name: 'ReportOrdersVolume',
		component: ReportOrdersVolume,
		meta: {
			title: 'reports.ordersVolume',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/purchased-materials',
		name: 'ReportPurchasedMaterials',
		component: ReportPurchasedMaterials,
		meta: {
			title: 'reports.purchasedMaterials',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/ordered-products',
		name: 'ReportOrderedProducts',
		component: ReportOrderedProducts,
		meta: {
			title: 'reports.orderedProducts',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/produced-products',
		name: 'ReportProducedProducts',
		component: ReportProducedProducts,
		meta: {
			title: 'reports.producedProducts',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/used-materials',
		name: 'ReportUsedMaterials',
		component: ReportUsedMaterials,
		meta: {
			title: 'reports.usedMaterials',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/materials-turnover-rates',
		name: 'ReportMaterialsTurnoverRates',
		component: ReportMaterialsTurnoverRates,
		meta: {
			title: 'reports.materialsTurnoverRates',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/products-turnover-rates',
		name: 'ReportProductsTurnoverRates',
		component: ReportProductsTurnoverRates,
		meta: {
			title: 'reports.productsTurnoverRates',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		},
	},
	{
		path: '/reports/resources-history',
		name: 'ReportResourcesHistory',
		component: ReportResourcesHistory,
		meta: {
			title: 'reports.resourcesHistory',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/resources-turnover',
		name: 'ReportResourcesTurnover',
		component: ReportResourcesTurnover,
		meta: {
			title: 'reports.resourcesTurnover',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/products-abc',
		name: 'ReportProductsAbc',
		component: ReportProductsAbc,
		meta: {
			title: 'reports.productsAbc',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/materials-abc',
		name: 'ReportMaterialsAbc',
		component: ReportMaterialsAbc,
		meta: {
			title: 'reports.materialsAbc',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/profit-and-loss',
		name: 'ReportProfitAndLoss',
		component: ReportProfitAndLoss,
		meta: {
			title: 'reports.profitAndLoss',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/purchase-cost-dynamics',
		name: 'ReportPurchaseCostDynamics',
		component: ReportPurchaseCostDynamics,
		meta: {
			title: 'reports.purchaseCostDynamics',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/production-cost-dynamics',
		name: 'ReportProductionCostDynamics',
		component: ReportProductionCostDynamics,
		meta: {
			title: 'reports.productionCostDynamics',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/reports/order-price-dynamics',
		name: 'ReportOrderPriceDynamics',
		component: ReportOrderPriceDynamics,
		meta: {
			title: 'reports.orderPriceDynamics',
			needAuth: true,
			authRight: 'reportsView',
			authDeny: false
		}
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		meta: {
			title: 'settings',
			needAuth: true,
			authDeny: false
		},
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		meta: {
			title: 'admin.title',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/companies',
		name: 'Companies',
		props: true,
		component: Companies,
		meta: {
			title: 'admin.companies',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/companies/:id',
		name: 'Company',
		props: true,
		component: Companies,
		meta: {
			title: 'admin.companies.company',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/invoices',
		name: 'Invoices',
		props: true,
		component: Invoices,
		meta: {
			title: 'settings.invoices',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/logs',
		name: 'Logs',
		component: Logs,
		meta: {
			title: 'admin.logs',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
	{
		path: '/admin/ui',
		name: 'UI Elements',
		component: UiElements,
		meta: {
			title: 'admin.uiElements',
			needAuth: true,
			authRight: 'globalAdmin',
			authDeny: false
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		// console.log('scroll from ' + from.path + ' to ' + to.path)
		if (savedPosition) {
			// console.log('scroll to saved position')
			return savedPosition
		} else if (from.path.split('/')[1] !== to.path.split('/')[1]) {
			// console.log('scroll to top 1')
			// window.scrollTo(0, 0)
			return { x: 0, y: 0 }
		} else if (to.name == 'AuditEdit') {
			// console.log('scroll to top 2')
			// window.scrollTo(0, 0)
			return { x: 0, y: 0 }
		} else if (to.hash) {
			// console.log('scroll to hash')
			return { selector: to.hash, offset: { x: 0, y: 200 } }
		} else {
			// console.log('else scroll to top')
			return { x: 0, y: 0 }
		}
	}
})

export default router

router.beforeEach((to, from, next) => {
	if (debug) console.log('router beforeEach')
	if (debug) console.log('-- from: ' + from.path)
	if (debug) console.log('-- to:   ' + to.path)

	if (to.meta.needAuth) {
		if (debug) console.log('-- need authorization')
		if (store.getters.authorized) {
			if (debug) console.log('-- authorized, ok')

			if (!store.getters.user.onboarded) {
				if (debug) console.log('-- not onboarded')
				if (to.path != '/onboarding') {
					if (debug) console.log('-- redirect to onboarding')
					next('/onboarding')
				}
				else {
					if (debug) console.log('-- ok')
					next()
				}
			}
			else if (to.meta.authRight) {
				if (debug) console.log('---- need specific view right')
				if (store.getters.user[to.meta.authRight]) {
					if (debug) console.log('---- view right granted')
					next()
				}
				else {
					if (debug) console.log('---- view right rejected')

					store.dispatch("dialog/notifyOpen", {
						title: "dialogs.accessDenied.title",
						message: "dialogs.accessDenied.message",
					})
				}
			}
			else {
				next()
			}
		}
		else {
			if (debug) console.log('-- unauthorized')

			store.dispatch('set', {
				key: 'pathBeforeLogin',
				value: to.path
			})

			next('/login')
		}
	}
	else if (to.meta.authDeny) {
		if (debug) console.log('-- deny authorization ')
		if (store.getters.authorized) {
			if (debug) console.log('-- authorized, redirect')
			next('/materials')
		}
		else {
			if (debug) console.log('-- ok')
			next()
		}
	}
	else {
		next()
	}
})
