var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{on:{"mouseover":_vm.onHover,"mouseleave":_vm.onLeave}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.printRoute)?_c('div',[_vm._v(" "+_vm._s(_vm.text)+" ")]):(_vm.to)?_c('router-link',{ref:"link",staticClass:"text-no-wrap d-inline-block text-truncate internal-link",class:{
					'blue--text text--darken-3': _vm.blue,
					'text--primary': !_vm.blue,
					'text-decoration-line-through': _vm.crossedOut,
				},style:('max-width: ' + _vm.width + '; vertical-align: middle;'),attrs:{"to":_vm.to}},[_c('span',[_vm._v(_vm._s(_vm.text))])]):_c('div',{ref:"link",staticClass:"text-no-wrap d-inline-block text-truncate",class:{
					'internal-link': _vm.link,
					'blue--text text--darken-3': _vm.blue,
					'text--primary': !_vm.blue,
					'text-decoration-line-through': _vm.crossedOut,
				},style:('max-width: ' + _vm.width + '; vertical-align: middle;'),on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._v(" "+_vm._s(_vm.text)+" ")])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v(_vm._s(_vm.text))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }