<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		left
		min-width="auto"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="dateFormatted"
				:label="computedLabel"
				append-icon="mdi-calendar"
				readonly
				outlined
				hide-details
				:rules="rules"
				:clearable="clearable"
				:dense="dense"
				:class="{ 'v-input--comfortable': !large && !dense }"
				@click:clear="$emit('input', null)"
				@click:append="menu = true"
				v-bind="attrs"
				v-on="on"
			></v-text-field>
		</template>
		<v-date-picker
			:value="value"
			:first-day-of-week="$locale == 'en' ? 0 : 1"
			:locale="$locale"
			no-title
			:show-current="false"
			:events="events"
			event-color="blue lighten-2"
			hide-details
			@input="menu = false"
			@change="onChange"
		></v-date-picker>
	</v-menu>
</template>

<script>
import dayjs from "dayjs"

export default {
	props: {
		label: {
			type: String,
			default() {
				return this.t("date")
			},
		},
		value: {
			type: String,
			default: null,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		events: {
			type: Array,
			default: () => [],
		},
		dense: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		dateFormatted: "",
		menu: false,
	}),
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.value)
		},
		computedLabel() {
			if (this.rules.some((r) => r("") !== true)) {
				return this.label + " *"
			} else {
				return this.label
			}
		},
	},
	mounted() {
		this.dateFormatted = this.formatDate(this.value)
	},
	watch: {
		value(val) {
			this.dateFormatted = this.formatDate(this.value)
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return null

			// const [year, month, day] = date.split("-")
			// return `${day}.${month}.${year}`

			return dayjs(date).format("DD.MM.YYYY")
		},
		onChange: function (e) {
			this.$emit("input", e)
		},
	},
}
</script>
