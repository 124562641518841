// translations/orders.js

export default {
    ru: {
        price: "Цена",
        total: "Стоимость",
        ttl: "Ст-ть",
        cost: "Себестоимость",
        components: "Состав",
        fulfillment: "Способ выполнения",
        fromStock: "Отгрузка со склада",
        customProduction: "Производство под заказ",
        noDataCta: {
            title: "Создайте первый заказ на ваши продукты",
            subtitle: "Когда вы настроили производство, пора создать первый заказ",
        },
        delete: {
            title: "Удаление заказа",
            message: "Удалить заказ ",
        },
        exportMessage: "Экспорт списка заказов",
        view: {
            menu: {
                invoice: "Скачать счет на оплату",
                torg12: "Скачать накладную",
                export: "Экспорт состава",
            },
            print: {
                title: 'Печать заказа',
                personal: "Печатать данные покупателя",
                hidePrices: 'Скрыть стоимость',
                hideCost: 'Скрыть себестоимость'
            },
            exportMessage: 'Экспорт состава заказа'
        },
        edit: {
            title: {
                add: 'Новый заказ',
                edit: 'Редактировать заказ',
            },
            productionWillBeCreated: "Производство будет создано автоматически",
            productionWillBeCreatedTooltip: "Для заказа будет создано отдельное производство. Произведенная продукция не поступит на склад, а будет сразу списана под данный заказ.",
            addRow: 'Добавить строку',
            addProduct: 'Создать новый продукт',
            duplicateWarning: 'Это повтор. Вы можете создать заказ с повторяющимися продуктами, мы просто предупреждаем.',
            createFirst: 'Сначала создайте хотя бы один продукт',
        },
        snackbar: {
            added: "Заказ добавлен",
            edited: "Заказ обновлен",
            deleted: "Заказ удален",
        },
        error: {
            notFound: 'Заказ не найден. Возможно, он был удален. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        price: "Unit price",
        total: "Total price",
        ttl: "Total",
        cost: "Total cost",
        components: "Ordered products",
        fulfillment: "Fulfillment",
        fromStock: "From inventory",
        customProduction: "Made to order",
        noDataCta: {
            title: "Create your first product sale",
            subtitle: "After setting up your production, it's time to create your first sale",
        },
        delete: {
            title: "Delete sale",
            message: "Delete sale ",
        },
        exportMessage: "Export sales list",
        view: {
            menu: {
                torg12: "",
                invoice: "Download invoice",
                export: "Export items",
            },
            print: {
                title: 'Print sale',
                personal: "Print customer data",
                hidePrices: 'Hide prices',
                hideCost: 'Hide cost'
            },
            exportMessage: 'Export sale items'
        },
        edit: {
            title: {
                add: 'New sale',
                edit: 'Edit sale',
            },
            productionWillBeCreated: "Production will be created automatically",
            productionWillBeCreatedTooltip: "A dedicated production run will be created for this sale. Products will be manufactured directly for the order without entering inventory first.",
            addRow: 'Add row',
            addProduct: 'Create new product',
            duplicateWarning: 'This is a duplicate. You can create a sale with duplicate products, but we recommend avoiding duplicates.',
            createFirst: 'Create at least one product first',
        },
        snackbar: {
            added: "Sale added",
            edited: "Sale updated",
            deleted: "Sale deleted",
        },
        error: {
            notFound: 'Sale not found. It may have been deleted. Try reloading the page.'
        }
    }
}