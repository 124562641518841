// translations/imports.js

export default {
    ru: {
        materials: 'Материалов',
        products: 'Продуктов',
        selectCsvFile: 'Выберите CSV файл для загрузки',
        oneLinePerMaterial: 'Одна строка файла должна соответствовать одному материалу',
        oneLinePerProduct: 'Одна строка файла должна соответствовать одному продукту',
        columnsOrder: 'Колонки могут располагаться в любом порядке',
        headersLine: 'Файл может содержать строку заголовков',
        encoding: 'Кодировка файла UTF-8',
        matchColumnsNext: 'На следующем шаге вы сможете сопоставить колонки.',
        separator: 'Разделитель колонок',
        comma: 'Запятая',
        semicolon: 'Точка с запятой',
        csvFile: 'Файл CSV',
        hasHeaders: 'Файл содержит строку заголовков',
        matchColumns: 'Сопоставьте колонки',
        controlataField: 'Поле Controlata',
        csvColumn: 'Колонка из файла CSV',
        unitTooltip: "Используйте следующие обозначения для единиц измерения:\n шт, кг, г, мг, ц, т, л, мл, м, см, мм, км, куб. м, кв. м, пог. м и т.д.\nНераспознанные единицы будут импортированы как \"шт\"",
        upload: 'Загрузить',
        success: {
            imported: 'Успешно импортировано',
            lines: 'строк',
        },
        snackbar: "Файл CSV загружен",
        error: {
            materialsLimitReached: "Достигнут лимит материалов для вашего тарифного плана. Чтобы загрузить этот файл, пожалуйста, перейдите на другой тарифный план.",
            productsLimitReached: "Достигнут лимит продуктов для вашего тарифного плана. Чтобы загрузить этот файл, пожалуйста, перейдите на другой тарифный план.",
        }
    },
    en: {
        materials: 'Materials',
        products: 'Products',
        selectCsvFile: 'Select a CSV file to upload',
        oneLinePerMaterial: 'Each row in the file represents one material',
        oneLinePerProduct: 'Each row in the file represents one product',
        columnsOrder: 'Columns can be in any order',
        headersLine: 'File may include a header row',
        encoding: 'File must use UTF-8 encoding',
        matchColumnsNext: "You'll map columns in the next step.",
        separator: 'Column separator',
        comma: 'Comma',
        semicolon: 'Semicolon',
        csvFile: 'CSV file',
        hasHeaders: 'The file contains a header line',
        matchColumns: 'Match columns',
        controlataField: 'Controlata field',
        csvColumn: 'CSV file column',
        unitTooltip: "Use these standard unit abbreviations:\n pcs, kg, lbs, oz, l, gal, fl oz, m, ft, in, cu m, sq m, etc.\nUnrecognized units will default to \"pcs\"",
        upload: 'Upload',
        success: {
            imported: 'Successfully imported',
            lines: 'lines',
        },
        snackbar: "CSV file uploaded",
        error: {
            materialsLimitReached: "Materials limit reached for your current plan. Please upgrade to import this file.",
            productsLimitReached: "Products limit reached for your current plan. Please upgrade to import this file.",

        }
    }
}