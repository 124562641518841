<template>
	<v-autocomplete
		:value="value"
		:search-input.sync="search"
		:hide-no-data="!search"
		:items="computedCustomers"
		:readonly="loading"
		:return-object="returnObject"
		:multiple="multiple"
		:hide-selected="multiple"
		:label="label"
		:placeholder="placeholder"
		:persistent-placeholder="persistentPlaceholder"
		:rules="rules"
		:dense="computedDense"
		item-value="id"
		item-text="name"
		hide-details
		outlined
		:class="{ 'v-input--comfortable': comfortable }"
		:no-data-text="t('noData')"
		:menu-props="{
			closeOnClick: true,
			closeOnContentClick: true,
			disableKeys: true,
			openOnClick: false,
			maxHeight: 304,
			offsetY: true,
			offsetOverflow: false,
			transition: false,
		}"
		@input="$emit('input', $event)"
		@change="$emit('change', $event)"
	>
		<template v-slot:no-data v-if="allowAdd">
			<v-list-item
				v-if="search"
				:class="[{ 'v-list-item--comfortable': comfortable }]"
				@click="addCustomer"
			>
				<v-icon class="mr-3">mdi-plus-circle</v-icon>
				<span>{{ t("customers.createNew") }}</span>
				<a-chip class="ml-2" v-if="multiple">
					{{ search }}
				</a-chip>
				<span v-else> &nbsp"{{ search }}" </span>
			</v-list-item>
		</template>
		<template v-slot:selection="{ attrs, item, parent, selected }">
			<a-chip v-if="multiple" @delete="parent.selectItem(item)" deletable>
				<span :class="{ 'text-decoration-line-through': currentDisabled }">
					{{ item.name }}
				</span>
			</a-chip>
			<!-- <v-chip
				v-if="multiple && item === Object(item)"
				v-bind="attrs"
				:input-value="selected"
				label
				small
			>
				<span class="pr-2" :class="{ 'text-decoration-line-through': currentDisabled }">
					{{ item.name }}
				</span>
				<v-icon small @click.stop="parent.selectItem(item)"> mdi-close-circle </v-icon>
			</v-chip> -->
			<template v-else>
				<div
					class="v-select__selection v-select__selection--comma"
					:class="{ 'text-decoration-line-through': currentDisabled }"
				>
					{{ item.name }}
				</div>
			</template>
		</template>
		<template v-slot:append>
			<v-btn v-if="showEdit" small icon class="mt-n1" @click.stop="editCustomer" @mousedown.stop>
				<v-icon small>mdi-pencil</v-icon>
			</v-btn>
			<v-btn
				v-else-if="showAdd"
				icon
				style="margin-top: -6px"
				@click.stop="addCustomer"
				@mousedown.stop
			>
				<v-icon>mdi-plus-circle</v-icon>
			</v-btn>
		</template>
		<template v-slot:item="{ index, item, on, attrs }">
			<v-list-item v-on="on" v-bind="attrs" :class="[{ 'v-list-item--comfortable': comfortable }]">
				<a-chip v-if="multiple">
					<span
						v-if="item.disabled"
						style="color: rgba(0, 0, 0, 0.87)"
						class="text-decoration-line-through"
					>
						{{ item.name }}
					</span>
					<span v-else>{{ item.name }}</span>
				</a-chip>
				<!-- <v-chip v-if="multiple" label small>
					<span
						v-if="item.disabled"
						style="color: rgba(0, 0, 0, 0.87)"
						class="text-decoration-line-through"
					>
						{{ item.name }}
					</span>
					<span v-else style="cursor: pointer">{{ item.name }}</span>
				</v-chip> -->
				<span
					v-else-if="item.disabled"
					class="text-decoration-line-through"
					style="color: rgba(0, 0, 0, 0.87)"
				>
					{{ item.name }}
				</span>
				<span v-else>
					{{ item.name }}
				</span>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<script>
export default {
	props: {
		value: {
			type: [String, Number, Object, Array],
			default: "",
		},
		customers: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		dense: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
		persistentPlaceholder: {
			type: Boolean,
			default: false,
		},
		allowAdd: {
			type: Boolean,
			default: false,
		},
		allowEdit: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		initialId: {
			type: Number,
			default: 0,
		},
		initialName: {
			type: String,
			default: "",
		},
	},
	data: function () {
		return {
			search: "",
		}
	},
	computed: {
		label() {
			if (this.rules.some((r) => r("") !== true)) {
				return this.t("customer") + " *"
			} else {
				return this.t("customer")
			}
		},
		showEdit() {
			if (!this.allowEdit) return false
			if (this.multiple) {
				return this.value?.length === 1
			} else {
				return this.value
			}
		},
		showAdd() {
			if (!this.allowAdd) return false
			if (this.multiple) {
				return false
			} else {
				return !this.value
			}
		},
		initialDisabled() {
			if (this.customers.length == 0) return false
			else return this.initialId && !this.customers.some((s) => s.id == this.initialId)
		},
		currentDisabled() {
			return this.value && this.computedCustomers.find((s) => s.id == this.value)?.disabled
		},
		computedCustomers() {
			if (!this.initialDisabled) {
				return this.customers
			} else {
				return [
					...this.customers,
					{
						id: this.initialId,
						name: this.initialName,
						disabled: true,
					},
				]
			}
		},
		computedDense() {
			// По факту компонент отображается как dense, только когда он пустой
			// Иначе отступы между чипами и границей компонента слишком малы
			return this.dense && this.value.length == 0
		},
		comfortable() {
			return !this.large && !this.computedDense
		},
	},
	methods: {
		addCustomer() {
			this.$emit("add", this.search)
			this.search = ""
		},
		editCustomer() {
			if (this.multiple) {
				if (this.returnObject) {
					this.$emit("edit", this.value[0].id)
				} else {
					this.$emit("edit", this.value[0])
				}
			} else {
				if (this.returnObject) {
					this.$emit("edit", this.value.id)
				} else {
					this.$emit("edit", this.value)
				}
			}
		},
	},
}
</script>
