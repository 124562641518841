import api from '@/lib/api'

export default {
    namespaced: true,
    state: {
        purchases: [],
        orders: [],
        production: [],
        payments: [
            {
                code: 0,
                name: "notPaid",
                color: 0,
            },
            {
                code: 1,
                name: "partiallyPaid",
                color: 3,
            },
            {
                code: 2,
                name: "paid",
                color: 2,
            },
        ],
        loading: false,
    },
    getters: {
        purchases: state => state.purchases,
        orders: state => state.orders,
        production: state => state.production,
        payments: state => state.payments,
        loading: state => state.loading,
        loaded: (state, getters) => getters.orders?.length > 0,
    },
    mutations: {
        setPurchases(state, purchases) {
            state.purchases = purchases
        },
        setProduction(state, production) {
            state.production = production
        },
        setOrders(state, orders) {
            state.orders = orders
        },
        startLoading(state) {
            state.loading = true
        },
        stopLoading(state) {
            state.loading = false
        },
        clear(state) {
            state.purchases = []
            state.orders = []
            state.production = []
        }
    },
    actions: {
        getList(context, force = false) {
            // Статусы загружаются только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loaded && !force) return

            // Если загрузка уже идет, то ничего не делаем
            if (context.getters.loading) return

            context.commit('startLoading')
            return api.post("statuses/get_list").then((response) => {
                context.commit('setPurchases', response.data.purchases)
                context.commit('setProduction', response.data.production)
                context.commit('setOrders', response.data.orders)

                context.commit('stopLoading')
            })
        },
        clear(context) {
            context.commit('clear')
        },
    }
}