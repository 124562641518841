<template>
	<v-text-field
		:value="localValue"
		v-bind="$attrs"
		outlined
		:hide-details="!showDetails"
		:class="{ 'v-input--comfortable': !large && $attrs.dense === undefined }"
		@input="$emit('input', $event)"
		@keydown="$emit('keydown', $event)"
		@click="$emit('click', $event)"
		@click:append="$emit('click:append', $event)"
	></v-text-field>
</template>

<script>
export default {
	name: "ATextInput",

	data() {
		return {
			localValue: null,
		}
	},

	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		large: {
			type: Boolean,
			default: false,
		},
		showDetails: {
			type: Boolean,
			default: false,
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.localValue = val
			},
		},
	},
}
</script>
