<template>
	<v-autocomplete
		:value="value"
		:search-input.sync="search"
		:hide-no-data="!search"
		:items="categories"
		:readonly="loading"
		:disabled="disabled"
		:label="label"
		:small-chips="value.length > 0"
		:placeholder="placeholder"
		:persistent-placeholder="placeholder.length > 0"
		:rules="rules"
		:hide-selected="!many"
		:dense="computedDense"
		:return-object="returnObject"
		item-text="name"
		item-value="id"
		multiple
		outlined
		hide-details
		:no-data-text="t('noData')"
		:class="{ 'v-input--comfortable': comfortable }"
		:menu-props="{
			closeOnClick: true,
			closeOnContentClick: !many,
			disableKeys: true,
			openOnClick: false,
			maxHeight: 304,
			offsetY: true,
			offsetOverflow: false,
			transition: false,
		}"
		@input="$emit('input', $event)"
		@change="$emit('change', $event)"
	>
		<template v-slot:no-data v-if="allowAdd">
			<v-list-item
				v-if="search"
				:class="[{ 'v-list-item--comfortable': comfortable }]"
				@click="addCategory"
			>
				<v-icon class="mr-3">mdi-plus-circle</v-icon>
				<span class="subheading pr-2">{{ t("settings.categories.createNew") }}</span>
				<a-chip :color="colors[nextColor]">
					{{ search }}
				</a-chip>
				<!-- <v-chip :color="colors[nextColor]" style="cursor: pointer" label small>
					{{ search }}
				</v-chip> -->
			</v-list-item>
		</template>
		<template v-slot:selection="{ attrs, item, parent, selected }">
			<a-chip :color="colors[item.color]" @delete="parent.selectItem(item)" deletable>
				{{ item.name }}
			</a-chip>
			<!-- <v-chip
				v-if="item === Object(item)"
				v-bind="attrs"
				:color="colors[item.color]"
				:input-value="selected"
				label
				small
			>
				<span class="pr-2">
					{{ item.name }}
				</span>
				<v-icon small @click.stop="parent.selectItem(item)"> mdi-close-circle </v-icon>
			</v-chip> -->
		</template>
		<template v-slot:item="{ index, item, on, attrs }">
			<v-list-item
				v-on="on"
				v-bind="attrs"
				#default="{ active }"
				:class="[{ 'v-list-item--comfortable': comfortable }]"
			>
				<v-list-item-action class="ml-0 mr-3" v-if="many">
					<v-checkbox :ripple="false" :input-value="active"></v-checkbox>
				</v-list-item-action>
				<!-- <v-chip :color="colors[item.color]" style="cursor: pointer" label small>
					{{ item.name }}
				</v-chip> -->
				<a-chip :color="colors[item.color]">
					{{ item.name }}
				</a-chip>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<script>
export default {
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		categories: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
		allowAdd: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default() {
				return this.t("categories")
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		many: {
			// Когда ожидается большое количество выбранных категорий
			// Не скрываем меню выбора посл нажатия
			// Отображаем галочки выбора
			type: Boolean,
			default: false,
		},
		dense: {
			// Отображается как dense, только когда он пустой
			type: Boolean,
			default: false,
		},
		denseAlways: {
			// Отображается как dense всегда
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: true,
		},
	},
	data: function () {
		return {
			search: "",
		}
	},
	computed: {
		nextColor() {
			if (this.categories.length > 0) {
				return (+this.categories[this.categories.length - 1].color + 1) % this.colors.length
			} else {
				return 0
			}
		},
		computedDense() {
			return (this.dense && this.value.length == 0) || this.denseAlways
		},
		comfortable() {
			return !this.large && !this.computedDense
		},
		colors() {
			return this.$store.getters.colors
		},
	},
	methods: {
		addCategory() {
			this.$emit("add", {
				name: this.search,
				color: this.nextColor,
			})
			this.search = ""
		},
	},
}
</script>
