// translations/customers.js

export default {
    ru: {
        type: 'Тип',
        personalName: 'Имя',
        legalName: 'Наименование',
        inn: 'ИНН',
        kpp: 'КПП',
        ogrn: 'ОГРН',
        addressLegal: 'Юридический адрес',
        addressReal: 'Адрес доставки',
        manager: 'Руководитель',
        post: 'Должность',
        phone: 'Телефон',
        email: 'Email',
        agreement: 'Договор',
        ordersCount: 'Количество заказов',
        ordersTotal: 'Сумма заказов',
        deliveryDaysAvg: "Среднее время обработки",
        deliveryDays: "Время обработки",
        exportMessage: "Экспорт списка покупателей",
        mergeMessage: "Никакая уникальная информация не будет потеряна. Вы уверены?",
        createNew: 'Создать нового покупателя',
        noDataCta: {
            title: 'Здесь будут показаны ваши покупатели',
            subtitle: 'Вы можете добавить покупателя в процессе создания заказа или на этом экране'
        },
        view: {
            menu: {
                export: "Экспорт списка заказов",
            },
            print: {
                title: 'Печать покупателя',
                orders: 'Печатать заказы',
            },
            noOrdersYet: 'Заказов пока нет',
            exportMessage: "Экспорт заказов от покупателя"
        },
        edit: {
            title: {
                add: 'Новый покупатель',
                edit: 'Редактировать покупателя',
            },
            fillByInn: 'Заполнить по ИНН',
            fillInnFirst: 'Сначала введите ИНН',
        },
        snackbar: {
            added: 'Покупатель добавлен',
            edited: 'Покупатель обновлен',
            deleted: 'Покупатель удален',
            merged: "Покупатели объединены",
            emailCopied: "Email скопирован",
            phoneCopied: "Телефон скопирован"
        },
        error: {
            notFound: 'Покупатель не найден. Возможно, он был удален. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        type: 'Type',
        personalName: 'Name',
        legalName: 'Name',
        inn: 'INN',
        kpp: 'KPP',
        ogrn: 'OGRN',
        addressLegal: 'Legal address',
        addressReal: 'Delivery address',
        manager: 'Manager',
        post: 'Position',
        phone: 'Phone',
        email: 'Email',
        agreement: 'Agreement',
        ordersCount: 'Sales count',
        ordersTotal: 'Total sales value',
        deliveryDaysAvg: "Average processing time",
        deliveryDays: "Processing time",
        exportMessage: "Export customers list",
        mergeMessage: 'No unique information will be lost. Are you sure?',
        createNew: 'Create new customer',
        noDataCta: {
            title: 'Your customers will be shown here',
            subtitle: 'You can add customers when creating a sale or directly on this screen'
        },
        view: {
            menu: {
                export: "Export sales list",
            },
            print: {
                title: 'Print customer',
                orders: 'Print sales',
            },
            noOrdersYet: 'No sales yet',
            exportMessage: "Export customer sales"
        },
        edit: {
            title: {
                add: 'New customer',
                edit: 'Edit customer',
            },
            fillByInn: '',
            fillInnFirst: '',
        },
        snackbar: {
            added: 'Customer added',
            edited: 'Customer updated',
            deleted: 'Customer deleted',
            merged: "Customers merged",
            emailCopied: "Email copied",
            phoneCopied: "Phone copied"
        },
        error: {
            notFound: 'Customer not found. It may have been deleted. Try reloading the page.'
        }
    }
}