<template>
	<v-row>
		<v-col cols="12" class="pb-1" v-if="allFiles.length">
			<v-chip
				icon
				small
				label
				v-for="(file, index) in allFiles"
				:key="index"
				class="mr-2 mb-2"
				:style="isImage(file) ? 'cursor: pointer' : ''"
			>
				<v-tooltip top open-delay="100" content-class="pa-1" :disabled="!isImage(file)">
					<template v-slot:activator="{ on, attrs }">
						<span class="pr-2" v-bind="attrs" v-on="on">
							<a-truncated-text width="350px" :text="file.name" />
						</span>
					</template>
					<v-img :src="getImgSrc(file)" height="80" width="80"></v-img>
				</v-tooltip>
				<v-icon small @click.stop="removeFile(index)"> mdi-close-circle </v-icon>
			</v-chip>
		</v-col>
		<v-col cols="12">
			<v-btn outlined small @click="$refs.files.click()">
				<v-icon left> mdi-paperclip </v-icon>
				{{ label }}
			</v-btn>
			<input ref="files" type="file" hidden multiple @change="uploadNewFiles" />
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		// New uploading files
		value: {
			type: Array,
			default: () => [],
		},
		existingFiles: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default() {
				return this.t("files.add")
			},
		},
	},
	data() {
		return {
			imageExtensions: ["jpg", "jpeg", "png"],
			maxFileSize: 1024 * 1024 * 50, // 50 MB
		}
	},
	computed: {
		allFiles() {
			return [...this.existingFiles, ...this.value]
		},
	},
	methods: {
		getImgSrc(file) {
			if (file.urlSmall) return file.urlSmall
			else if (this.isImage(file)) {
				return URL.createObjectURL(file)
			} else return null
		},
		isImage(file) {
			const extension = file.name.split(".").pop().toLowerCase()
			return this.imageExtensions.includes(extension)
		},
		checkFileSize(files) {
			const totalSize = files.reduce((acc, file) => acc + file.size, 0)
			if (totalSize < this.maxFileSize) return true
			else {
				this.$store.dispatch("dialog/notifyOpen", {
					title: this.t("files.sizeErrorTitle"),
					message:
						this.t("files.sizeErrorLimitMessage") +
						`${this.maxFileSize / 1024 / 1024} MB.<br>` +
						this.t("files.sizeErrorActualSizeMessage") +
						`${Math.round(totalSize / 1024 / 1024)} MB`,
					btnAcceptText: this.t("ok"),
				})
				return false
			}
		},
		uploadNewFiles($event) {
			// check files size
			const newFiles = [...$event.target.files]

			if (!this.checkFileSize(newFiles)) {
				return
			}

			// add files to the existing files
			const files = [...this.value, ...newFiles]

			// update the v-model
			this.$emit("input", files)

			// clear the input
			this.$refs.files.value = ""
		},
		removeFile(index) {
			if (index < this.existingFiles.length) {
				this.$emit(
					"update:existingFiles",
					this.existingFiles.filter((_, i) => i !== index)
				)
			} else {
				this.$emit(
					"input",
					this.value.filter((_, i) => i !== index - this.existingFiles.length)
				)
			}
		},
	},
}
</script>
