// translations/dialogs.js

export default {
    ru: {
        superConfirm: {
            typeToConfirm: 'Для подтверждения введите текст',
            below: 'в поле ниже',
            error: 'Текст не совпадает',
        },
        connection: {
            title: 'Проблемы с сетью',
            text: 'Похоже, что-то случилось с соединением с интернетом'
        },
        trialPastDue: {
            title: 'Выберите тарифный план',
            text1: 'Пробный период использования сервиса закончился. Пожалуйста, выберите тарифный план, чтобы и дальше пользоваться сервисом.',
            text2: 'Если вам нужно больше времени для принятия решения, пожалуйста, напишите нам в чат.'
        },
        pastDue: {
            title: 'Обновите платежные данные',
            card: 'Мы не смогли списать очередную оплату с вашей карты. Пожалуйста внесите оплату, чтобы и дальше пользоваться вашим тарифным планом.',
            invoice: 'Мы не получили оплату по выставленному счету. Пожалуйста внесите оплату, чтобы и дальше пользоваться вашим тарифным планом.<br /><br />Если вы уже оплатили счет, то пожалуйста, напишите нам в чат и пришлите копию платежного поручения.'
        },
        export: {
            title: 'Экспорт',
            format: 'Формат',
            download: 'Загрузить',
        },
        maintenance: {
            title: 'Технические работы 🛠',
            text: 'На сайте проводятся технические работы. Мы скоро все починим. Приносим извинения за неудобства.'
        },
        accessDenied: {
            title: "Ошибка доступа",
            message: "Недостаточно прав для просмотра данной страницы",
        },
        error: {
            title: 'Что-то пошло не так',
            message: 'Мы уже занимаемся этим вопросом. Пожалуйста, попробуйте позже. Если ошибка повторится, напишите нам в чат.',
            reload: 'Пожалуйста, перезагрузите страницу. Если ошибка повторится, напишите нам в чат.'
        }
    },
    en: {
        superConfirm: {
            typeToConfirm: 'Please type this text to confirm',
            below: 'in the box below',
            error: 'That doesn\'t match - please try again',
        },
        connection: {
            title: 'Network Connection Issue',
            text: 'Unable to connect to the server. Please check your internet connection.'
        },
        trialPastDue: {
            title: 'Select a Subscription Plan',
            text1: 'Your free trial has ended. Please select a subscription plan to continue using the service.',
            text2: 'If you need more time to decide, please contact us via chat.'
        },
        pastDue: {
            title: 'Payment Update Required',
            card: 'We were unable to process your card payment. Please update your payment details to continue using your subscription.',
            invoice: 'We have not received payment for your latest invoice. Please complete payment to maintain uninterrupted service.\n\nIf you have already paid, please contact us via chat and provide a copy of your payment confirmation.'
        },
        export: {
            title: 'Export',
            format: 'Format',
            download: 'Download'
        },
        maintenance: {
            title: 'System Maintenance 🛠',
            text: 'The system is currently undergoing maintenance. We apologize for any inconvenience and will restore service as soon as possible.'
        },
        accessDenied: {
            title: "Access Denied",
            message: "You don't have sufficient permissions to view this page",
        },
        error: {
            title: 'Something went wrong',
            message: 'We\'re already working on this issue. Please try again later. If the error persists, please contact us in chat.',
            reload: 'Please reload the page. If the error persists, please contact us in chat.'
        }
    }
}