<template>
	<span @mouseover="onHover" @mouseleave="onLeave">
		<v-tooltip v-model="show" bottom>
			<template v-slot:activator="{ on, attrs }">
				<div v-if="printRoute">
					{{ text }}
				</div>
				<router-link
					v-else-if="to"
					ref="link"
					class="text-no-wrap d-inline-block text-truncate internal-link"
					:class="{
						'blue--text text--darken-3': blue,
						'text--primary': !blue,
						'text-decoration-line-through': crossedOut,
					}"
					:style="'max-width: ' + width + '; vertical-align: middle;'"
					:to="to"
				>
					<span>{{ text }}</span>
				</router-link>
				<div
					v-else
					ref="link"
					class="text-no-wrap d-inline-block text-truncate"
					:class="{
						'internal-link': link,
						'blue--text text--darken-3': blue,
						'text--primary': !blue,
						'text-decoration-line-through': crossedOut,
					}"
					:style="'max-width: ' + width + '; vertical-align: middle;'"
					@click="$emit('click', $event)"
				>
					{{ text }}
				</div>
			</template>
			<span>{{ text }}</span>
		</v-tooltip>
	</span>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: "350px",
		},
		text: {
			type: [String, Number],
			default: "",
		},
		link: {
			type: Boolean,
			default: false,
		},
		blue: {
			type: Boolean,
			default: false,
		},
		crossedOut: {
			type: Boolean,
			default: false,
		},
		to: {
			type: [String, Object],
			default: "",
		},
	},
	data: function () {
		return {
			show: false,
		}
	},
	computed: {
		printRoute() {
			return this.$route.path.includes("/print/")
		},
	},
	methods: {
		onHover() {
			if (this.printRoute) {
				this.show = false
			} else if (this.to) {
				// link - это router-link
				this.show = this.$refs.link.$el.offsetWidth < this.$refs.link.$el.scrollWidth
			} else {
				// link - это div
				this.show = this.$refs.link.offsetWidth < this.$refs.link.scrollWidth
			}
		},
		onLeave() {
			this.show = false
		},
	},
}
</script>
