<template>
	<v-chip small label class="ma-1" v-bind="$attrs" :deletable="deletable" @click="$emit('click', $event)">
		<v-icon v-if="icon" left small>{{ icon }}</v-icon>
		<slot></slot>
		<v-icon v-if="deletable" small class="ml-2" @click.stop="$emit('delete', $event)">
			mdi-close-circle
		</v-icon>
	</v-chip>
</template>

<script>
export default {
	name: "AChip",

	props: {
		deletable: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: null,
		},
	},
}
</script>
