<template>
	<v-card class="rounded-lg pa-3">
		<v-card-title> Billing </v-card-title>
		<v-row v-if="!user">
			<v-col cols="12">
				<v-skeleton-loader type="list-item@2" />
			</v-col>
		</v-row>
		<v-card-text class="text--primary">
			<v-row>
				<v-col cols="12" class="text-body-2">
					Controlata is totally free until we launch billing. You can use all features without any
					restrictions.
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	data: () => ({}),
	computed: {
		...mapGetters({
			user: "user",
		}),
	},
	methods: {},
	created() {},
}
</script>
