// src/plugins/components.js
import Vue from 'vue'
import aAutocomplete from "@/components/global/AAutocomplete.vue"
import aBtnTooltip from "@/components/global/ABtnTooltip.vue"
import aCategories from "@/components/global/ACategories.vue"
import aCheckbox from '@/components/global/ACheckbox.vue'
import aChip from '@/components/global/AChip.vue'
import aDatePicker from "@/components/global/ADatePicker.vue"
import aDottedSelection from "@/components/global/ADottedSelection.vue"
import aFilesInput from "@/components/global/AFilesInput.vue"
import aFilesPreview from "@/components/global/AFilesPreview.vue"
import aHistoryComment from "@/components/global/AHistoryComment.vue"
import aInfoIcon from '@/components/global/AInfoIcon.vue'
import aNotesInput from "@/components/global/ANotesInput.vue"
import aNotesView from "@/components/global/ANotesView.vue"
import aNumberInput from "@/components/global/ANumberInput.vue"
import aPaymentChip from "@/components/global/APaymentChip.vue"
import aSelect from "@/components/global/ASelect.vue"
import aSelectCategories from "@/components/global/ASelectCategories.vue"
import aSelectComponent from "@/components/global/ASelectComponent.vue"
import aSelectCustomers from "@/components/global/ASelectCustomers.vue"
import aSelectStorage from "@/components/global/ASelectStorage.vue"
import aSelectSuppliers from "@/components/global/ASelectSuppliers.vue"
import aStatusChip from "@/components/global/AStatusChip.vue"
import aSwitch from '@/components/global/ASwitch.vue'
import aTextInput from '@/components/global/ATextInput.vue'
import aTruncatedText from "@/components/global/ATruncatedText.vue"
import aViewCategories from "@/components/global/AViewCategories.vue"

// Register components globally
Vue.component('AAutocomplete', aAutocomplete)
Vue.component('ABtnTooltip', aBtnTooltip)
Vue.component('ACategories', aCategories)
Vue.component('ACheckbox', aCheckbox)
Vue.component('AChip', aChip)
Vue.component('ADatePicker', aDatePicker)
Vue.component('ADottedSelection', aDottedSelection)
Vue.component('AFilesInput', aFilesInput)
Vue.component('AFilesPreview', aFilesPreview)
Vue.component('AHistoryComment', aHistoryComment)
Vue.component('AInfoIcon', aInfoIcon)
Vue.component('ANotesInput', aNotesInput)
Vue.component('ANotesView', aNotesView)
Vue.component('ANumberInput', aNumberInput)
Vue.component('APaymentChip', aPaymentChip)
Vue.component('ASelect', aSelect)
Vue.component('ASelectCategories', aSelectCategories)
Vue.component('ASelectComponent', aSelectComponent)
Vue.component('ASelectCustomers', aSelectCustomers)
Vue.component('ASelectStorage', aSelectStorage)
Vue.component('ASelectSuppliers', aSelectSuppliers)
Vue.component('AStatusChip', aStatusChip)
Vue.component('ASwitch', aSwitch)
Vue.component('ATextInput', aTextInput)
Vue.component('ATruncatedText', aTruncatedText)
Vue.component('AViewCategories', aViewCategories)