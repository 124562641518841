// translations/statuses.js

export default {
    ru: {
        stocksWillBeUpdated: 'Следующие остатки будут обновлены',
        stocksWillNotChange: 'Остатки не изменятся',
        snackbar: {
            purchase: 'Статус поставки обновлен',
            order: 'Статус заказа обновлен',
            production: 'Статус производства обновлен',
            payment: {
                purchase: 'Статус оплаты поставки обновлен',
                order: 'Статус оплаты заказа обновлен'
            }
        },
        payment: {
            purchase: {
                paid: 'Оплачена',
                partiallyPaid: 'Частично оплачена',
                notPaid: 'Не оплачена',
            },
            order: {
                paid: 'Оплачен',
                partiallyPaid: 'Частично оплачен',
                notPaid: 'Не оплачен',
            }
        }
    },
    en: {
        stocksWillBeUpdated: 'The following inventory levels will be updated',
        stocksWillNotChange: 'Stock levels will not change',
        snackbar: {
            purchase: 'Purchase status updated',
            order: 'Sale status updated',
            production: 'Production status updated',
            payment: {
                purchase: 'Purchase payment status updated',
                order: 'Sale payment status updated'
            }
        },
        payment: {
            purchase: {
                paid: 'Paid',
                partiallyPaid: 'Partially paid',
                notPaid: 'Unpaid',
            },
            order: {
                paid: 'Paid',
                partiallyPaid: 'Partially paid',
                notPaid: 'Unpaid',
            }
        }
    }
}