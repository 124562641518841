<template>
	<div>
		<v-skeleton-loader v-if="loading" type="list-item@2" />
		<v-data-table
			v-else
			:headers="headers"
			:items="invoices"
			no-data-text="Счетов пока нет"
			disable-sort
			mobile-breakpoint="0"
			:class="mobile ? 'mb-9' : ''"
			:items-per-page="25"
			:hide-default-footer="invoices.length < 25"
			:footer-props="{
				itemsPerPageText: t('itemsPerPage'),
				itemsPerPageAllText: t('itemsPerPageAll'),
				itemsPerPageOptions: [10, 25, 50, -1],
				class: 'pr-15',
			}"
		>
			<template v-slot:item.num="{ item }">
				<span class="text-no-wrap">
					{{ item.num }}
				</span>
			</template>
			<template v-slot:item.date="{ item }">
				<span class="text-no-wrap">
					{{ formatDate(item.date) }}
				</span>
			</template>
			<template v-slot:item.amount="{ item }">
				<span class="text-no-wrap"> {{ formatNumber(item.amount, 2) }} ₽ </span>
			</template>
			<template v-slot:item.status="{ item }">
				<span class="text-no-wrap">
					{{ statusText(item.status) }}
				</span>
			</template>
			<template v-slot:item.actions="{ item }">
				<span class="text-no-wrap">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								v-if="admin && item.status == 'NEW'"
								v-bind="attrs"
								v-on="on"
								@click="setPaid(item)"
								label
								small
								style="cursor: pointer"
								class="mr-2"
							>
								<v-icon small left> mdi-cash </v-icon>
								Оплачен
							</v-chip>
						</template>
						<span> Установить статус "Оплачен" </span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								v-bind="attrs"
								v-on="on"
								@click="downloadInvoice(item)"
								label
								small
								style="cursor: pointer"
								class="mr-2"
							>
								<v-progress-circular
									v-if="item.loadingInvoice"
									indeterminate
									size="16"
									width="2"
									class="mx-4"
								></v-progress-circular>
								<div v-else style="width: 48px">
									<v-icon small left> mdi-download </v-icon>
									Счет
								</div>
							</v-chip>
						</template>
						<span> Скачать счет на оплату в PDF </span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								v-if="item.status != 'NEW'"
								v-bind="attrs"
								v-on="on"
								@click="downloadAct(item)"
								label
								small
								style="cursor: pointer"
								class="mr-2"
							>
								<v-progress-circular
									v-if="item.loadingAct"
									indeterminate
									size="16"
									width="2"
									class="mx-4"
								></v-progress-circular>
								<div v-else style="width: 48px">
									<v-icon small left> mdi-download </v-icon>
									Акт
								</div>
							</v-chip>
						</template>
						<span> Скачать акт об оказании услуг в PDF </span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-if="item.status == 'NEW'"
								v-bind="attrs"
								v-on="on"
								small
								@click="deleteInvoice(item)"
							>
								mdi-delete
							</v-icon>
						</template>
						<span> Удалить </span>
					</v-tooltip>
				</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import api from "@/lib/api"
import dayjs from "dayjs"
import { mapGetters } from "vuex"

export default {
	props: {
		admin: {
			type: Boolean,
			default: false,
		},
		open: {
			type: Boolean,
			default: false,
		},
		countNew: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		invoices: [],
		loading: false,
	}),
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		headers() {
			let headers = [
				{
					text: "Номер",
					value: "num",
					align: "start",
					class: "text-no-wrap",
				},
				{
					text: "Дата",
					value: "date",
					align: "start",
					class: "text-no-wrap",
				},
				{
					text: "Сумма",
					value: "amount",
					align: "start",
					class: "text-no-wrap",
				},
				{
					text: "Статус",
					value: "status",
					align: "end",
					class: "text-no-wrap",
				},
				{
					text: "",
					value: "actions",
					align: "end",
					class: "text-no-wrap",
					sortable: false,
				},
			]

			if (this.admin) {
				headers.unshift(
					{
						text: "id компании",
						value: "companyId",
						align: "start",
						class: "text-no-wrap",
					},
					{
						text: "Компания",
						value: "companyName",
						align: "start",
						class: "text-no-wrap",
					},
					{
						text: "Юрлицо",
						value: "companyLegalName",
						align: "start",
						class: "text-no-wrap",
					}
				)
			}

			return headers
		},
		...mapGetters({
			user: "user",
		}),
	},
	created() {
		this.getInvoices()
	},
	watch: {
		open(val) {
			if (!val) return
			this.getInvoices()
		},
	},
	methods: {
		getInvoices() {
			this.loading = true
			return api
				.post("invoices/get_list", {
					admin: this.admin,
				})
				.then((response) => {
					this.invoices = response.data.invoices
					this.invoices.forEach((i) => this.$set(i, "loadingInvoice", false))
					this.invoices.forEach((i) => this.$set(i, "loadingAct", false))

					this.$emit("update:count", this.invoices.length)
					this.$emit(
						"update:countNew",
						this.invoices.reduce((acc, item) => (item.status == "NEW" ? acc + 1 : acc), 0)
					)
					this.loading = false
				})
		},
		downloadInvoice(item) {
			item.loadingInvoice = true
			return api
				.post(
					"invoices/download_invoice",
					{
						id: item.id,
						ext: "pdf",
					},
					{
						responseType: "blob",
					}
				)
				.then((response) => {
					item.loadingInvoice = false

					// create file link in browser's memory
					const href = URL.createObjectURL(response.data)

					let formattedDate = dayjs(item.date).format("DD.MM.YYYY")

					// create "a" HTML element with href to file & click
					const link = document.createElement("a")
					link.href = href
					link.setAttribute("download", `Счет на оплату №${item.num} от ${formattedDate}.pdf`)
					document.body.appendChild(link)
					link.click()

					// clean up "a" element & remove ObjectURL
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
		},
		downloadAct(item) {
			item.loadingAct = true
			return api
				.post(
					"invoices/download_act",
					{
						id: item.id,
						ext: "pdf",
					},
					{
						responseType: "blob",
					}
				)
				.then((response) => {
					item.loadingAct = false

					// create file link in browser's memory
					const href = URL.createObjectURL(response.data)

					// create "a" HTML element with href to file & click
					const link = document.createElement("a")
					link.href = href
					link.setAttribute("download", `Акт №${item.num} от ${item.date}.pdf`)
					document.body.appendChild(link)
					link.click()

					// clean up "a" element & remove ObjectURL
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
		},
		statusText(status) {
			if (status == "NEW") return "Новый"
			if (status == "PAID") return "Оплачен"
			else return ""
		},
		deleteInvoice(item) {
			this.$store
				.dispatch("dialog/confirmOpen", {
					title: "Удаление счет на оплату",
					message: "Удалить счет на оплату №" + item.num + " от " + formatDate(item.date) + "?",
					btnAcceptText: this.t("ok"),
					btnDenyText: this.t("back"),
				})
				.then((confirm) => {
					if (confirm) {
						this.loading = true
						api.post("invoices/delete", {
							id: item.id,
						}).then(() => {
							this.getInvoices()
						})
					}
				})
		},
		setPaid(item) {
			this.$store
				.dispatch("dialog/confirmOpen", {
					title: "Счет оплачен",
					message:
						"Установить статус Оплачен для счета №" +
						item.num +
						" от " +
						this.formatDate(item.date) +
						" для компании " +
						item.companyLegalName +
						" на сумму " +
						this.formatNumber(item.amount, 2, 2) +
						" ₽ ?",
					btnAcceptText: this.t("ok"),
					btnDenyText: this.t("back"),
				})
				.then((confirm) => {
					if (confirm) {
						api.post("invoices/set_paid", {
							id: item.id,
						}).then(() => {
							this.getInvoices()
						})
					}
				})
		},
	},
}
</script>
