// translations/plurals.js

export function pluralizeTime(num, unit, locale) {
    // Dictionary for translations and forms
    const units = {
        en: {
            d: {
                singular: 'd',
                plural: 'd'
            },
            days: {
                singular: 'day',
                plural: 'days'
            },
            m: {
                singular: 'mo',
                plural: 'mo'
            },
            months: {
                singular: 'month',
                plural: 'months'
            }
        },
        ru: {
            d: {
                one: 'дн.',
                few: 'дн.',
                many: 'дн.'
            },
            days: {
                one: 'день',    // 1, 21, 31, ...
                few: 'дня',     // 2-4, 22-24, ...
                many: 'дней'    // 5-20, 25-30, ...
            },
            m: {
                one: 'мес.',
                few: 'мес.',
                many: 'мес.'
            },
            months: {
                one: 'месяц',   // 1, 21, 31, ...
                few: 'месяца',  // 2-4, 22-24, ...
                many: 'месяцев' // 5-20, 25-30, ...
            }
        }
    }

    // Handle English pluralization
    if (locale === 'en') {
        const form = num === 1 ? 'singular' : 'plural'
        return `${num} ${units[locale][unit][form]}`
    }

    // Handle Russian pluralization
    if (locale === 'ru') {
        // Get last digit and last two digits
        const lastDigit = num % 10
        const lastTwoDigits = num % 100

        let form
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            form = 'many'
        } else if (lastDigit === 1) {
            form = 'one'
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            form = 'few'
        } else {
            form = 'many'
        }

        return `${num} ${units[locale][unit][form]}`
    }

    throw new Error('Unsupported locale')
}

export function pluralizeEntities(num, entity, locale) {
    // Винительный падеж
    // Удалить Х материалов
    // Удалить Х продуктов
    // Удалить Х покупателей
    // Удалить Х поставщиков

    // Для неодушевленных совпадает с именительным падежом
    // Для одушевленных совпадает с родительным падежом

    // Dictionary of forms for different entities
    const entities = {
        en: {
            materials: {
                singular: 'material',
                plural: 'materials'
            },
            products: {
                singular: 'product',
                plural: 'products'
            },
            customers: {
                singular: 'customer',
                plural: 'customers'
            },
            suppliers: {
                singular: 'supplier',
                plural: 'suppliers'
            }
        },
        ru: {
            materials: {
                one: 'материал',      // В винительном падеже совпадает с именительным для неодушевленных
                few: 'материала',     // В винительном падеже совпадает с именительным для неодушевленных
                many: 'материалов'    // Родительный падеж используется при числительных
            },
            products: {
                one: 'продукт',
                few: 'продукта',
                many: 'продуктов'
            },
            customers: {
                one: 'покупателя',    // Для чисел, оканчивающихся на 1 (кроме 11)
                few: 'покупателей',   // Для чисел 2-4, 22-24, 32-34 и т.д.
                many: 'покупателей'   // Для чисел 5-20, 25-30 и т.д.
            },
            suppliers: {
                one: 'поставщика',    // Для чисел, оканчивающихся на 1 (кроме 11)
                few: 'поставщиков',   // Для чисел 2-4, 22-24, 32-34 и т.д.
                many: 'поставщиков'   // Для чисел 5-20, 25-30 и т.д.
            }
        }
    }

    if (!entities[locale]) {
        throw new Error('Unsupported locale')
    }

    if (!entities[locale][entity]) {
        throw new Error('Unsupported entity')
    }

    // Handle English pluralization
    if (locale === 'en') {
        const form = num === 1 ? 'singular' : 'plural'
        return `${num} ${entities[locale][entity][form]}`
    }

    // Handle Russian pluralization
    if (locale === 'ru') {
        const lastDigit = num % 10
        const lastTwoDigits = num % 100

        let form
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            form = 'many'
        } else if (lastDigit === 1) {
            form = 'one'
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            form = 'few'
        } else {
            form = 'many'
        }

        return `${num} ${entities[locale][entity][form]}`
    }

    throw new Error('Unsupported locale')
}