<template>
	<v-tooltip bottom right max-width="500">
		<template v-slot:activator="{ on }">
			<!-- inherit component class -->
			<v-icon :class="$attrs.class" v-bind="$attrs" v-on="on" style="cursor: default">
				mdi-information-outline
			</v-icon>
		</template>
		<span v-html="text" style="white-space: pre-line"></span>
	</v-tooltip>
</template>

<script>
export default {
	name: "AInfoIcon",

	inheritAttrs: false,

	props: {
		text: {
			type: String,
			default: "",
		},
	},
}
</script>
