// translations/common.js

// В этом словаре специально нет следующих ключей,
// Поскольку их перевод зависит от контекста
// И должен быть определен в конкретных модулях словаря:
// - price
// - cost
// - components

export default {
    ru: {
        aCopy: "Копия",
        add: "Добавить",
        all: "Все",
        amount: "Количество",
        amt: "Кол-во",
        and: "и",
        areYouSure: "Вы уверены?",
        audit: "Инвентаризация",
        audits: "Инвентаризации",
        back: "Назад",
        belowMinimum: "Ниже минимума",
        cancel: "Отмена",
        categories: "Категории",
        categoriesAny: "Любая из выбранных категорий",
        category: "Категория",
        comment: "Комментарий",
        continue: "Продолжить",
        copy: "Копировать",
        correction: "Корректировка",
        close: "Закрыть",
        create: "Создать",
        customer: "Покупатель",
        customers: "Покупатели",
        date: "Дата",
        dated: "от",
        datePlaced: "Дата заказа",
        dateReceived: "Дата получения",
        dateShipped: "Дата отправки",
        delete: "Удалить",
        delivery: "Доставка",
        discount: "Скидка",
        edit: "Редактировать",
        error: "Ошибка",
        export: "Экспорт",
        fact: "Факт",
        files: "Файлы",
        filters: "Фильтры",
        from: "от",
        fromTime: "с",
        helpCenterUrl: "https://help.controlata.ru",
        hide: "Скрыть",
        history: "История",
        import: "Импорт",
        inStorage: "На складе",
        itemsPerPage: "Записей на странице",
        itemsPerPageAll: "Все",
        loading: "Загрузка...",
        margin: "Маржинальность",
        material: "Материал",
        materials: "Материалы",
        materialOrProduct: "Материал / продукт",
        materialsStorage: "Склад материалов",
        merge: "Объединить",
        minimum: "Минимум",
        minimumStock: "Минимальный остаток",
        n: "№",
        name: "Название",
        next: "Далее",
        no: "Нет",
        noData: "Ничего не найдено",
        noHistory: "Истории пока нет",
        noName: "Без названия",
        notes: "Заметки",
        number: "Номер",
        ok: "Ок",
        or: "или",
        order: "Заказ",
        orders: "Заказы",
        payment: "Оплата",
        per: "за",
        plan: "План",
        pleaseWaitForLoading: "Подождите, идет загрузка данных",
        print: "Печать",
        product: "Продукт",
        production: "Производство",
        productions: "Производство",
        products: "Продукты",
        productsStorage: "Склад продуктов",
        purchase: "Поставка",
        purchases: "Поставки",
        report: "Отчет",
        reports: "Отчеты",
        resource: "Ресурс",
        resources: "Ресурсы",
        save: "Сохранить",
        search: "Поиск",
        selectAll: "Выбрать все",
        selected: "Выбрано",
        settings: "Настройки",
        sku: "SKU",
        status: "Статус",
        statuses: "Статусы",
        stock: "Остаток",
        stk: "Ост.",
        stockBelowMinimum: "Остаток ниже минимума",
        stockUpdated: "Остаток обновлен",
        storage: "Склад",
        storages: "Склады",
        subtotal: "Подытог",
        supplier: "Поставщик",
        suppliers: "Поставщики",
        suppliersAny: "Любой из выбранных поставщиков",
        thisIsDuplicate: "Это повтор",
        to: "до",
        toTime: "по",
        total: "Итого",
        transfer: "Перемещение",
        transfers: "Перемещения",
        unit: "Единица измерения",
        unt: "Ед. изм.",
        upgradePlan: "Повысить тариф",
        user: "Пользователь",
        users: "Пользователи",
        yes: "Да",
        websiteUrl: "https://controlata.ru",
        writeoff: "Списание",
        writeoffs: "Списания",
    },
    en: {
        aCopy: "Copy",
        add: "Add",
        all: "All",
        amount: "Quantity",
        amt: "Qty",
        and: "and",
        areYouSure: "Are you sure?",
        audit: "Audit",
        audits: "Audits",
        back: "Back",
        belowMinimum: "Below minimum",
        cancel: "Cancel",
        categories: "Categories",
        categoriesAny: "Any of selected categories",
        category: "Category",
        close: "Close",
        comment: "Comment",
        continue: "Continue",
        copy: "Copy",
        correction: "Adjustment",
        create: "Create",
        customer: "Customer",
        customers: "Customers",
        date: "Date",
        dated: "of",    // order #123 of 12.12.2020, production #321 of 12.12.2020
        datePlaced: "Date placed",
        dateReceived: "Date received",
        dateShipped: "Date shipped",
        delete: "Delete",
        delivery: "Delivery",
        discount: "Discount",
        edit: "Edit",
        error: "Error",
        export: "Export",
        fact: "Actual",     // plan vs actual
        files: "Files",
        filters: "Filters",
        from: "from",
        fromTime: "from",
        helpCenterUrl: "https://help.controlata.com",
        hide: "Hide",
        history: "History",
        import: "Import",
        inStorage: "In location",   // for editing stock level in particular location
        itemsPerPage: "Items per page",
        itemsPerPageAll: "All",
        loading: "Loading...",
        margin: "Margin",
        material: "Material",
        materials: "Materials",
        materialOrProduct: "Material / Product",
        materialsStorage: "Materials location",
        merge: "Merge",
        minimum: "Minimum",
        minimumStock: "Minimum stock level",
        n: "#",
        name: "Name",
        next: "Next",
        no: "No",
        noData: "No data found",
        noHistory: "No history yet",
        noName: "Unnamed",
        notes: "Notes",
        number: "Number",
        ok: "Ok",
        or: "or",
        order: "Sale",
        orders: "Sales",
        payment: "Payment",
        per: "per",
        plan: "Plan",
        pleaseWaitForLoading: "Please wait, loading data...",
        print: "Print",
        product: "Product",
        production: "Production",
        productions: "Productions",
        products: "Products",
        productsStorage: "Products location",
        purchase: "Purchase",
        purchases: "Purchases",
        report: "Report",
        reports: "Reports",
        resource: "Resource",
        resources: "Resources",
        save: "Save",
        search: "Search",
        selectAll: "Select all",
        selected: "Selected",
        settings: "Settings",
        sku: "SKU",
        status: "Status",
        statuses: "Statuses",
        stock: "Stock level",
        stk: "Stock",
        stockBelowMinimum: "Stock below minimum",
        stockUpdated: "Stock updated",
        storage: "Location",
        storages: "Storage locations",
        subtotal: "Subtotal",
        supplier: "Supplier",
        suppliers: "Suppliers",
        suppliersAny: "Any of selected suppliers",
        thisIsDuplicate: "This is a duplicate",
        to: "to",
        total: "Total",
        toTime: "to",
        transfer: "Transfer",
        transfers: "Transfers",
        unit: "Unit of measure",
        unt: "Unit",
        upgradePlan: "Upgrade plan",
        user: "User",
        users: "Users",
        yes: "Yes",
        websiteUrl: "https://controlata.com",
        writeoff: "Write-off",
        writeoffs: "Write-offs",
    }
}