// translations/storages.js

export default {
    ru: {
        upgradeNeeded: "Доступно на других тарифных планах",
        limitReached: 'Достигнут лимит складов',
        all: "Все",
        selectedCategories: "Только выбранные категории",
        selectedMaterials: "Только выбранные материалы",
        selectedProducts: "Только выбранные продукты",
        materialsCategories: "Категории материалов",
        productsCategories: "Категории продуктов",
        none: "Нет",
        defaults: {
            title: "Склады по умолчанию",
            text: "Укажите склады, которые будут предлагаться по умолчанию при создании новых поставок, производства и заказов. Вы всегда сможете выбрать нужный склад при создании или редактировании поставки, производства, заказа.",
            purchasedMaterials: "Склад по умолчанию для поставок материалов",
            purchasedProducts: "Склад по умолчанию для поставок продуктов",
            usedMaterials: "Склад по умолчанию для списания материалов при производстве",
            producedProducts: "Склад по умолчанию для произведенных продуктов",
            orders: "Склад по умолчанию для формирования заказов"
        },
        delete: {
            message: "При удалении склада будут обнулены все остатки на этом складе. Восстановить склад невозможно. Вы уверены?",
        },
        view: {
            materialsCount: 'Количество материалов',
            productsCount: 'Количество продуктов',
        },
        edit: {
            title: {
                add: "Добавить склад",
                edit: "Редактировать склад",
            },
            materialsLabel: "Какие материалы могут храниться на этом складе?",
            productsLabel: "Какие продукты могут храниться на этом складе?",
            emptyTooltip: "На складе должно что-то храниться"
        },
        leftover: {
            title: "Редактирование склада",
            message: "При изменении параметров склада следующие позиции остались на складе, потому что их остаток был не нулевым. Вы можете скорректировать остаток этих позиций и повторно обновить параметры склада."
        },
        snackbar: {
            added: "Склад добавлен",
            edited: "Склад обновлен",
            deleted: "Склад удален",
            defaults: "Настройки складов обновлены",
        },
        error: {
            notFound: 'Склад не найден. Возможно, он был удален. Попробуйте перезагрузить страницу.',
            noMaterialsOrProducts: "Склад должен содержать хотя бы один тип материалов или продуктов",
            deleteLastMaterialsStorage: "Нельзя удалить последний склад с материалами",
            deleteLastProductsStorage: "Нельзя удалить последний склад с продуктами",
        }
    },
    en: {
        upgradeNeeded: "Available on other plans",
        limitReached: 'Storage locations limit reached',
        all: "All",
        selectedCategories: "Only selected categories",
        selectedMaterials: "Only selected materials",
        selectedProducts: "Only selected products",
        materialsCategories: "Material categories",
        productsCategories: "Product categories",
        none: "None",
        defaults: {
            title: "Default storage locations",
            text: "Set default storage locations for new purchases, production runs, and sales. You can always change the location when creating or editing specific transactions.",
            purchasedMaterials: "Default storage location for purchased materials",
            purchasedProducts: "Default storage location for purchased products",
            usedMaterials: "Default storage location for materials used in production",
            producedProducts: "Default storage location for manufactured products",
            orders: "Default storage location for order fulfillment"
        },
        delete: {
            message: "Deleting this storage location will reset all inventory stored here. This action cannot be undone. Are you sure?",
        },
        view: {
            materialsCount: 'Number of materials',
            productsCount: 'Number of products',
        },
        edit: {
            title: {
                add: "Add storage location",
                edit: "Edit storage location",
            },
            materialsLabel: "Which materials can be stored at this location?",
            productsLabel: "Which products can be stored at this location?",
            emptyTooltip: "Storage location must contain at least one item type"
        },
        leftover: {
            title: "Edit storage location",
            message: "The following items remain in this location because they have non-zero inventory. Adjust their quantities before updating the location settings again."
        },
        snackbar: {
            added: "Storage location added",
            edited: "Storage location updated",
            deleted: "Storage location deleted",
            defaults: "Storage locations settings updated",
        },
        error: {
            notFound: 'Storage location not found. It may have been deleted. Try reloading the page.',
            noMaterialsOrProducts: "Storage location must include at least one material or product type",
            deleteLastMaterialsStorage: "Cannot delete the last storage location for materials",
            deleteLastProductsStorage: "Cannot delete the last storage location for products",
        }
    }
}