// translations/writeoffs.js

export default {
    ru: {
        cost: "Стоимость",
        costPerUnit: "Цена",
        components: "Состав",
        linkedToProduction: "Привязано к производству",
        linkToProduction: "Привязать к производству",
        noDataCta: {
            title: "Добавьте списание материалов или продуктов",
            subtitle: "Спишите использованные материалы или бракованные продукты, не привязываясь к производству",
        },
        delete: {
            title: "Удаление списания",
            message: "Удалить списание",
        },
        exportMessage: "Экспорт списка списаний",
        view: {
            menu: {
                export: "Экспорт состава",
            },
            print: {
                title: 'Печать списания',
                hidePrices: 'Скрыть стоимость',
            },
            exportMessage: 'Экспорт списка материалов / продуктов'
        },
        edit: {
            title: {
                add: 'Новое списание',
                edit: 'Редактировать списание',
            },
            addRow: 'Добавить строку',
            duplicateWarning: 'Это повтор. Вы можете создать списание с повторами, мы просто предупреждаем.',
            createComponentFirst: 'Сначала создайте хотя бы один материал или продукт',
            createProductionFirst: 'Сначала создайте хотя бы одно производство'
        },
        snackbar: {
            added: "Списание добавлено",
            edited: "Списание обновлено",
            deleted: "Списание удалено",
        },
        error: {
            notFound: 'Списание не найдено. Возможно, оно было удалено. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        cost: "Total cost",
        costPerUnit: "Unit cost",
        components: "Write-off items",
        linkedToProduction: "Linked to production",
        linkToProduction: "Link to production",
        noDataCta: {
            title: "Add your first write-off",
            subtitle: "Write off used materials or defective products without linking them to production",
        },
        delete: {
            title: "Delete write-off",
            message: "Delete write-off ",
        },
        exportMessage: "Export write-offs list",
        view: {
            menu: {
                export: "Export items",
            },
            print: {
                title: 'Print write-off',
                hidePrices: 'Hide prices',
            },
            exportMessage: 'Export write-off items'
        },
        edit: {
            title: {
                add: 'New write-off',
                edit: 'Edit write-off',
            },
            addRow: 'Add row',
            duplicateWarning: 'This is a duplicate. You can create a write-off with duplicate items, but we recommend avoiding duplicates.',
            createComponentFirst: 'Create at least one material or product first',
            createProductionFirst: 'Create at least one production first'
        },
        snackbar: {
            added: "Write-off added",
            edited: "Write-off updated",
            deleted: "Write-off deleted",
        },
        error: {
            notFound: 'Write-off not found. It may have been deleted. Try reloading the page.'
        }
    }
}