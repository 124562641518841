// translations/resources.js

export default {
    ru: {
        price: 'Цена',
        amortization: 'Амортизация',
        amortized: 'Амортизируемый',
        notAmortized: 'Не амортизируемый',
        initialCost: 'Первоначальная стоимость',
        usefulLife: 'Срок службы',
        leftCost: 'Остаточная стоимость',
        leftLife: 'Остаток срока службы',
        amortizedLife: 'Отработанный срок службы',
        noDataCta: {
            title: 'Учитывайте оплату сотрудникам, электроэнергию и т.д.',
            subtitle: 'Ресурсы - это все то, что используется для производстве, но не является материалом. Например, это может быть зарплата сотрудников, оплата коммунальных услуг, амортизация оборудования и т.д.',
        },
        delete: {
            message: "Ресурс будут удален в том числе из состава продуктов, в которые он входит. Восстановить ресурс будет невозможно. Вы уверены?",
            multipleMessage: "Ресурсы будут удалены в том числе из состава продуктов, в которые они входят. Восстановить их будет невозможно. Вы уверены, что хотите удалить эти ресурсы?",
        },
        exportMessage: "Экспорт списка ресурсов",
        view: {
            menu: {
                export: 'Экспорт истории'
            },
            creation: 'Создание ресурса',
            usedIn: 'Используется в составе',
            usedIn1Product: 'продукта',
            usedInNProducts: 'продуктов',
            print: {
                title: 'Печать ресурса',
                products: 'Печатать продукты',
                history: 'Печатать историю',
                hidePrices: 'Скрыть стоимость',
            },
            amountUsed: 'Количество в составе',
            exportMessage: 'Экспорт истории ресурса'
        },
        edit: {
            title: {
                add: 'Добавить ресурс',
                edit: 'Редактировать ресурс',
            },
            amortize: 'Амортизировать этот ресурс',
            amortizeTooltip: {
                new: "Используйте амортизацию для таких ресурсов, как оборудование, автомобили, здания и т.д.\n\nНе следует использовать амортизацию, если вы добавляете сотрудника, подрядчика, контрактное производство, электроэнергию или другую коммунальную услугу.",
                used: 'Этот ресурс уже был использован в производстве, поэтому изменить этот параметр нельзя.'
            },
            initialCostTooltip: {
                new: "Укажите полную стоимость, то есть ту сумма, за которую вы купили этот ресурс",
                used: "Этот ресурс уже был использован в производстве, поэтому изменить этот параметр нельзя."
            },
            priceTooltip: {
                new: "Укажите часовую ставку для сотрудника, тариф за кВт-ч для электроэнергии и т.д.",
                used: "Укажите часовую ставку для сотрудника, тариф за кВт-ч для электроэнергии и т.д.\n\nЭто повлияет только новое производство. Уже внесенное в систему производство не будет пересчитано."
            },
            usefulLifeTooltip: {
                new: 'Укажите расчетный полезный срок службы ресурса. Иногда его можно найти в документации к оборудованию. Либо вы можете указать оценочное значение.\n\n<b>Важно!</b> Указывайте именно срок полезной работы, без учета времени простоя.\n\nНапример, вы рассчитываете, что оборудование прослужит 2 года. За эти два года оно будет работать примерно 2000 часов. Укажите в этом поле 2000 часов, а не 2 года.',
                used: 'Этот ресурс уже был использован в производстве, поэтому изменить этот параметр нельзя.'
            },
            unitChangeWarning: 'Другие единицы измерения недоступны, так как ресурс используется в составе некоторых продуктов',
            multiple: {
                differentValues: 'Разные значения',
                differentUnits: 'Разные единицы измерения',
                someAmortized: 'Невозможно изменить цену у амортизируемых ресурсов'
            }
        },
        snackbar: {
            added: "Ресурс добавлен",
            edited: "Ресурс обновлен",
            deleted: "Ресурс удален",
            editedMultiple: "Ресурсы обновлены",
            deletedMultiple: "Ресурсы удалены",
        },
        error: {
            unitChange: 'Изменение единицы измерения невозможно, так как ресурс используется в других продуктах',
            notFound: 'Ресурс не найден. Возможно, он был удален. Попробуйте перезагрузить страницу.'
        }
    },
    en: {
        price: 'Price',
        amortization: 'Depreciation',
        amortized: 'Depreciable',
        notAmortized: 'Non-depreciable',
        initialCost: 'Initial cost',
        usefulLife: 'Useful life',
        leftCost: 'Residual value',
        leftLife: 'Remaining useful life',
        amortizedLife: 'Accumulated depreciation period',
        noDataCta: {
            title: 'Track labor costs, utilities, and other expenses',
            subtitle: 'Resources are everything used in production that isn\'t a material. For example, employee wages, utility costs, equipment depreciation, etc.',
        },
        delete: {
            message: "The resource will be removed from all products where it is used. This action cannot be undone. Are you sure?",
            multipleMessage: "The resources will be removed from all products where they are used. This action cannot be undone. Are you sure you want to delete these resources?",
        },
        exportMessage: "Export resources list",
        view: {
            menu: {
                export: 'Export history'
            },
            creation: 'Resource creation',
            usedIn: 'Used in',
            usedIn1Product: 'product',
            usedInNProducts: 'products',
            print: {
                title: 'Print resource',
                products: 'Print products',
                history: 'Print history',
                hidePrices: 'Hide prices',
            },
            amountUsed: 'Amount used',
            exportMessage: 'Export resource history'
        },
        edit: {
            title: {
                add: 'Add resource',
                edit: 'Edit resource',
            },
            amortize: 'Depreciate this resource',
            amortizeTooltip: {
                new: "Enable depreciation for capital assets such as equipment, vehicles, buildings, etc.\n\nDo not use depreciation for operational expenses like employee wages, contractor fees, outsourced manufacturing, utilities, or other services.",
                used: 'This resource has already been used in production and its depreciation setting cannot be modified.',
            },
            initialCostTooltip: {
                new: "Enter the total acquisition cost of this asset (purchase price plus any setup costs).",
                used: "This resource has already been used in production and its initial cost cannot be modified."
            },
            priceTooltip: {
                new: "Enter the rate for this resource (hourly rate for labor, price per kWh for electricity, etc.).",
                used: "Enter the rate for this resource (hourly rate for labor, price per kWh for electricity, etc.).\n\nNote: Changes will only apply to future production runs. Existing production data will not be recalculated."
            },
            usefulLifeTooltip: {
                new: 'Enter the estimated productive lifespan of this resource. You can find this in equipment specifications or use your own estimate.\n\n<b>Important!</b> Specify actual operating time, not calendar time.\n\nExample: If a machine will last 2 years but will only operate for 2000 hours during that period, enter 2000 hours here, not 2 years.',
                used: 'This resource has already been used in production and its useful life setting cannot be modified.'
            },
            unitChangeWarning: 'Other units are not available because this resource is used in products',
            multiple: {
                differentValues: 'Different values',
                differentUnits: 'Different units of measure',
                someAmortized: 'Cannot change price for depreciated resources'
            }
        },
        snackbar: {
            added: "Resource added",
            edited: "Resource updated",
            deleted: "Resource deleted",
            editedMultiple: "Resources updated",
            deletedMultiple: "Resources deleted",
        },
        error: {
            unitChange: 'Cannot change unit of measure because this resource is used in other products',
            notFound: 'Resource not found. It may have been deleted. Try reloading the page.'
        }
    }
}