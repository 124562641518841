// translations/files.js

export default {
    ru: {
        add: 'Добавить файл',
        sizeErrorTitle: 'Файлы слишком большие',
        sizeErrorLimitMessage: 'Общий размер файлов при загрузке не должен превышать ',
        sizeErrorActualSizeMessage: 'Размер загружаемых файлов - '
    },
    en: {
        add: 'Upload a file',
        sizeErrorTitle: 'Files are too large',
        sizeErrorLimitMessage: 'Total file size must not exceed ',
        sizeErrorActualSizeMessage: 'Current total file size: '
    }
}