<template>
	<v-select
		:value="value"
		:items="computedStorages"
		:rules="rules"
		:label="computedLabel"
		:disabled="disabled"
		:dense="dense"
		:clearable="clearable"
		:multiple="multiple"
		:placeholder="placeholder"
		:persistent-placeholder="placeholder.length > 0"
		item-text="name"
		item-value="id"
		outlined
		hide-details
		:class="{ 'v-input--comfortable': !large && !dense }"
		:menu-props="{
			closeOnClick: true,
			closeOnContentClick: !multiple,
			disableKeys: true,
			openOnClick: false,
			maxHeight: 304,
			offsetY: true,
			offsetOverflow: false,
			transition: false,
		}"
		@input="$emit('input', $event)"
		@change="$emit('change', $event)"
	>
		<!-- Выбрать все / снять все -->
		<template v-slot:prepend-item v-if="multiple && allowAll">
			<v-list-item ripple @mousedown.prevent @click="toggle">
				<v-list-item-action class="ml-0 mr-4 my-2">
					<v-icon :color="value.length > 0 ? 'primary' : ''">
						{{ icon }}
					</v-icon>
				</v-list-item-action>
				Выбрать все
			</v-list-item>
		</template>

		<!-- Selection multiple -->
		<template v-slot:selection="{ item, index }" v-if="multiple">
			<span v-if="value.length == 1" class="v-select__selection v-select__selection--comma">
				{{ item.name }}
			</span>
			<span v-else-if="value.length == storages.length && index == 0" class="grey--text">
				{{ placeholder }}
			</span>
			<span v-else-if="index == 0"> {{ value.length }} {{ suffix }} </span>
		</template>

		<!-- Selection single -->
		<template v-slot:selection="{ item }" v-else>
			<span
				v-if="!loading"
				class="v-select__selection v-select__selection--comma"
				:class="{ 'text-decoration-line-through': currentDisabled }"
			>
				{{ item.name }}
			</span>
		</template>

		<!-- Item multiple -->
		<template v-slot:item="{ index, item, on, attrs }" v-if="multiple">
			<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
				<v-list-item-action class="ml-0 mr-4 my-2">
					<v-checkbox :ripple="false" :input-value="active"></v-checkbox>
				</v-list-item-action>
				<span>
					{{ item.name }}
				</span>
			</v-list-item>
		</template>

		<!-- Item single -->
		<template v-slot:item="{ item }" v-else>
			<span
				v-if="item.disabled"
				class="text-decoration-line-through"
				style="color: rgba(0, 0, 0, 0.87)"
			>
				{{ item.name }}
			</span>
			<span v-else>
				{{ item.name }}
			</span>
		</template>
	</v-select>
</template>

<script>
export default {
	props: {
		value: {
			type: [Array, Number],
			default: 0,
		},
		storages: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		name: {
			type: String,
			default: "",
		},
		loading: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
		initialId: {
			type: Number,
			default: 0,
		},
		initialName: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: function () {
				return this.t("storage")
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		allowAll: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedLabel() {
			if (this.rules.some((r) => r("") !== true)) {
				return this.label + " *"
			} else {
				return this.label
			}
		},
		initialDisabled() {
			if (this.storages.length == 0) return false
			else return this.initialId && !this.storages.some((s) => s.id == this.initialId)
		},
		currentDisabled() {
			return this.value && this.computedStorages.find((s) => s.id == this.value)?.disabled
		},
		computedStorages() {
			if (!this.initialDisabled) {
				return this.storages
			} else {
				return [
					...this.storages,
					{
						id: this.initialId,
						name: this.initialName,
						disabled: true,
					},
				]
			}
		},
		icon() {
			if (this.value.length == this.storages.length) return "mdi-checkbox-marked"
			else if (this.value.length > 0) return "mdi-minus-box"
			return "mdi-checkbox-blank-outline"
		},
		suffix() {
			if (this.$locale === "en") return this.t("storages")
			else if (this.value.length < 5) return "склада"
			else return "складов"
		},
	},
	methods: {
		toggle() {
			if (this.value.length == this.storages.length) this.$emit("input", [])
			else {
				this.$emit(
					"input",
					this.storages.map((item) => item.id)
				)
			}
		},
	},
}
</script>
