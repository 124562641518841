// translations/login.js

export default {
    ru: {
        title: "Вход",
        recovery: {
            title: "Восстановление пароля",
            text: "Укажите вашу почту, и мы отправим вам ссылку для восстановления пароля",
            email: "Электронная почта",
            button: "Отправить",
            back: "Назад",
            success: {
                title: "Проверьте почту",
                message1: "Письмо с восстановлением пароля отправлено на:<br><br>",
                message2: ".<br><br>Если эта почта соответствует активному пользователю, вам придет письмо со ссылкой для восстановления пароля."
            }
        },
        form: {
            email: "Электронная почта",
            password: "Пароль",
            wrong: "Неверная почта или пароль",
            forgot: "Забыл пароль",
            login: "Войти"
        },
        error: {
            emptyFields: "Не указан email или пароль",
            wrongData: "Неверный email или пароль"
        },
        noAccount: "Нет аккаунта?",
        register: "Зарегистрироваться",
        setPassword: {
            title: "Установка пароля",
            success: "Пароль успешно установлен",
            login: "Войти",
            password: "Пароль",
            passwordCheck: "Повторите пароль",
            notMatch: "Пароли не совпадают",
            set: "Установить пароль",
            invalid: {
                title: "Ссылка недействительна или устарела",
                login: "Вход",
                register: "Регистрация"
            }
        }
    },
    en: {
        title: "Sign in",
        recovery: {
            title: "Password Reset",
            text: "Enter your email address and we'll send you a password reset link",
            email: "Email",
            button: "Send",
            back: "Back",
            success: {
                title: "Check your email",
                message1: "A password recovery email has been sent to:\n\n",
                message2: ".\n\nIf this email matches an active user account, you will receive a password reset link."
            }
        },
        form: {
            email: "Email address",
            password: "Password",
            wrong: "Incorrect email or password",
            forgot: "Forgot password",
            login: "Sign in",
        },
        error: {
            emptyFields: "Please enter your email and password",
            wrongData: "Incorrect email or password"
        },
        noAccount: "Don't have an account?",
        register: "Sign up",
        setPassword: {
            title: "Set password",
            success: "Password set successfully",
            login: "Sign in",
            password: "Password",
            passwordCheck: "Repeat password",
            notMatch: "Passwords do not match",
            set: "Set password",
            invalid: {
                title: "The link is invalid or expired",
                login: "Sign in",
                register: "Sign up"
            }
        }
    }
}