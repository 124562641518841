import dayjs from "dayjs"
import store from '@/store'

// Кэш для number форматтеров
const numberFormattersCache = new Map()

// Кэш для currency форматтера
let currencyFormatter = null
let lastLocale = null
let lastCurrencyCode = null
let lastMinDecimals = null
let lastMaxDecimals = null

// Функция для получения ключа кэша
const getCacheKey = (locale, minDecimals, maxDecimals) => `${locale}-${minDecimals}-${maxDecimals}`

// Функция для получения форматтера чисел из кэша
const getNumberFormatter = (locale, minDecimals = null, maxDecimals = null) => {
    const key = getCacheKey(locale, minDecimals, maxDecimals)

    if (!numberFormattersCache.has(key)) {
        const options = {
            minimumFractionDigits: minDecimals,
            maximumFractionDigits: maxDecimals,
        }

        numberFormattersCache.set(key, new Intl.NumberFormat(locale, options))
    }

    return numberFormattersCache.get(key)
}

// Функция для получения форматтера валют
const getCurrencyFormatter = (locale, currencyCode, minDecimals, maxDecimals) => {
    if (lastLocale !== locale
        || lastCurrencyCode !== currencyCode
        || lastMinDecimals !== minDecimals
        || lastMaxDecimals !== maxDecimals
    ) {
        currencyFormatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: minDecimals,
            maximumFractionDigits: maxDecimals
        })
        lastLocale = locale
        lastCurrencyCode = currencyCode
        lastMinDecimals = minDecimals
        lastMaxDecimals = maxDecimals
    }

    return currencyFormatter
}

export default {
    install(Vue) {
        Vue.prototype.formatNumber = function (value, minDecimals = 0, maxDecimals = 3) {
            const formatter = getNumberFormatter(store.getters.locale, minDecimals, maxDecimals)
            return formatter.format(value)
        }

        Vue.prototype.formatSignedNumber = function (value, minDecimals = 0, maxDecimals = 3) {
            const formatter = getNumberFormatter(store.getters.locale, minDecimals, maxDecimals)
            return (value >= 0 ? '+ ' : '- ') + formatter.format(Math.abs(value))
        }

        Vue.prototype.formatCurrency = function (value, decimals = null) {
            if (decimals === null) {
                decimals = store.getters.user.prefDecimals ? 2 : 0
            }

            if (store.getters.user.currencyCode === '') {
                // In case of empty currency code, just format number
                return this.formatNumber(value, decimals, decimals)
            }
            else {
                const formatter = getCurrencyFormatter(
                    store.getters.locale,
                    store.getters.user.currencyCode,
                    decimals,
                    decimals
                )
                return formatter.format(value)
            }
        }


        Vue.prototype.formatAdminCurrency = function (value) {
            if (store.getters.locale == "ru") {
                return this.formatNumber(value, 0, 0) + "₽"
            } else {
                return "$" + this.formatNumber(value, 0, 0)
            }
        }

        Vue.prototype.formatPercent = function (value, decimals = 1) {
            const formatter = getNumberFormatter(store.getters.locale, decimals, decimals)
            return formatter.format(value) + '%'
        }

        Vue.prototype.formatDate = function (date) {
            if (!date) return ''

            const format = store.getters.user?.prefDateFormat || 'DD.MM.YYYY'

            return dayjs(date).format(format)
        }

        Vue.prototype.formatDateTime = function (date) {
            if (!date) return ''

            const formatDate = store.getters.user?.prefDateTimeFormat || 'DD.MM.YYYY'
            const formatTime = 'HH:mm:ss'

            return dayjs(date).format(`${formatDate} ${formatTime}`)
        }
    }
}