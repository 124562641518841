<template>
	<div style="white-space: pre-wrap" v-html="processedNotes"></div>
</template>

<script>
export default {
	name: "ANotesView",

	props: {
		notes: {
			type: String,
			default: "",
		},
	},

	computed: {
		processedNotes() {
			if (!this.notes) return ""
			return this.notes.replace(
				/(https?:\/\/[^\s]+)/g,
				'<a href="$1" target="_blank" class="internal-link" rel="noopener noreferrer nofollow">$1</a>'
			)
		},
	},
}
</script>
