var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',{class:{ 'v-input--comfortable': !_vm.large && !_vm.dense },attrs:{"value":_vm.value,"items":_vm.computedStorages,"rules":_vm.rules,"label":_vm.computedLabel,"disabled":_vm.disabled,"dense":_vm.dense,"clearable":_vm.clearable,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"persistent-placeholder":_vm.placeholder.length > 0,"item-text":"name","item-value":"id","outlined":"","hide-details":"","menu-props":{
		closeOnClick: true,
		closeOnContentClick: !_vm.multiple,
		disableKeys: true,
		openOnClick: false,
		maxHeight: 304,
		offsetY: true,
		offsetOverflow: false,
		transition: false,
	}},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([(_vm.multiple && _vm.allowAll)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',{staticClass:"ml-0 mr-4 my-2"},[_c('v-icon',{attrs:{"color":_vm.value.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_vm._v(" Выбрать все ")],1)]},proxy:true}:null,(_vm.multiple)?{key:"selection",fn:function({ item, index }){return [(_vm.value.length == 1)?_c('span',{staticClass:"v-select__selection v-select__selection--comma"},[_vm._v(" "+_vm._s(item.name)+" ")]):(_vm.value.length == _vm.storages.length && index == 0)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):(index == 0)?_c('span',[_vm._v(" "+_vm._s(_vm.value.length)+" "+_vm._s(_vm.suffix)+" ")]):_vm._e()]}}:{key:"selection",fn:function({ item }){return [(!_vm.loading)?_c('span',{staticClass:"v-select__selection v-select__selection--comma",class:{ 'text-decoration-line-through': _vm.currentDisabled }},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},(_vm.multiple)?{key:"item",fn:function({ index, item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',{staticClass:"ml-0 mr-4 my-2"},[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},'v-list-item',attrs,false),on))]}}:{key:"item",fn:function({ item }){return [(item.disabled)?_c('span',{staticClass:"text-decoration-line-through",staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }