// translations/admin.js

export default {
    ru: {
        title: "Панель администратора",
        totalCompanies: "Всего компаний",
        online: "Онлайн",
        payingCustomers: "Плательщиков",
        conversion: "Конверсия в плательщиков",
        churn: "Отток",
        netConversion: "Чистая конверсия",
        mrr: "Выручка в месяц",
        averageCheck: "Средний чек",
        companyLifetimeMonths: "Средняя продолжительность",
        companyLifetimeValue: "Средняя общая стоимость подписки",
        userLifetimeValue: "Средняя стоимость регистрации",
        checkPayments: "Проверка оплат",
        checkStatuses: "Проверка статусов",
        checkStocks: "Проверка остатков",
        checkSigns: "Проверка знаков",
        compareDb: {
            title: "Сравнить базы данных",
            button: "Сравнить",
            noDifferences: "Различий не найдено",
            table: "Таблица",
            row: "Строка",
        },
        copyDb: {
            title: "Копировать базу данных",
            message: "База данных prod будет скопирована в stage. Это безопасно. Вы уверены?",
            button: "Копировать",
            snackbar: "База данных скопирована",
        },
        logs: {
            title: "Логи",
            button: "Открыть",
            company: "Компания",
            action: "Действие",
            result: "Результат"
        },
        tests: {
            title: "Тесты",
            button: "Открыть",
            totalTime: "Общее время",
            runAll: "Запустить все",
            runOne: "Запустить один",
            stop: "Остановить",
            reset: "Сбросить",
        },
        demo: {
            title: "Демо данные",
            button: "Сгенерировать",
            snackbar: "Демо данные сгенерированы",
        },
        companies: {
            title: "Компании",
            company: "Компания",
            extend: {
                title: "Продлить",
                message: "Продление пробного или оплаченного периода",
                button: "Продлить",
                days: "Количество дней",
            },
            plan: "Тарифный план",
            actDays: "Акт. дни",
            activeDays: "Активные дни",
            registered: "Регистрация",
            nextPaymentDate: "Дата следующей оплаты",
            nxtPmtDate: "След. оплата",
            cancelDate: "Дата окончания платной подписки",
            lastSeen: "Последний вход",
            lstSeen: "Послед. вход",
            revenue: "Выручка",
            sticker: "Стикер",
            industry: "Отрасль",
            email: "Электронная почта",
            position: "Позиция",
            headcount: "Количество сотрудников",
            turnover: "Оборот",
            price: "Стоимость",
            cancelled: "Отменен",
            pastdue: "Просрочка оплаты",
            adm: "адм",
            cardPayments: "Оплаты картой",
            invoices: "Счета на оплату",
            totalRevenue: "Выручка за все время",
            loginAs: "Зайти от имени",
            amount: "Сумма",
            type: "Тип",
            delete: {
                title: "Удалить компанию",
                message: "Данные компании будут стерты через один месяц. Вы уверены?"
            },
            snackbar: {
                extended: "Период продлен",
                adminNameCopied: "Имя пользователя скопировано",
                emailCopied: "Электронная почта скопирована",
                idCopied: "Id компании скопирован",
                companyNameCopied: "Название компании скопировано",
            }
        },
        uiElements: "UI элементы",
    },
    en: {
        title: "Admin panel",
        totalCompanies: "Total companies",
        online: "Online",
        payingCustomers: "Paying subscribers",
        conversion: "Conversion rate",
        churn: "Churn rate",
        netConversion: "Net conversion",
        mrr: "Monthly recurring revenue",
        averageCheck: "Average revenue per user",
        companyLifetimeMonths: "Average duration",
        companyLifetimeValue: "Customer lifetime value",
        userLifetimeValue: "Registration value",
        checkPayments: "Check payments",
        checkStatuses: "Check statuses",
        checkStocks: "Check stock levels",
        checkSigns: "Check signs",
        compareDb: {
            title: "Compare databases",
            button: "Compare",
            noDifferences: "No differences found",
            table: "Table",
            row: "Row",
        },
        copyDb: {
            title: "Copy database",
            message: "The prod database will be copied to stage. This is safe. Are you sure?",
            button: "Copy",
            snackbar: "Database copied",
        },
        logs: {
            title: "Logs",
            button: "Open",
            company: "Company",
            action: "Action",
            result: "Result"
        },
        tests: {
            title: "Tests",
            button: "Open",
            totalTime: "Total time",
            runAll: "Run all",
            runOne: "Run one",
            stop: "Stop",
            reset: "Reset",
        },
        demo: {
            title: "Demo data",
            button: "Generate",
            snackbar: "Demo data generated",
        },
        companies: {
            title: "Companies",
            company: "Company",
            extend: {
                title: "Extend",
                message: "Extend trial period or paid subscription",
                button: "Extend",
                days: "Number of days",
            },
            plan: "Plan",
            actDays: "Act. days",
            activeDays: "Active days",
            registered: "Registered",
            nextPaymentDate: "Next payment date",
            nxtPmtDate: "Next payment",
            cancelDate: "Subscription expiration date",
            lastSeen: "Last seen",
            lstSeen: "Last seen",
            revenue: "Revenue",
            sticker: "Sticker",
            industry: "Industry",
            email: "Email",
            position: "Position",
            headcount: "Headcount",
            turnover: "Turnover",
            price: "Price",
            cancelled: "Cancelled",
            pastdue: "Past due",
            adm: "adm",
            cardPayments: "Card payments",
            invoices: "Invoices",
            totalRevenue: "Total revenue",
            loginAs: "Login as",
            amount: "Amount",
            type: "Type",
            delete: {
                title: "Delete company",
                message: "Company data will be erased in one month. Are you sure?"
            },
            snackbar: {
                extended: "Period extended",
                adminNameCopied: "User name copied",
                emailCopied: "Email copied",
                idCopied: "Company id copied",
                companyNameCopied: "Company name copied",
            }
        },
        uiElements: "UI elements",
    }
}