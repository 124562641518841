<template>
	<v-text-field
		:value="displayValue"
		v-bind="$attrs"
		outlined
		hide-details
		:dense="dense"
		:prefix="computedPrefix"
		:suffix="computedSuffix"
		:class="[alignClass, { 'v-input--comfortable': !large && !dense }]"
		:rules="computedRules"
		@input="handleInput"
		@focus="handleFocus"
		@blur="handleBlur"
		@keydown="$emit('keydown', $event)"
		@click="$emit('click', $event)"
		@mousedown="$emit('mousedown', $event)"
		@change="$emit('change', $event)"
	>
		<template v-slot:append v-if="info.length">
			<a-info-icon :text="info" />
		</template>
	</v-text-field>
</template>

<script>
export default {
	name: "ANumberInput",

	inheritAttrs: false,

	props: {
		value: {
			type: [Number, String],
			default: null,
		},
		currency: {
			// Флаг валюты. Если true, то число будет отображаться с двумя знаками после запятой
			type: Boolean,
			default: false,
		},
		currencySymbol: {
			// Флаг символа валюты. Если true, то будет отображаться символ валюты
			type: Boolean,
			default: false,
		},
		textAlign: {
			type: String,
			default: "left",
			validator: function (value) {
				return ["left", "center", "right"].indexOf(value) !== -1
			},
		},
		rules: {
			type: Array,
			default: () => [],
		},
		large: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		info: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			focused: false,

			// Локальное состояние компонента, которое используется для хранения промежуточного значения поля ввода во время редактирования пользователем.
			// Используется только когда поле находится в фокусе (focused === true)
			// Хранит "сырое" значение, введенное пользователем, до его обработки и форматирования
			inputValue: null,
		}
	},

	computed: {
		alignClass() {
			return `text-${this.textAlign}`
		},

		isCurrency() {
			return this.currency || this.currencySymbol
		},

		displayValue() {
			if (this.focused) {
				// if (this.value === 0) return ""
				// else return this.inputValue

				return this.inputValue
			}

			if (this.value === null || this.value === "") {
				return ""
			}

			const numValue = Number(this.value)

			if (this.isCurrency) {
				return this.formatNumber(numValue, 2, 2)
			} else {
				return this.formatNumber(numValue, 0, 3)
			}
		},

		computedPrefix() {
			if (!this.currencySymbol) return this.$attrs.prefix || ""
			return this.$store.getters.user?.currencyLeft ? this.$store.getters.user?.currencySymbol : ""
		},

		computedSuffix() {
			if (!this.currencySymbol) return this.$attrs.suffix || ""
			return !this.$store.getters.user?.currencyLeft ? this.$store.getters.user?.currencySymbol : ""
		},

		// infoIconStyle() {
		// 	if (this.large) {
		// 		return "margin-top: 0px;"
		// 	} else if (this.dense) {
		// 		return "margin-top: 0px;"
		// 	} else {
		// 		return "margin-top: -2px;"
		// 	}
		// },

		computedRules() {
			// Add parseUserInput to the argument of every rule
			return this.rules.map((rule) => {
				return (value) => {
					return rule(this.parseUserInput(value))
				}
			})
		},
	},

	methods: {
		parseUserInput(value) {
			// Если произошло событие clear
			if (value === null) return null

			// Если введена пустая строка
			if (value === "") return null

			// Убираем все, кроме цифр, точки, запятой и минуса
			value = value.replace(/[^\d,.,-]/g, "")

			// Если есть запятая, считаем её разделителем дробной части
			if (value.includes(",")) {
				value = value.replace(/,/g, ".")
			}

			// Убираем все точки, кроме последней
			value = value.replace(/\.(?=.*\.)/g, "")

			// Проверяем, что осталось валидное число
			if (!/^-?\d*\.?\d*$/.test(value)) {
				return null
			}

			const num = Number(value)
			if (isNaN(num)) return null

			return this.isCurrency ? +num.toFixed(2) : +num.toFixed(3)
		},

		handleInput(value) {
			this.inputValue = value
			const parsedValue = this.parseUserInput(value)
			this.$emit("input", parsedValue)
		},

		handleFocus() {
			this.focused = true

			if (this.value === 0 || this.value === null || this.value === "") {
				this.inputValue = ""
				return
			}

			const numValue = Number(this.value)
			const stringNum = this.isCurrency ? numValue.toFixed(2) : numValue.toString()

			this.inputValue = this.$store.getters.locale === "ru" ? stringNum.replace(".", ",") : stringNum
		},

		handleBlur() {
			this.focused = false
			this.inputValue = null
		},
	},
}
</script>

<style scoped>
.text-left ::v-deep .v-text-field__slot input {
	text-align: left;
}
.text-center ::v-deep .v-text-field__slot input {
	text-align: center;
}
.text-right ::v-deep .v-text-field__slot input {
	text-align: right;
}

/* Make currency prefix and suffix gray when not focused and empty */
.v-text-field:not(.v-input--is-focused):not(.v-input--is-label-active)
	::v-deep
	.v-text-field__slot
	.v-text-field__prefix {
	color: rgba(0, 0, 0, 0.6);
}
.v-text-field:not(.v-input--is-focused):not(.v-input--is-label-active)
	::v-deep
	.v-text-field__slot
	.v-text-field__suffix {
	color: rgba(0, 0, 0, 0.6);
}
</style>
