// translations/reports.js

export default {
    ru: {
        materialsStocks: {
            title: "Стоимость материалов на складе",
            tooltip: "Показывает совокупную стоимость всех материалов на складе за выбранный период времени и с выбранной периодичностью.",
            includeDeletedMaterials: "Учитывать удаленные материалы",
            cost: "Стоимость",
        },
        productsStocks: {
            title: "Стоимость продукты на складе",
            tooltip: "Показывает совокупную стоимость всех продуктов на складе за выбранный период времени и с выбранной периодичностью",
            includeDeletedProducts: "Учитывать удаленные продукты",
            cost: "Стоимость",
            price: "Стоимость",
            atCost: "По себестоимости",
            atPrice: "По розничной цене",
        },
        materialsHistory: {
            title: "Движение материала",
            tooltip: "Показывает все операции с указанным материалом за определенный период времени. Для каждой операции отображается<ul> <li>количество</li><li>остаток на складе после операции</li><li>цена за единицу - стоимость одной единицы измерения материала для данной операции</li><li>стоимость операции - стоимость общего количества материала для данной операции</li><li>стоимость накопительно - стоимость остатка материала на складе после операции</li><li>статус - факт или план в зависимости от статуса связанной операции (поставки, производства и т.д.)</li></ul>",
            reasonLabel: "Типы операций",
            selectMaterial: "Выберите материал",
            initialStock: "Начальный остаток",
            costPerUnit: 'Цена',
            cost: 'Стоимость',
            costCarry: 'Стоимость накопительно',
        },
        productsHistory: {
            title: "Движение продукта",
            tooltip: "Показывает все операции с указанным продуктом за определенный период времени. Для каждой операции отображается<ul><li>количество</li><li>остаток на складе после операции</li><li>себестоимость за единицу - себестоимость одной единицы измерения продукта для данной операции</li><li>себестоимость операции - себестоимость общего количества продукта для данной операции</li><li>себестоимость накопительно - себестоимость остатка продукта на складе после операции</li><li>стоимость за единицу - стоимость одной единицы измерения продукта для данной операции в розничных ценах</li><li>стоимость операции - стоимость общего количества данной операции в розничных ценах</li><li>стоимость накопительно - стоимость остатка продукта на складе после операции в розничных ценах</li><li>статус - факт или план в зависимости от статуса связанной операции (производства, заказа и т.д.)</li></ul>",
            reasonLabel: "Типы операций",
            selectProduct: "Выберите продукт",
            initialStock: "Начальный остаток",
            costPerUnit: 'Себ-ть за ед.',
            cost: 'Себестоимость',
            costCarry: 'Себ-ть накопительно',
            pricePerUnit: 'Стоимость за ед.',
            price: 'Стоимость',
            priceCarry: 'Стоимость накопительно',
        },
        resourcesHistory: {
            title: "Использование ресурса",
            tooltip: "Показывает все операции с указанным ресурсом за определенный период времени. Для каждой операции отображается<ul><li>количество</li><li>цена за единицу - стоимость одной единицы измерения ресурса для данной операции</li><li>стоимость операции - стоимость общего количества ресурса для данной операции</li><li>статус - факт или план в зависимости от статуса связанной операции</li></ul>",
            selectResource: "Выберите ресурс",
            costPerUnit: 'Цена',
            cost: 'Стоимость',
        },
        materialsTurnover: {
            title: "Обороты материалов",
            tooltip: "Показывает суммарные обороты (приход и расход) материалов за указанный период времени.",
            cost: "Стоимость",
            start: "Начальный остаток",
            end: "Конечный остаток",
            plus: "Приход",
            minus: "Расход",
        },
        productsTurnover: {
            title: "Обороты продуктов",
            tooltip: "Показывает суммарные обороты (приход и расход) продуктов за указанный период времени.",
            cost: "Себестоимость",
            price: "Стоимость",
            start: "Начальный остаток",
            end: "Конечный остаток",
            plus: "Приход",
            minus: "Расход",
        },
        resourcesTurnover: {
            title: "Обороты ресурсов",
            tooltip: "Показывает суммарное использование ресурсов за указанный период времени.",
            costPerUnit: 'Цена',
            cost: 'Стоимость',
        },
        materialsAtDate: {
            title: "Остатки материалов на дату",
            tooltip: "Показывает количество, среднюю цену и общую стоимость материалов на выбранную дату. Остатки указываются на конец дня.\n\nВ этом отчете цена рассчитывается как средняя цена материала на складе, и поэтому может отличаться от цены в карточке материала, где указывается цена последней поставки.",
            belowMinimum: "Только с остатком ниже минимума",
            costPerUnit: 'Цена',
            cost: 'Стоимость',
        },
        productsAtDate: {
            title: "Остатки продуктов на дату",
            tooltip: "Показывает количество, себестоимость и розничную стоимость продуктов на выбранную дату. Остатки указываются на конец дня.\n\nВ этом отчете себестоимость и стоимость рассчитываются как средние значения для продуктов на складе, и поэтому могут отличаться от значений в карточке продукта, где указываются цены на основании последних поставок материалов.",
            belowMinimum: "Только с остатком ниже минимума",
            costPerUnit: 'Себ-ть за ед.',
            cost: 'Себестоимость',
            pricePerUnit: 'Стоимость за ед.',
            price: 'Стоимость',
        },
        purchasesVolume: {
            title: "Объем поставок",
            tooltip: "Показывает количество и совокупную стоимость всех поставок материалов и продуктов за выбранный период времени и с выбранной периодичностью.",
        },
        productionVolume: {
            title: "Объем производства",
            tooltip: "Показывает количество и совокупную стоимость всего производства за выбранный период времени и с выбранной периодичностью."
        },
        ordersVolume: {
            title: "Объем заказов",
            tooltip: "Показывает количество и совокупную стоимость всех заказов за выбранный период времени и с выбранной периодичностью.",
            averageCheck: "Средний чек",
        },
        purchasedMaterials: {
            title: "Закупленные материалы и продукты",
            tooltip: "Показывает количество, среднюю цену и общую стоимость закупленных материалов и продуктов за выбранный период времени.",
        },
        orderedProducts: {
            title: "Проданные продукты",
            tooltip: "Показывает количество, стоимость и себестоимость заказанных продуктов за выбранный период времени.",
            costPerUnit: "Средняя себестоимость",
            cost: "Себестоимость",
            pricePerUnit: "Средняя стоимость",
            price: "Стоимость",
        },
        producedProducts: {
            title: "Произведенные продукты",
            tooltip: "Показывает количество, стоимость и себестоимость произведенных продуктов за выбранный период времени.",
            price: "Стоимость",
            cost: "Себестоимость",
            costPerUnit: "Средняя себестоимость",
            pricePerUnit: "Средняя стоимость",
        },
        usedMaterials: {
            title: "Использованные материалы и продукты",
            tooltip: "Показывает количество, среднюю цену и общую стоимость использованных в производстве материалов и продуктов за выбранный период времени.",
            costPerUnit: 'Цена',
            cost: 'Стоимость',
        },
        materialsTurnoverRates: {
            title: "Оборачиваемость материалов",
            tooltip: "Показывает оборачиваемость материалов за указанный период времени.\n\nОборачиваемость считается двумя способами:<ol><li>В днях - показывает, за какое количество дней оборачивается средний запас на складе.</li><li>В оборотах - показывает, сколько раз за выбранный период времени оборачивается средний запас на складе.</li></ol>\nЗа оборот принимается расход материала в производство или списание.",
            method: 'Способ расчета',
            inDays: 'В днях',
            inTurns: 'В оборотах',
            turnover: 'Оборот за период',
            averageStock: 'Средний запас',
            turnoverRate: 'Оборачиваемость',
            turnoverDays: 'Оборачиваемость',
        },
        productsTurnoverRates: {
            title: "Оборачиваемость продуктов",
            tooltip: "Показывает оборачиваемость продуктов за указанный период времени.\n\nОборачиваемость считается двумя способами:<ol><li>В днях - показывает, за какое количество дней оборачивается средний запас на складе.</li><li>В оборотах - показывает, сколько раз за выбранный период времени оборачивается средний запас на складе.</li></ol>\nЗа оборот принимается продажи продукта.",
            method: 'Способ расчета',
            inDays: 'В днях',
            inTurns: 'В оборотах',
            turnover: 'Оборот за период',
            averageStock: 'Средний запас',
            turnoverRate: 'Оборачиваемость',
            turnoverDays: 'Оборачиваемость',
        },
        materialsAbc: {
            title: "ABC анализ материалов",
            tooltip: "ABC анализ разделяет материалы на три группы по степени важности:\n\n<ul><li>А — наиболее ценные, 20 % — ассортимента; 80 % — закупок</li><li>В — промежуточные, 30 % — ассортимента; 15 % — закупок</li><li>С — наименее ценные, 50 % — ассортимента; 5 % — закупок</li></ul>\nКонкретные значения в закупок могут отличаться в зависимости от бизнеса.\n\nВы также можете выбрать по какому показателю сортировать материалы: объем закупок или средний остаток на складе.",
            group: 'Группа',
            ofItems: 'позиций',
            total: "Итого",
            cost: "Объем закупок",
            stock: "Средний остаток на складе",
            percent: "Доля",
        },
        productsAbc: {
            title: "ABC анализ продуктов",
            tooltip: "ABC анализ разделяет продукты на три группы по степени важности:\n\n<ul><li>А — наиболее ценные, 20 % — ассортимента; 80 % — продаж</li><li>В — промежуточные, 30 % — ассортимента; 15 % — продаж</li><li>С — наименее ценные, 50 % — ассортимента; 5 % — продаж</li></ul>\nКонкретные значения в объеме продаж и валовой прибыли могут отличаться в зависимости от бизнеса.\n\nВы также можете выбрать по какому показателю сортировать продукты: валовая прибыль, объем продаж или себестоимость.",
            group: 'Группа',
            ofItems: 'позиций',
            total: "Итого",
            profit: "Валовая прибыль",
            price: "Объем продаж",
            cost: "Себестоимость",
            percent: "Доля",
        },
        profitAndLoss: {
            title: "Прибыли и убытки",
            tooltip: "Показывает выручку и расходы в разрезе категорий, а также валовую прибыль.\n\nВыручка считается на основании заказов.\n\nРасходы на материалы считаются на основании<ul><li>списания материалов в производство</li><li>списания материалов, не связанного с производством</li><li>результатов инвентаризаций</li></ul>\nРасходы на ресурсы считаются на основании их использовании в производстве.\n\nВаловая прибыль рассчитывается как разница между выручкой и суммарными расходами.",
            includeLabel: "Учитывать в выручке",
            showPercent: "Показывать проценты",
            revenue: "Выручка",
            materialsExpenses: "Расходы на материалы",
            resourcesExpenses: "Расходы на ресурсы",
            grossProfit: "Валовая прибыль",
            noCategory: "Без категории",
        },
        purchaseCostDynamics: {
            title: "Динамика закупочных цен",
            tooltip: "Показывает динамику закупочных цен на материалы и продукты.\n\nЗа каждый период указывается средневзвешенная цена за единицу товара на основании поставок.\n\nЕсли в определенном периоде не было поставок, то отображается цена предыдущего периода.",
        },
        productionCostDynamics: {
            title: "Динамика себестоимости производства",
            tooltip: "Показывает динамику себестоимости произведенных продуктов.\n\nЗа каждый период указывается средневзвешенная себестоимость за единицу продукта на основании производства.\n\nЕсли в определенном периоде не было производства, то отображается цена предыдущего периода.",
            costPerUnit: 'Себестоимость',
            cost: "Итого себестоимость",
        },
        orderPriceDynamics: {
            title: "Динамика отпускных цен",
            tooltip: "Показывает динамику отпускной стоимости проданных продуктов.\n\nЗа каждый период указывается средневзвешенная стоимость продукции на основании заказов.\n\nЕсли в определенном периоде не было заказов, то отображается цена предыдущего периода.",
        },
        range: {
            period: "Период",
            frequency: "Периодичность",
            current: "Текущий",
            last: "Прошлый",
            year: "год",
            month: "месяц",
            quarter: "квартал",
            days: "дней",
            custom: 'Произвольный период',
            all: 'Все время',
            daily: 'По дням',
            weekly: 'По неделям',
            monthly: 'По месяцам',
            quarterly: 'По кварталам',
            yearly: 'По годам',
        },
        general: 'Общее',
        refresh: "Обновить данные",
        downloadXlsx: "Скачать XLSX",
        includePlanned: "Учитывать плановые операции",
        chart: "График",
        generated: "Отчет сформирован",
        noDataForThisPeriod: "Нет данных за выбранный период",
        display: "Показывать",
        displayValues: "Показывать значения",
        sortBy: "Сортировать по",
        include: {
            label: "Учитывать в стоимости",
            onlyPrice: "Только цену",
            delivery: "Доставку",
            discount: "Скидку",
            deliveryAndDiscount: "Доставку, скидку",
        },
        details: "Подробнее",
        change: 'Изменение',
    },
    en: {
        materialsStocks: {
            title: "Materials Inventory Value",
            tooltip: "Shows the total value of all materials in stock for the selected period and with the selected frequency.",
            includeDeletedMaterials: "Include deleted materials",
            cost: "Cost",
        },
        productsStocks: {
            title: "Products Inventory Value",
            tooltip: "Shows the total value of all products in stock for the selected period and with the selected frequency.",
            includeDeletedProducts: "Include deleted products",
            cost: "Cost",
            price: "Sales price",
            atCost: "At cost",
            atPrice: "At sales price",
        },
        materialsHistory: {
            title: "Material Transaction History",
            tooltip: "Shows all operations with the specified material for the selected period. Each operation displays:<ul><li>quantity</li><li>stock level after the operation</li><li>unit cost - cost per individual material unit</li><li>total cost - aggregate cost of all material units in this operation</li><li>running total cost - cumulative cost of remaining stock after the operation</li><li>status - actual or planned, depending on the related transaction's status (purchase, production, etc.)</li></ul>",
            reasonLabel: "Operation types",
            selectMaterial: "Select material",
            initialStock: "Initial balance",
            costPerUnit: 'Unit cost',
            cost: 'Total cost',
            costCarry: 'Running total cost',
        },
        productsHistory: {
            title: "Product Transaction History",
            tooltip: "Shows all operations with the specified product for the selected period. Each operation displays:<ul><li>quantity</li><li>stock level after the operation</li><li>unit cost - cost per individual product unit</li><li>total cost - aggregate cost of all product units in this operation</li><li>running total cost - cumulative cost of remaining stock after the operation</li><li>unit price - sales price per individual product unit</li><li>total price - aggregate sales price for all product units in this operation</li><li>running total price - cumulative sales value of remaining stock after the operation</li><li>status - actual or planned, depending on the related transaction's status (production, sale, etc.)</li></ul>",
            reasonLabel: "Operation types",
            selectProduct: "Select product",
            initialStock: "Initial balance",
            costPerUnit: 'Unit cost',
            cost: 'Total cost',
            costCarry: 'Running total cost',
            pricePerUnit: 'Unit price',
            price: 'Total price',
            priceCarry: 'Running total price',
        },
        resourcesHistory: {
            title: "Resource Usage History",
            tooltip: "Shows all operations with the specified resource for the selected period. Each operation displays:<ul><li>quantity</li><li>unit cost - cost per individual resource unit</li><li>total cost - aggregate cost of all resource units in this operation</li><li>status - actual or planned, depending on the related transaction's status</li></ul>",
            selectResource: "Select resource",
            costPerUnit: 'Unit cost',
            cost: 'Total cost',
        },
        materialsTurnover: {
            title: "Materials Turnover",
            tooltip: "Shows the total turnover (inflows and outflows) of materials for the selected period.",
            cost: "Cost",
            start: "Opening balance",
            end: "Closing balance",
            plus: "Inflow",
            minus: "Outflow",
        },
        productsTurnover: {
            title: "Products Turnover",
            tooltip: "Shows the total turnover (inflows and outflows) of products for the specified period of time.",
            cost: "Cost",
            price: "Sales price",
            start: "Opening balance",
            end: "Closing balance",
            plus: "Inflow",
            minus: "Outflow",
        },
        resourcesTurnover: {
            title: "Resources Turnover",
            tooltip: "Shows the total usage of resources for the selected period.",
            costPerUnit: 'Unit cost',
            cost: 'Total cost',
        },
        materialsAtDate: {
            title: "Materials Inventory at Date",
            tooltip: "Shows the quantity, unit cost, and total cost of materials on the selected date. The stock levels are shown as of the end of the day.\n\nIn this report, unit cost is calculated as the average cost of the material in inventory, which may differ from the unit cost shown in the material details, where the cost of the most recent purchase is displayed.",
            belowMinimum: "Only with stock below minimum",
            costPerUnit: 'Unit cost',
            cost: 'Total cost',
        },
        productsAtDate: {
            title: "Products Inventory at Date",
            tooltip: "Shows the quantity, unit cost, total cost, unit sales price, and total sales price of products on the selected date. The stock levels are shown as of the end of the day.\n\nIn this report, costs and sales prices are calculated as averages for products in inventory, which may differ from values shown in product details, where costs are based on the most recent material purchases.",
            belowMinimum: "Only with stock below minimum",
            costPerUnit: 'Unit cost',
            cost: 'Total cost',
            pricePerUnit: 'Unit price',
            price: 'Total price',
        },
        purchasesVolume: {
            title: "Purchase Volume",
            tooltip: "Shows the quantity and total value of all material and product purchases for the selected period and frequency.",
        },
        productionVolume: {
            title: "Production Volume",
            tooltip: "Shows the quantity and total value of all production for the selected period and frequency.",
        },
        ordersVolume: {
            title: "Sales Volume",
            tooltip: "Shows the quantity and total value of all sales for the selected period and frequency.",
            averageCheck: "Average check",
        },
        purchasedMaterials: {
            title: "Purchased Materials and Products",
            tooltip: "Shows the quantity, average price, and total cost of purchased materials and products for the selected period.",
        },
        orderedProducts: {
            title: "Sold Products",
            tooltip: "Shows the quantity, unit cost, total cost, unit price, and total sales price of products sold during the selected period.",
            costPerUnit: "Unit cost",
            cost: "Total cost",
            pricePerUnit: "Unit price",
            price: "Total price",
        },
        producedProducts: {
            title: "Manufactured Products",
            tooltip: "Shows the quantity, unit cost, total cost, unit sales price, and total sales price of products manufactured during the selected period.",
            price: "Total price",
            cost: "Total cost",
            costPerUnit: "Unit cost",
            pricePerUnit: "Unit price",
        },
        usedMaterials: {
            title: "Consumed Materials and Products",
            tooltip: "Shows the quantity, unit cost, and total cost of materials and products consumed in production during the selected period.",
            costPerUnit: "Unit cost",
            cost: "Total cost",
        },
        // All fine above this line
        materialsTurnoverRates: {
            title: "Materials Turnover Rates",
            tooltip: "Shows the inventory turnover rates of materials for the selected period.\n\nTurnover is calculated in two ways:<ol><li>In days - shows how many days the average inventory remains in stock before being consumed (Days of Inventory on Hand)</li><li>In turns - shows how many times the average inventory is completely consumed and replaced during the selected period</li></ol>\nMaterial turnover is measured by consumption in production or write-offs.",
            method: 'Calculation method',
            inDays: 'In days',
            inTurns: 'In turns',
            turnover: 'Turnover',
            averageStock: 'Average inventory',
            turnoverRate: 'Turnover ratio',
            turnoverDays: 'Days of inventory on hand',
        },
        productsTurnoverRates: {
            title: "Products Turnover Rates",
            tooltip: "Shows the inventory turnover rates of products for the selected period.\n\nTurnover is calculated in two ways:<ol><li>In days - shows how many days the average inventory remains in stock before being sold (Days of Inventory on Hand)</li><li>In turns - shows how many times the average inventory is completely sold and replaced during the selected period</li></ol>\nProduct turnover is measured by sales.",
            method: 'Calculation method',
            inDays: 'In days',
            inTurns: 'In turns',
            turnover: 'Turnover',
            averageStock: 'Average stock',
            turnoverRate: 'Turnover ratio',
            turnoverDays: 'Days of inventory on hand',
        },
        materialsAbc: {
            title: "Materials ABC Analysis",
            tooltip: "ABC analysis categorizes materials into three groups by business importance:<ul><li>A - high value (20% of items representing 80% of purchase value)</li><li>B - medium value (30% of items representing 15% of purchase value)</li><li>C - low value (50% of items representing 5% of purchase value)</li></ul>\nThese percentages may vary depending on your business.\n\nYou can sort materials by either purchase volume or average inventory level.",
            group: 'Group',
            ofItems: 'of items',
            total: "Total",
            cost: "Purchase volume",
            stock: "Average inventory level",
            percent: "Percentage",
        },
        productsAbc: {
            title: "Products ABC Analysis",
            tooltip: "ABC analysis categorizes products into three groups by business importance:<ul><li>A - high value (20% of items representing 80% of sales value)</li><li>B - medium value (30% of items representing 15% of sales value)</li><li>C - low value (50% of items representing 5% of sales value)</li></ul>\nThese percentages may vary depending on your business.\n\nYou can sort products by gross profit, sales volume, or cost.",
            group: 'Group',
            ofItems: 'of items',
            total: "Total",
            profit: "Gross profit",
            price: "Sales revenue",
            cost: "Cost",
            percent: "Percentage",
        },
        profitAndLoss: {
            title: "Profit and Loss",
            tooltip: "Displays revenue and expenses by category, along with gross profit analysis.\n\nRevenue is calculated from sales orders.\n\nMaterial expenses are calculated from:<ul><li>materials consumed in production</li><li>materials written off (not related to production)</li><li>inventory audits</li></ul>\nResource expenses are calculated based on resource usage in production.\n\nGross profit is calculated as revenue minus total expenses.",
            includeLabel: "Include in revenue",
            showPercent: "Show percentages",
            revenue: "Revenue",
            materialsExpenses: "Material expenses",
            resourcesExpenses: "Resource expenses",
            grossProfit: "Gross profit",
            noCategory: "No category",
        },
        purchaseCostDynamics: {
            title: "Purchase Price Trends",
            tooltip: "Displays purchase price trends for materials and products over time.\n\nEach period shows the weighted average unit price based on purchases made during that period.\n\nIf no purchases occurred in a specific period, the price from the previous period is carried forward.",
        },
        productionCostDynamics: {
            title: "Production Cost Trends",
            tooltip: "Displays manufacturing cost trends for products over time.\n\nEach period shows the weighted average unit cost based on production runs during that period.\n\nIf no production occurred in a specific period, the cost from the previous period is carried forward.",
            costPerUnit: "Unit cost",
            cost: "Total cost",
        },
        orderPriceDynamics: {
            title: "Sales Price Trends",
            tooltip: "Displays selling price trends for products over time.\n\nEach period shows the weighted average unit price based on sales during that period.\n\nIf no sales occurred in a specific period, the price from the previous period is carried forward.",
        },
        range: {
            period: "Period",
            frequency: "Frequency",
            current: "Current",
            last: "Last",
            year: "year",
            month: "month",
            quarter: "quarter",
            days: "days",
            custom: 'Custom period',
            all: 'All time',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            quarterly: 'Quarterly',
            yearly: 'Yearly',
        },
        general: 'General',
        refresh: "Refresh data",
        downloadXlsx: "Download XLSX",
        includePlanned: "Include planned operations",
        chart: "Chart",
        generated: "Report generated",
        noDataForThisPeriod: "No data available for selected period",
        display: "Display",
        displayValues: "Display values",
        sortBy: "Sort by",
        include: {
            label: "Price includes",
            onlyPrice: "Unit price only",
            delivery: "Delivery",
            discount: "Discount",
            deliveryAndDiscount: "Delivery, discount",
        },
        details: "Details",
        change: 'Change',
    }
}