<template>
	<a-truncated-text width="450px" :text="comment" :link="link" :blue="blue" :to="to" @click="onClick" />
</template>

<script>
import { orderNum } from "@/lib/utils"

export default {
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
		type: {
			// materials, products, resources
			type: String,
			default: "",
		},
		black: {
			// Makes all links black, used in reports
			type: Boolean,
			default: false,
		},
	},
	computed: {
		link() {
			// link отвечает за класс internal-link в ATruncatedText.vue
			//  - это просто добавляет hover:underline к тексту
			//  - нужно в случае, если это не router-link, но текст должен быть
			//    кликабельным для вызова диалогов edit creation и edit correction

			return (
				!this.black &&
				(this.type == "materials" || this.type == "products") &&
				(this.item.reason == 0 || this.item.reason == 3)
			)
		},
		to() {
			if (this.item.reason == 1 && this.item.productionId > 0) {
				return "/production/item/" + this.item.productionId
			} else if (this.item.reason == 2 && this.item.purchaseId > 0) {
				return "/purchases/item/" + this.item.purchaseId
			} else if (this.item.reason == 4 && this.item.auditId > 0) {
				return "/audits/" + this.item.auditId
			} else if (this.item.reason == 5 && this.item.orderId > 0) {
				return "/orders/item/" + this.item.orderId
			} else if (this.item.reason == 6 && this.item.transferId > 0) {
				return "/transfer/item/" + this.item.transferId
			} else if (this.item.reason == 7 && this.item.writeoffId > 0) {
				return "/writeoff/item/" + this.item.writeoffId
			} else {
				return null
			}
		},
		blue() {
			// Black makes all links not blue
			if (this.black) return false
			// If there is a router-link, make it blue
			else if (this.to !== null) return true
			// If it is styled as a link
			else if (this.link) {
				return true
			} else return false
		},
		comment() {
			switch (this.item.reason) {
				case 0:
					return this.t(this.type + ".view.creation")
				case 1:
					if (this.type == "materials" || this.type == "resources") {
						return (
							this.t("production") +
							" " +
							this.t("n") +
							this.item.productionNum +
							", " +
							this.item.productAmount +
							" x " +
							this.item.productName
						)
					} else if (this.type == "products") {
						return (
							this.t("production") +
							" " +
							this.t("n") +
							this.item.productionNum +
							" " +
							this.item.productionName
						)
					} else {
						return this.t("production")
					}
				case 2:
					return this.t("purchase") + " " + this.t("from") + " " + this.item.supplierName
				case 3:
					return this.t("correction") + (this.item.notes ? ": " + this.item.notes : "")
				case 4:
					return this.t("audit") + " " + this.t("n") + this.item.auditNum
				case 5:
					return this.t("order") + " " + this.t("n") + orderNum(this.item.orderNum)
				case 6:
					return this.t("transfer") + " " + this.t("n") + orderNum(this.item.transferNum)
				case 7:
					return this.t("writeoff") + " " + this.t("n") + orderNum(this.item.writeoffNum)
			}
		},
	},
	methods: {
		onClick($event) {
			if (this.item.reason == 0) {
				this.$emit("editCreation", $event)
			} else if (this.item.reason == 3) {
				this.$emit("editCorrection", $event)
			}
		},
	},
}
</script>
