// translations/onboarding.js

export default {
    ru: {
        title: "Привет! 👋",
        subtitle: "Пожалуйста, расскажите немного о вашей компании. Это поможет настроить Controlata наиболее подходящим образом именно для вашего бизнеса.",
        industry: {
            question: "Чем занимается ваша компания?",
            options: [
                "Автотовары и запчасти",
                "Детские товары и игрушки",
                "Изделия из кожи",
                "Корма и товары для животных",
                "Красота и здоровье",
                "Мебель и товары для дома",
                "Металлическое производство",
                "Одежда и обувь",
                "Пищевое производство",
                "Свечи, ароматы для дома",
                "Строительство",
                "Технологичное производство",
                "Полиграфия и печать",
                "Производство упаковки",
                "Украшения и аксессуары",
                "Химическое производство",
                "Другое",
            ],
            other: "Другое",
            specify: "Укажите отрасль"
        },
        headcount: {
            question: "Сколько у вас сотрудников?",
            options: [
                "Пока только я",
                "2-10",
                "11-50",
                "51-100",
                "Больше 100"
            ]
        },
        turnover: {
            question: "Какой оборот у компании в месяц?",
            options: [
                "Пока нет выручки",
                "До 2 млн ₽",
                "2-5 млн ₽",
                "6-20 млн ₽",
                "21-50 млн ₽",
                "Больше 50 млн ₽",
            ]
        },
        position: {
            question: "Какова ваша роль в компании?",
            options: [
                "Основатель",
                "Генеральный директор",
                "Закупщик",
                "Бухгалтер",
                "IT менеджер",
                "Другая"
            ],
            other: "Другая",
            specify: "Укажите вашу роль"
        },
        finish: "Начать работу"
    },
    en: {
        title: "Hello! 👋",
        subtitle: "Please tell us a bit about your company. This will help us set up Controlata in the most suitable way for your business.",
        industry: {
            question: "What does your company do?",
            options: [
                "Apparel",
                "Automotive parts",
                "Beauty products",
                "Chemicals",
                "Construction materials",
                "Electronics",
                "Food production",
                "Furniture",
                "Glass and ceramics",
                "Industrial equipment",
                "Jewelry accessories",
                "Leather goods",
                "Metal fabrication",
                "Packaging",
                "Pet products",
                "Plastics production",
                "Printing",
                "Textiles",
                "Toys",
                "Woodworking",
                "Other",
            ],
            other: "Other",
            specify: "Specify industry"
        },
        headcount: {
            question: "How many employees do you have?",
            options: [
                "Just me",
                "2-10",
                "11-50",
                "51-100",
                "More than 100"
            ]
        },
        turnover: {
            question: "What is your company's monthly revenue?",
            options: [
                "No revenue yet",
                "Up to $20K",
                "$20K-$50K",
                "$50K-$200K",
                "$200K-$500K",
                "More than $500K",
            ]
        },
        position: {
            question: "What is your role in the company?",
            options: [
                "Founder",
                "CEO",
                "Operations Manager",
                "Procurement Manager",
                "Finance Manager",
                "IT Manager",
                "Other",
            ],
            other: "Other",
            specify: "Specify your role"
        },
        finish: "Get started"
    }
}