// translations/rules.js

export default {
    ru: {
        required: "Обязательно",
        positive: "Должно быть больше нуля",
        nonNegative: "Должно быть больше или равно нулю",
        wrongEmail: "Неверный формат email",
        minEight: "Минимум 8 символов",
        hasNumber: "Должно содержать цифру",
        hasLowercase: "Должно содержать строчную букву",
        hasUppercase: "Должно содержать заглавную букву",
        hasLetter: "Должно содержать букву"
    },
    en: {
        required: "Required",
        positive: "Must be greater than zero",
        nonNegative: "Must be greater than or equal to zero",
        wrongEmail: "Invalid email format",
        minEight: "Minimum 8 characters",
        hasNumber: "Must contain at least one number",
        hasLowercase: "Must contain at least one lowercase letter",
        hasUppercase: "Must contain at least one uppercase letter",
        hasLetter: "Must contain at least one letter"
    }
}