<template>
	<span class="caption">
		<span
			v-for="(item, i) in items"
			:key="item.id"
			:class="isSelected(item.id) ? 'font-weight-bold' : ''"
			@click="toggle(item.id)"
		>
			<span class="link-dotted">{{ item.name }}</span>
			<span v-if="i < items.length - 1"> / </span>
		</span>
	</span>
</template>

<style scoped>
.link-dotted {
	text-decoration: none;
	border-bottom: dotted 1px;
	cursor: pointer;
}
</style>

<script>
export default {
	props: {
		items: Array,
		multiple: {
			type: Boolean,
			default: false,
		},
		value: [Number, String, Array],
	},
	methods: {
		toggle(id) {
			if (this.multiple) {
				if (this.value.includes(id)) {
					this.$emit(
						"input",
						this.value.filter((i) => i !== id)
					)
				} else {
					this.$emit("input", [...this.value, id])
				}
			} else {
				this.$emit("input", id)
			}
		},
		isSelected(id) {
			if (this.multiple) {
				return this.value.includes(id)
			} else {
				return this.value === id
			}
		},
	},
}
</script>
