// plugins/i18n.js

import common from '@/translations/common'
import menu from '@/translations/menu'
import toolbars from '@/translations/toolbars'
import dialogs from '@/translations/dialogs'
import materials from '@/translations/materials'
import products from '@/translations/products'
import suppliers from '@/translations/suppliers'
import files from '@/translations/files'
import purchases from '@/translations/purchases'
import statuses from '@/translations/statuses'
import production from '@/translations/production'
import register from '@/translations/register'
import login from '@/translations/login'
import reports from '@/translations/reports'
import admin from '@/translations/admin'
import settings from '@/translations/settings'
import customers from '@/translations/customers'
import orders from '@/translations/orders'
import storages from "@/translations/storages"
import transfers from "@/translations/transfers"
import writeoffs from "@/translations/writeoffs"
import resources from "@/translations/resources"
import audits from "@/translations/audits"
import imports from '@/translations/imports'
import dadata from '@/translations/dadata'
import invoices from '@/translations/invoices'
import onboarding from '@/translations/onboarding'
import rules from '@/translations/rules'
import { pluralizeTime, pluralizeEntities } from '@/translations/plurals'
import * as Sentry from "@sentry/vue"

const translations = {
    common,
    menu,
    toolbars,
    dialogs,
    materials,
    products,
    suppliers,
    files,
    purchases,
    statuses,
    production,
    register,
    login,
    reports,
    admin,
    settings,
    customers,
    orders,
    storages,
    transfers,
    writeoffs,
    resources,
    audits,
    imports,
    dadata,
    invoices,
    onboarding,
    rules
}

const locale = process.env.VUE_APP_LOCALE

export default {
    install(Vue) {
        Vue.prototype.$locale = locale

        Vue.prototype.t = function (key) {
            // t for translate

            // Если ключа нет, возвращаем пустую строку
            if (!key) return ''

            // Проверяем, содержит ли ключ точку
            const hasModule = key.includes('.')

            // Если точки нет, добавляем 'common.' в начало
            const fullKey = hasModule ? key : `common.${key}`

            const [module, ...path] = fullKey.split('.')
            try {
                const translation = path.reduce((obj, key) => {
                    if (obj === undefined) throw new Error()
                    return obj[key]
                }, translations[module][locale])

                if (translation === undefined) {
                    throw new Error()
                }

                return translation
            } catch {
                Sentry.captureMessage(`Lost in translation: ${key}`)
                console.error(`Lost in translation: ${key}`)
                return key
            }
        }

        Vue.prototype.pluralize = function (number, entity) {
            if (entity == 'days' || entity == 'months' || entity == 'd' || entity == 'm') {
                return pluralizeTime(number, entity, locale)
            }
            else if (entity == 'materials' || entity == 'products'
                || entity == 'customers' || entity == 'suppliers'
            ) {
                return pluralizeEntities(number, entity, locale)
            }
        }
    }
}