<template>
	<v-checkbox :input-value="localValue" v-bind="$attrs" hide-details class="mt-0" @change="handleChange" />
	<!-- @change="$emit('input', $event)" -->
	<!-- @click="$emit('click', $event)" -->
</template>

<script>
export default {
	name: "ACheckbox",

	data() {
		return {
			localValue: null,
		}
	},

	props: {
		value: {
			type: [Boolean, Number],
			default: false,
		},
	},

	watch: {
		value: {
			immediate: true,
			handler(val) {
				// Convert number to boolean
				this.localValue = typeof val === "number" ? Boolean(val) : val
			},
		},
	},

	methods: {
		handleChange(val) {
			this.localValue = val

			// Convert back to number if original value was a number
			const emitValue = typeof this.value === "number" ? Number(val) : val
			this.$emit("input", emitValue)
			this.$emit("change", emitValue)
		},
	},
}
</script>

<style scoped>
.v-input--checkbox {
	padding-top: 0 !important;
}
</style>
