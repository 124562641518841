var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.showTooltip},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-autocomplete',_vm._b({class:{ 'v-input--comfortable': !_vm.large && _vm.$attrs.dense === undefined },attrs:{"value":_vm.value,"search-input":_vm.search,"hide-no-data":_vm.allowAdd && !_vm.search,"items":_vm.computedItems,"readonly":_vm.loading,"disabled":_vm.disabled,"item-text":_vm.itemText,"item-value":_vm.itemValue,"return-object":_vm.returnObject,"outlined":"","hide-details":"","single-line":"","menu-props":{
				closeOnClick: true,
				closeOnContentClick: true,
				disableKeys: true,
				openOnClick: false,
				maxHeight: 304,
				offsetY: true,
				offsetOverflow: false,
				transition: false,
			},"no-data-text":_vm.computedNoDataText},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([(_vm.duplicate)?{key:"prepend-inner",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","nudge-right":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",style:(_vm.duplicateIconStyle)},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.duplicateText)+" ")])])]},proxy:true}:(_vm.loading && _vm.value)?{key:"prepend-inner",fn:function(){return [_c('span',[_c('v-skeleton-loader',{staticStyle:{"padding-top":"5px"},attrs:{"type":"text","width":"150px"}})],1)]},proxy:true}:null,{key:"selection",fn:function({ item }){return [(!_vm.loading)?_c('div',_vm._g(_vm._b({staticClass:"v-select__selection v-select__selection--comma",class:{ 'text-decoration-line-through': _vm.currentDisabled }},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},(!!_vm.$scopedSlots.item)?{key:"item",fn:function({ index, item }){return [_vm._t("item",null,{"item":item})]}}:{key:"item",fn:function({ index, item }){return [(item.disabled)?_c('v-list-item-content',{staticClass:"text-decoration-line-through",staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-list-item-content',[_vm._v(" "+_vm._s(item.name)+" ")])]}},(_vm.allowAdd)?{key:"no-data",fn:function(){return [(_vm.search)?_c('v-list-item',{on:{"click":_vm.addItem}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-plus-circle")]),_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.addText)+" \""+_vm._s(_vm.search)+"\" ")])],1):_vm._e()]},proxy:true}:null],null,true)},'v-autocomplete',_vm.$attrs,false))]}}])},[_c('span',[_vm._v(_vm._s(_vm.itemName))])])
}
var staticRenderFns = []

export { render, staticRenderFns }