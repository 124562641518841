<template>
	<v-autocomplete
		:value="value"
		:items="items"
		:rules="rules"
		:label="label"
		:dense="dense"
		:disabled="disabled"
		:clearable="clearable"
		:item-text="itemText"
		:item-value="itemValue"
		:multiple="multiple"
		:placeholder="placeholder"
		:persistent-placeholder="placeholder.length > 0"
		:class="[{ 'v-input--comfortable': !large && !dense }]"
		outlined
		hide-details
		:menu-props="{
			closeOnClick: true,
			closeOnContentClick: !multiple,
			disableKeys: true,
			openOnClick: false,
			maxHeight: 304,
			offsetY: true,
			offsetOverflow: false,
			transition: false,
		}"
		@input="$emit('input', $event)"
		@change="$emit('change', $event)"
	>
		<!-- Выбрать все / снять все -->
		<template v-slot:prepend-item v-if="multiple && selectAll">
			<v-list-item ripple @mousedown.prevent @click="toggle">
				<v-list-item-action class="ml-0 mr-4 my-2">
					<v-icon :color="value.length > 0 ? 'primary' : ''">
						{{ icon }}
					</v-icon>
				</v-list-item-action>
				{{ t("selectAll") }}
			</v-list-item>
		</template>

		<!-- Selection multiple -->
		<template v-slot:selection="{ item, index }" v-if="multiple">
			<span v-if="value.length == 1" class="v-select__selection v-select__selection--comma">
				{{ item[itemText] }}
			</span>
			<span v-else-if="value.length == items.length && index == 0" class="grey--text">
				{{ placeholder }}
			</span>
			<span v-else-if="index == 0"> {{ t("selected") }} {{ value.length }} </span>
		</template>

		<!-- Selection single -->
		<template v-slot:selection="{ item }" v-else>
			<span class="v-select__selection v-select__selection--comma">
				{{ item[itemText] }}
			</span>
		</template>

		<!-- Item multiple -->
		<template v-slot:item="{ index, item, on, attrs }" v-if="multiple">
			<v-list-item
				v-on="on"
				v-bind="attrs"
				#default="{ active }"
				:class="[{ 'v-list-item--comfortable': !large && !dense }]"
			>
				<v-list-item-action class="ml-0 mr-4 my-2">
					<v-checkbox :ripple="false" :input-value="active"></v-checkbox>
				</v-list-item-action>
				<span>
					{{ item[itemText] ?? item }}
				</span>
			</v-list-item>
		</template>

		<!-- Item single -->
		<template v-slot:item="{ item, on, attrs }" v-else>
			<v-list-item
				v-on="on"
				v-bind="attrs"
				#default="{ active }"
				:class="[{ 'v-list-item--comfortable': !large && !dense }]"
			>
				<span>
					{{ item[itemText] }}
				</span>
			</v-list-item>
		</template>
	</v-autocomplete>
</template>

<script>
export default {
	props: {
		value: {
			type: [String, Number, Array],
			default: 0,
		},
		items: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: "",
		},
		large: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
		selectAll: {
			type: Boolean,
			default: false,
		},
		itemText: {
			type: String,
			default: "name",
		},
		itemValue: {
			type: String,
			default: "id",
		},
	},
	computed: {
		icon() {
			if (this.value.length == this.items.length) return "mdi-checkbox-marked"
			else if (this.value.length > 0) return "mdi-minus-box"
			return "mdi-checkbox-blank-outline"
		},
	},
	methods: {
		toggle() {
			if (this.value.length == this.items.length) this.$emit("input", [])
			else {
				this.$emit(
					"input",
					this.items.map((item) => item[this.itemValue])
				)
			}
		},
	},
}
</script>
