import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/lib/router.js'
import vuetify from '@/plugins/vuetify'
import api from '@/lib/api'
import i18n from '@/plugins/i18n.js'
import formatter from '@/plugins/formatter.js'
import chart from '@/translations/chart'
import { init } from '@amplitude/analytics-browser'
import VueApexCharts from "vue-apexcharts"
import * as Sentry from "@sentry/vue"
import '@/plugins/components'

Vue.use(i18n)
Vue.use(formatter)
Vue.use(VueApexCharts)

Vue.component("apexchart", VueApexCharts)

Apex.chart = {
	locales: [chart.ru, chart.en],
	defaultLocale: process.env.VUE_APP_LOCALE
}

// Initialize analytics and error tracking services
if (process.env.VUE_APP_ENV === 'production') {
	// Amplitude
	init('f62fa84d6158eba06d3193f3c9481f21', null, {
		// optional configuration options
		saveEvents: true,
		includeUtm: true,
		includeReferrer: true
	})

	// Sentry
	Sentry.init({
		Vue,
		dsn: "https://fb402e417e5407b7b14f98bed9edb65e@o4508681250734080.ingest.de.sentry.io/4508699215396944",
		integrations: [
			Sentry.replayIntegration(),
		],
		// Session Replay
		replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})

	// Yandex Metrika
	if (process.env.VUE_APP_LOCALE === 'ru') {
		ym(90143371, "init", {
			defer: true,
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
		})
	}
}

// Turn off production tip
Vue.config.productionTip = false

// Function to initialize Vue instance
function initializeVue() {
	const vm = new Vue({
		router,
		vuetify,
		store,
		render: h => h(App),
	}).$mount('#app')

	window.vm = vm
	return vm
}

// Read auth token from local storage
if (localStorage.getItem('token')) {
	api.post('auth/authorize').then(response => {
		initializeVue()
		if (response.data.success) {
			store.dispatch("login", response.data)
		}
	})
}
else {
	initializeVue()
}
