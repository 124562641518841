<template>
	<div class="mb-n2">
		<v-chip
			v-for="c in categories"
			:key="c.id"
			:color="colors[c.color]"
			@click="$emit('select', c)"
			class="mr-2 mb-2"
			label
			small
		>
			{{ c.name }}
		</v-chip>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	props: {
		categories: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapGetters({
			colors: "colors",
		}),
	},
}
</script>
